/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiInventoryV1beta1CreateDiscountResponse } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1Discount } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1ListDiscountsResponse } from '../models';
// @ts-ignore
import type { CastaiInventoryV1beta1UpdateDiscountResponse } from '../models';
/**
 * DiscountsAPIApi - axios parameter creator
 * @export
 */
export const DiscountsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create discount
         * @param {CastaiInventoryV1beta1Discount} discount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPICreateDiscount: async (discount: CastaiInventoryV1beta1Discount, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discount' is not null or undefined
            assertParamExists('discountsAPICreateDiscount', 'discount', discount)
            const localVarPath = `/v1/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a given discount by its id
         * @param {string} discountId ID of the discount to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPIDeleteDiscount: async (discountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('discountsAPIDeleteDiscount', 'discountId', discountId)
            const localVarPath = `/v1/discounts/{discountId}`
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List discounts
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPIListDiscounts: async (pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/discounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a given discount
         * @param {string} discountId ID of the discount to be updated
         * @param {CastaiInventoryV1beta1Discount} discount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPIUpdateDiscount: async (discountId: string, discount: CastaiInventoryV1beta1Discount, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discountId' is not null or undefined
            assertParamExists('discountsAPIUpdateDiscount', 'discountId', discountId)
            // verify required parameter 'discount' is not null or undefined
            assertParamExists('discountsAPIUpdateDiscount', 'discount', discount)
            const localVarPath = `/v1/discounts/{discountId}`
                .replace(`{${"discountId"}}`, encodeURIComponent(String(discountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(discount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DiscountsAPIApi - functional programming interface
 * @export
 */
export const DiscountsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DiscountsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create discount
         * @param {CastaiInventoryV1beta1Discount} discount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsAPICreateDiscount(discount: CastaiInventoryV1beta1Discount, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1CreateDiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsAPICreateDiscount(discount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsAPIApi.discountsAPICreateDiscount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a given discount by its id
         * @param {string} discountId ID of the discount to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsAPIDeleteDiscount(discountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsAPIDeleteDiscount(discountId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsAPIApi.discountsAPIDeleteDiscount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List discounts
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsAPIListDiscounts(pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1ListDiscountsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsAPIListDiscounts(pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsAPIApi.discountsAPIListDiscounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update a given discount
         * @param {string} discountId ID of the discount to be updated
         * @param {CastaiInventoryV1beta1Discount} discount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async discountsAPIUpdateDiscount(discountId: string, discount: CastaiInventoryV1beta1Discount, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiInventoryV1beta1UpdateDiscountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.discountsAPIUpdateDiscount(discountId, discount, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DiscountsAPIApi.discountsAPIUpdateDiscount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DiscountsAPIApi - factory interface
 * @export
 */
export const DiscountsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DiscountsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create discount
         * @param {DiscountsAPIApiDiscountsAPICreateDiscountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPICreateDiscount(requestParameters: DiscountsAPIApiDiscountsAPICreateDiscountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1CreateDiscountResponse> {
            return localVarFp.discountsAPICreateDiscount(requestParameters.discount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a given discount by its id
         * @param {DiscountsAPIApiDiscountsAPIDeleteDiscountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPIDeleteDiscount(requestParameters: DiscountsAPIApiDiscountsAPIDeleteDiscountRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.discountsAPIDeleteDiscount(requestParameters.discountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List discounts
         * @param {DiscountsAPIApiDiscountsAPIListDiscountsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPIListDiscounts(requestParameters: DiscountsAPIApiDiscountsAPIListDiscountsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1ListDiscountsResponse> {
            return localVarFp.discountsAPIListDiscounts(requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a given discount
         * @param {DiscountsAPIApiDiscountsAPIUpdateDiscountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discountsAPIUpdateDiscount(requestParameters: DiscountsAPIApiDiscountsAPIUpdateDiscountRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiInventoryV1beta1UpdateDiscountResponse> {
            return localVarFp.discountsAPIUpdateDiscount(requestParameters.discountId, requestParameters.discount, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for discountsAPICreateDiscount operation in DiscountsAPIApi.
 * @export
 * @interface DiscountsAPIApiDiscountsAPICreateDiscountRequest
 */
export interface DiscountsAPIApiDiscountsAPICreateDiscountRequest {
    /**
     * 
     * @type {CastaiInventoryV1beta1Discount}
     * @memberof DiscountsAPIApiDiscountsAPICreateDiscount
     */
    readonly discount: CastaiInventoryV1beta1Discount
}

/**
 * Request parameters for discountsAPIDeleteDiscount operation in DiscountsAPIApi.
 * @export
 * @interface DiscountsAPIApiDiscountsAPIDeleteDiscountRequest
 */
export interface DiscountsAPIApiDiscountsAPIDeleteDiscountRequest {
    /**
     * ID of the discount to be deleted
     * @type {string}
     * @memberof DiscountsAPIApiDiscountsAPIDeleteDiscount
     */
    readonly discountId: string
}

/**
 * Request parameters for discountsAPIListDiscounts operation in DiscountsAPIApi.
 * @export
 * @interface DiscountsAPIApiDiscountsAPIListDiscountsRequest
 */
export interface DiscountsAPIApiDiscountsAPIListDiscountsRequest {
    /**
     * 
     * @type {string}
     * @memberof DiscountsAPIApiDiscountsAPIListDiscounts
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof DiscountsAPIApiDiscountsAPIListDiscounts
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for discountsAPIUpdateDiscount operation in DiscountsAPIApi.
 * @export
 * @interface DiscountsAPIApiDiscountsAPIUpdateDiscountRequest
 */
export interface DiscountsAPIApiDiscountsAPIUpdateDiscountRequest {
    /**
     * ID of the discount to be updated
     * @type {string}
     * @memberof DiscountsAPIApiDiscountsAPIUpdateDiscount
     */
    readonly discountId: string

    /**
     * 
     * @type {CastaiInventoryV1beta1Discount}
     * @memberof DiscountsAPIApiDiscountsAPIUpdateDiscount
     */
    readonly discount: CastaiInventoryV1beta1Discount
}

/**
 * DiscountsAPIApi - object-oriented interface
 * @export
 * @class DiscountsAPIApi
 * @extends {BaseAPI}
 */
export class DiscountsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Create discount
     * @param {DiscountsAPIApiDiscountsAPICreateDiscountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsAPIApi
     */
    public discountsAPICreateDiscount(requestParameters: DiscountsAPIApiDiscountsAPICreateDiscountRequest, options?: RawAxiosRequestConfig) {
        return DiscountsAPIApiFp(this.configuration).discountsAPICreateDiscount(requestParameters.discount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a given discount by its id
     * @param {DiscountsAPIApiDiscountsAPIDeleteDiscountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsAPIApi
     */
    public discountsAPIDeleteDiscount(requestParameters: DiscountsAPIApiDiscountsAPIDeleteDiscountRequest, options?: RawAxiosRequestConfig) {
        return DiscountsAPIApiFp(this.configuration).discountsAPIDeleteDiscount(requestParameters.discountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List discounts
     * @param {DiscountsAPIApiDiscountsAPIListDiscountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsAPIApi
     */
    public discountsAPIListDiscounts(requestParameters: DiscountsAPIApiDiscountsAPIListDiscountsRequest = {}, options?: RawAxiosRequestConfig) {
        return DiscountsAPIApiFp(this.configuration).discountsAPIListDiscounts(requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a given discount
     * @param {DiscountsAPIApiDiscountsAPIUpdateDiscountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DiscountsAPIApi
     */
    public discountsAPIUpdateDiscount(requestParameters: DiscountsAPIApiDiscountsAPIUpdateDiscountRequest, options?: RawAxiosRequestConfig) {
        return DiscountsAPIApiFp(this.configuration).discountsAPIUpdateDiscount(requestParameters.discountId, requestParameters.discount, options).then((request) => request(this.axios, this.basePath));
    }
}

