import { Actions, Subjects } from './types';

type ActionConfig = {
  permissions?: string[]; // list of RBAC permissions required to perform the action
  flags?: string[]; // feature flags required to perform the action
} & ({ permissions: string[] } | { flags: string[] });

export const ABILITY_CONFIG: Record<
  Subjects,
  Partial<Record<Actions, ActionConfig>>
> = {
  OrganizationNotifications: {
    // organizationManagement.notifications.read
    view: {
      permissions: [
        'notifications.ListNotifications',
        'notifications.GetNotification',
      ],
      flags: ['ui__page-modules--notifications'],
    },
    // organizationManagement.notifications.write
    update: {
      permissions: ['notifications.AckNotifications'],
      flags: ['ui__page-modules--notifications'],
    },
  },
  OrganizationWebHooks: {
    // organizationManagement.notifications.read
    view: {
      permissions: [
        'notifications.GetWebhookConfig',
        'notifications.ListWebhookCategories',
        'notifications.ListWebhookConfigs',
      ],
    },
    // organizationManagement.notifications.read
    create: {
      permissions: ['notifications.CreateWebhookConfig'],
    },
    // organizationManagement.notifications.write
    update: {
      permissions: ['notifications.UpdateWebhookConfig'],
    },
    // organizationManagement.notifications.delete
    delete: {
      permissions: ['notifications.DeleteWebhookConfig'],
    },
  },
  OrganizationSsoConnections: {
    // organizationManagement.sso.read
    view: {
      permissions: ['sso.GetSSOConnection', 'sso.ListSSOConnections'],
    },
    // organizationManagement.sso.create
    create: {
      permissions: ['sso.CreateSSOConnection'],
    },
    // organizationManagement.sso.write
    update: {
      permissions: ['sso.UpdateSSOConnection'],
    },
    // organizationManagement.sso.delete
    delete: {
      permissions: ['sso.DeleteSSOConnection'],
    },
  },
  OrganizationBilling: {
    view: {
      flags: ['ui__page-modules--billing'],
    },
  },
  OrganizationBillingReport: {
    // organizationManagement.billing.read
    view: {
      permissions: ['billing.GetUsageReport'],
      flags: ['ui__page-modules--billing-report'],
    },
  },
  OrganizationDiscounts: {
    // organizationManagement.billing.read
    view: {
      permissions: ['discounts.ListDiscounts'],
      flags: ['ui__page-modules--organization__discounts'],
    },
    // organizationManagement.billing.create
    create: {
      permissions: ['discounts.CreateDiscount'],
      flags: ['ui__page-modules--organization__discounts'],
    },
    // organizationManagement.billing.write
    update: {
      permissions: ['discounts.UpdateDiscount'],
      flags: ['ui__page-modules--organization__discounts'],
    },
    // organizationManagement.billing.delete
    delete: {
      permissions: ['discounts.DeleteDiscount'],
      flags: ['ui__page-modules--organization__discounts'],
    },
  },
  OrganizationCommitments: {
    // organizationManagement.commitments.read
    view: {
      permissions: [
        'commitments.GetCommitment',
        'commitments.GetCommitments',
        'commitments.GetCommitmentAssignments',
        'commitments.GetCommitmentsAssignments',
      ],
      flags: ['ui__page-modules--organization__commitments'],
    },
    // organizationManagement.commitments.{write,create,read}
    create: {
      permissions: [
        'commitments.ImportGCPCommitments',
        'commitments.ImportAzureReservations',
        'commitments.GetGCPCommitmentsImportScript',
        'commitments.GetGCPCommitmentsScriptTemplate',
        'commitments.CreateCommitmentAssignment',
      ],
      flags: ['ui__page-modules--organization__commitments'],
    },
    // organizationManagement.commitments.write
    update: {
      permissions: [
        'commitments.UpdateCommitment',
        'commitments.ReplaceCommitmentAssignments',
      ],
      flags: ['ui__page-modules--organization__commitments'],
    },
    // organizationManagement.commitments.delete
    delete: {
      permissions: [
        'commitments.DeleteCommitment',
        'commitments.DeleteCommitmentAssignment',
      ],
      flags: ['ui__page-modules--organization__commitments'],
    },
  },
  OrganizationRebalanceSchedules: {
    // organizationManagement.rebalancing.read
    view: {
      permissions: [
        'rebalancing.ListRebalancingJobs',
        'rebalancing.ListRebalancingSchedules',
        'rebalancing.ListAvailableRebalancingTZ',
        'rebalancing.GetRebalancingJob',
        'rebalancing.GetRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization__scheduled-rebalancing'],
    },
    // organizationManagement.rebalancing.create
    create: {
      permissions: [
        'rebalancing.CreateRebalancingJob',
        'rebalancing.CreateRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization__scheduled-rebalancing'],
    },
    // organizationManagement.rebalancing.write
    update: {
      permissions: [
        'rebalancing.UpdateRebalancingJob',
        'rebalancing.UpdateRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization__scheduled-rebalancing'],
    },
    // organizationManagement.rebalancing.delete
    delete: {
      permissions: [
        'rebalancing.DeleteRebalancingJob',
        'rebalancing.DeleteRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization__scheduled-rebalancing'],
    },
  },
  OrganizationCostMonitoring: {
    // costMonitoring.{clusters,workloads,allocationGroups}.read,
    view: {
      permissions: [
        'clusters.GetOrganizationClustersCostReport',
        'clusters.GetClustersCostReport',
        'clusters.GetClustersSummary',
        'workloads.GetWorkloadLabelNames',
        'workloads.GetWorkloadLabelValues',
        'allocationGroups.ListAllocationGroups',
        'allocationGroups.GetCostAllocationGroupWorkloads',
        'allocationGroups.GetAllocationGroupCostSummaries',
        'allocationGroups.GetAllocationGroupTotalCostTimed',
        'allocationGroups.GetAllocationGroupEfficiencySummary',
        'allocationGroups.GetAllocationGroupWorkloadCosts',
        'allocationGroups.GetCostAllocationGroupDataTransferSummary',
        'allocationGroups.GetCostAllocationGroupDataTransferWorkloads',
      ],
      flags: ['ui__page-modules--organization__cost-monitoring'],
    },
    // costMonitoring.allocationGroups.create
    create: {
      permissions: ['allocationGroups.CreateAllocationGroup'],
      flags: ['ui__page-modules--organization__cost-monitoring'],
    },
    // costMonitoring.allocationGroups.write
    update: {
      permissions: ['allocationGroups.UpdateAllocationGroup'],
      flags: ['ui__page-modules--organization__cost-monitoring'],
    },
    // costMonitoring.allocationGroups.delete
    delete: {
      permissions: ['allocationGroups.DeleteAllocationGroup'],
      flags: ['ui__page-modules--organization__cost-monitoring'],
    },
  },
  OrganizationSecurityCompliance: {
    // kubernetesSecurity.compliance.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'compliance.GetBestPracticesReport',
        'compliance.GetBestPracticesCheckDetails',
        'compliance.GetBestPracticesReportFilters',
        'compliance.GetCheckResources',
        'compliance.GetChecksResources',
        'compliance.GetBestPracticesOverview',
        'compliance.GetCheckExceptions',
        'compliance.PreviewExceptionGroupsResources',
      ],
      flags: ['ui__page-modules--organization-security__best-practices'],
    },
    // kubernetesSecurity.compliance.{write,read}, kubernetesSecurity.integrations.{write,read}
    create: {
      permissions: [
        'integrations.CreateTicket',
        'integrations.GetProjects',
        'compliance.ScheduleBestPracticesScan',
        'compliance.EnforceCheckPolicy',
        'compliance.SaveCheckExceptions',
      ],
      flags: ['ui__page-modules--organization-security__best-practices'],
    },
    delete: {
      // kubernetesSecurity.compliance.delete
      permissions: ['compliance.DeletePolicyEnforcement'],
      flags: ['ui__page-modules--organization-security__best-practices'],
    },
  },
  OrganizationSecurityVulnerabilities: {
    // kubernetesSecurity.components.read, kubernetesSecurity.vulnerabilities.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'vulnerabilities.GetContainerImagesFilters',
        'vulnerabilities.GetContainerImages',
        'vulnerabilities.GetContainerImageDetails',
        'vulnerabilities.GetContainerImageDigests',
        'vulnerabilities.GetContainerImagePackages',
        'vulnerabilities.GetContainerImageResources',
        'vulnerabilities.GetContainerImageVulnerabilities',
        'vulnerabilities.GetContainerImagePackageVulnerabilityDetails',
        'vulnerabilities.GetImageSecurityOverview',
        'vulnerabilities.GetPublicImageAlternatives',
      ],
      flags: ['ui__page-modules--organization-security__image-security'],
    },
    // kubernetesSecurity.integrations.read, kubernetesSecurity.integrations.write, kubernetesSecurity.vulnerabilities.read
    create: {
      permissions: [
        'integrations.CreateTicket',
        'integrations.GetProjects',
        'vulnerabilities.ImageExceptionChanges',
      ],
      flags: ['ui__page-modules--organization-security__image-security'],
    },
    delete: {
      // kubernetesSecurity.vulnerabilities.delete
      permissions: ['vulnerabilities.DeleteImages'],
      flags: ['ui__page-modules--organization-security__image-security'],
    },
  },
  OrganizationSecurityAttackPaths: {
    // kubernetesSecurity.components.read, kubernetesSecurity.attackPaths.read, kubernetesSecurity.vulnerabilities.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'attackPaths.GetAttackPathDetails',
        'attackPaths.GetAttackPaths',
        'attackPaths.GetAttackPathsOverview',
        'vulnerabilities.GetResourceDetails',
      ],
      flags: ['ui__page-modules--organization-security__attack-path'],
    },
  },
  OrganizationSecurityRuntime: {
    // kubernetesSecurity.components.read, kubernetesSecurity.runtime.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'runtime.GetAnomalies',
        'runtime.GetAnomaliesOverview',
        'runtime.GetAnomaly',
        'runtime.GetAnomalyEvents',
        'runtime.GetClusterWorkloadsNetflow',
        'runtime.GetList',
        'runtime.GetListEntries',
        'runtime.GetLists',
        'runtime.GetRule',
        'runtime.GetRules',
        'runtime.GetRuntimeEventGroups',
        'runtime.GetRuntimeEvents',
      ],
      flags: ['ui__page-modules--organization-security__anomalies'],
    },
    // kubernetesSecurity.runtime.{write,create,read}
    create: {
      permissions: [
        'runtime.Validate',
        'runtime.AddListEntries',
        'runtime.CreateList',
        'runtime.CreateRule',
      ],
      flags: ['ui__page-modules--organization-security__anomalies'],
    },
    // kubernetesSecurity.runtime.{write,create,read}
    update: {
      permissions: [
        'runtime.Validate',
        'runtime.EditRule',
        'runtime.ToggleRules',
        'runtime.AckAnomalies',
        'runtime.CloseAnomalies',
      ],
      flags: ['ui__page-modules--organization-security__anomalies'],
    },
    // kubernetesSecurity.runtime.delete
    delete: {
      permissions: [
        'runtime.RemoveListEntries',
        'runtime.DeleteLists',
        'runtime.DeleteRules',
      ],
      flags: ['ui__page-modules--organization-security__anomalies'],
    },
  },
  OrganizationSecuritySettings: {
    // kubernetesSecurity.settings.read, kubernetesSecurity.components.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'settings.ListClusterSecuritySettings',
        'settings.GetClusterSecuritySettings',
      ],
      flags: ['ui__page-modules--organization-security__settings'],
    },
    // kubernetesSecurity.settings.write},
    update: {
      permissions: ['settings.ConfigureClusterSecuritySettings'],
      flags: ['ui__page-modules--organization-security__settings'],
    },
  },
  OrganizationSecurityIntegrations: {
    // kubernetesSecurity.{integrations,components}.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'integrations.ListIntegrations',
        'integrations.GetIntegration',
      ],
    },
    // kubernetesSecurity.integrations.create
    create: {
      permissions: ['integrations.CreateIntegration'],
    },
    // kubernetesSecurity.integrations.write
    update: {
      permissions: ['integrations.UpdateIntegration'],
    },
    // kubernetesSecurity.integrations.delete
    delete: {
      permissions: ['integrations.DeleteIntegration'],
    },
  },
  OrganizationSecurityWorkloads: {
    // kubernetesSecurity.components.read, kubernetesSecurity.compliance.read, kubernetesSecurity.vulnerabilities.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'compliance.ListWorkloads',
        'compliance.GetBestPracticesReportFilters',
        'vulnerabilities.GetResourceDetails',
      ],
      flags: ['ui__page-modules--organization-security__workloads'],
    },
  },
  OrganizationSecurityNodeRotation: {
    // kubernetesSecurity.components.read, kubernetesSecurity.vulnerabilities.read, clusterOptimization.rebalancing.read, clusterManagement.{clusters,nodes}.read
    view: {
      permissions: [
        'components.GetAgentsStatus',
        'vulnerabilities.GetNodesOverview',
        'rebalancing.ListRebalancingJobs',
        'rebalancing.ListRebalancingSchedules',
        'rebalancing.ListAvailableRebalancingTZ',
        'rebalancing.GetRebalancingJob',
        'rebalancing.GetRebalancingSchedule',
        'clusters.ListClusters',
        'nodes.GetNode',
        'workloads.GetClusterWorkloads',
      ],
      flags: ['ui__page-modules--organization-security__node-os-updates'],
    },
    // clusterOptimization.rebalancing.create
    create: {
      permissions: [
        'rebalancing.CreateRebalancingJob',
        'rebalancing.CreateRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization-security__node-os-updates'],
    },
    // clusterOptimization.rebalancing.write
    update: {
      permissions: [
        'rebalancing.UpdateRebalancingJob',
        'rebalancing.UpdateRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization-security__node-os-updates'],
    },
    // clusterOptimization.rebalancing.delete
    delete: {
      permissions: [
        'rebalancing.DeleteRebalancingJob',
        'rebalancing.DeleteRebalancingSchedule',
      ],
      flags: ['ui__page-modules--organization-security__node-os-updates'],
    },
  },
  OrganizationAiOptimizerReports: {
    // aiOptimizer.models.read
    view: {
      permissions: [
        'models.GetModels',
        'models.GetChatCompletionsAPIKeyUsageReport',
        'models.GetChatCompletionsAPIKeysRecommendationsReport',
        'models.GetChatCompletionsAPIKeysSavingsReport',
        'models.GetChatCompletionsCategoryRecommendationsReport',
        'models.GetChatCompletionsCategoryUsageReport',
        'models.GetChatCompletionsRecommendationsReport',
        'models.GetChatCompletionsUsageReport',
      ],
      flags: ['ui__page-modules--organization-llm__cost-report'],
    },
  },
  OrganizationAiOptimizerProviders: {
    // aiOptimizer.providers.read
    view: {
      permissions: ['providers.GetProviders'],
      flags: ['ui__page-modules--organization-llm__providers'],
    },
    // aiOptimizer.providers.create
    create: {
      permissions: ['providers.RegisterProviders'],
      flags: ['ui__page-modules--organization-llm__providers'],
    },
    // aiOptimizer.providers.write
    update: {
      permissions: ['providers.UpdateProvider'],
      flags: ['ui__page-modules--organization-llm__providers'],
    },
    // aiOptimizer.providers.delete
    delete: {
      permissions: ['providers.DeleteProvider'],
      flags: ['ui__page-modules--organization-llm__providers'],
    },
  },
  OrganizationAiOptimizerSettings: {
    // aiOptimizer.settings.read
    view: {
      permissions: ['settings.GetSettings'],
    },
    // aiOptimizer.settings.write
    update: {
      permissions: ['settings.UpsertSettings'],
    },
  },
  OrganizationAiOptimizerPlayGround: {
    // aiOptimizer.{models,events, providers, playgrounds}.{read, create}
    view: {
      permissions: [
        'models.GetModels',
        'events.GetRoutingEvents',
        'providers.GetSupportedProviders',
        'playgroundChatCompletions.StreamPlaygroundChatCompletion',
      ],
      flags: ['ui__page-modules--organization-llm__playground'],
    },
  },
  OrganizationAiOptimizerModelDeployments: {
    // aiOptimizer.{hostedModels, components}.read clusterManagement.clusters.read
    view: {
      permissions: [
        'hostedModels.ListHostedModels',
        'components.ListComponents',
        'clusters.ListClusters',
      ],
      flags: ['ui__page-modules--organization-llm__model-deployments'],
    },
    // organizationManagement.clusters.read, aiOptimizer.hostedModelSpecs.read, clusterManagement.nodeConfigurations.read, aiOptimizer.hostedModels.create
    create: {
      permissions: [
        'clusters.ListClusters',
        'hostedModelSpecs.ListHostedModelSpecs',
        'nodeConfigurations.ListNodeTemplates',
        'hostedModels.CreateHostedModel',
      ],
      flags: ['ui__page-modules--organization-llm__model-deployments'],
    },
    // aiOptimizer.hostedModels.write clusterManagement.clusters.write
    update: {
      permissions: ['hostedModels.ScaleHostedModel', 'clusters.UpdateCluster'],
      flags: ['ui__page-modules--organization-llm__model-deployments'],
    },
    // aiOptimizer.hostedModels.delete
    delete: {
      permissions: ['hostedModels.DeleteHostedModel'],
      flags: ['ui__page-modules--organization-llm__model-deployments'],
    },
  },
  OrganizationAiOptimizerSettingsPage: {
    // aiOptimizer.{settings, components}.read clusterManagement.clusters.read
    view: {
      permissions: [
        'settings.GetSettings',
        'components.ListComponents',
        'clusters.ListClusters',
      ],
    },
    // aiOptimizer.settings.write clusterManagement.clusters.write
    update: {
      permissions: ['settings.UpsertSettings', 'clusters.UpdateCluster'],
    },
  },
  OrganizationDatabaseOptimization: {
    // databaseOptimizer.{databases,instances,reports}.read
    view: {
      permissions: [
        'instances.GetDatabaseInstance',
        'caches.ListCacheConfigurations',
        'caches.ListCacheGroups',
        'caches.ListCacheTTLs',
        'instances.ListDatabaseInstances',
        'reports.GetCacheSummary',
        // 'reports.GetReportQueryTotal', # fixme(Adomas): is not returned by roles ep
      ],
    },
    // databaseOptimizer.{databases,instances,reports,caches}.{create,read}
    create: {
      permissions: [
        'components.GetRegistrationStatus',
        'caches.CreateCacheConfiguration',
        'components.CreateCacheGroup',
        'caches.CreateCacheTTL',
        'instances.CreateDatabaseInstance',
        'databases.CreateLogicalDatabase',
      ],
    },
    // databaseOptimizer.{databases,caches,components}.write
    update: {
      permissions: [
        'caches.UpdateCacheConfiguration',
        'caches.UpdateCacheGroup',
        'caches.UpdateCacheTTL',
        'databases.UpdateDatabaseInstance',
      ],
    },
    // databaseOptimizer.{databases,caches}.delete
    delete: {
      permissions: [
        'caches.DeleteCacheConfiguration',
        'caches.DeleteCacheGroup',
        'caches.DeleteCacheTTL',
        'databases.DeleteDatabaseInstance',
        'databases.DeleteLogicalDatabase',
      ],
    },
  },
  OrganizationManagement: {
    // organizationManagement.organizations.read
    view: {
      permissions: [
        'organizations.GetOrganization',
        'organizations.ListOrganizations',
      ],
      flags: ['ui__page-modules--organization-management'],
    },
    // organizationManagement.organizations.create
    create: {
      permissions: ['organizations.CreateOrganization'],
      flags: ['ui__page-modules--organization-management'],
    },
    update: {
      // organizationManagement.organizations.write
      permissions: ['organizations.EditOrganization'],
      flags: ['ui__page-modules--organization-management'],
    },
    // organizationManagement.organizations.delete
    delete: {
      permissions: ['organizations.DeleteOrganization'],
      flags: ['ui__page-modules--organization-management'],
    },
  },
  OrganizationAccessManagement: {
    // organizationManagement.iam.read
    view: {
      permissions: [
        'iam.GetGroup',
        'iam.GetRole',
        'iam.GetRoleBinding',
        'iam.GetServiceAccount',
        'iam.ListGroups',
        'iam.ListInvitations',
        'iam.ListOrganizationUsers',
        'iam.ListRoleBindings',
        'iam.ListRoles',
        'iam.ListServiceAccounts',
        'iam.ListGroups',
      ],
      flags: ['wire-rbacv2-enabled'],
    },
    // organizationManagement.iam.create
    create: {
      permissions: [
        'iam.CreateGroup',
        'iam.CreateInvitations',
        'iam.CreateRole',
        'iam.CreateRoleBindings',
        'iam.CreateServiceAccount',
      ],
      flags: ['wire-rbacv2-enabled'],
    },
    // organizationManagement.iam.write
    update: {
      permissions: [
        'iam.AddUserToOrganization',
        'iam.UpdateGroup',
        'iam.UpdateGroups',
        'iam.UpdateOrganizationUser',
        'iam.UpdateRole',
        'iam.UpdateRoleBinding',
      ],
      flags: ['wire-rbacv2-enabled'],
    },
    delete: {
      // organizationManagement.iam.delete
      permissions: [
        'iam.RemoveOrganizationUsers',
        'iam.RemoveUserFromOrganization',
        'iam.DeleteGroup',
        'iam.DeleteRole',
        'iam.DeleteRoleBinding',
        'iam.DeleteServiceAccount',
      ],
      flags: ['wire-rbacv2-enabled'],
    },
  },
  Clusters: {
    // organizationManagement.clusters.read, costMonitoring.workloads.read
    view: {
      permissions: [
        'clusters.ListClusters',
        'clusters.GetCluster',
        'workloads.GetClusterUnscheduledPods',
      ],
    },
    // clusterManagement.clusters.{create,write}, clusterOptimization.clusters.read
    create: {
      permissions: [
        'clusters.GetAgentScript',
        'clusters.RegisterCluster',
        'clusters.GetCredentialsScript',
      ],
    },
    // clusterManagement.clusters.write
    update: {
      permissions: [
        'clusters.UpdateCluster',
        'clusters.UpdateClusterTags',
        'clusters.ReconcileCluster',
      ],
    },
    // clusterManagement.clusters.delete
    delete: {
      permissions: [
        'clusters.DeleteCluster',
        'clusters.DisconnectCluster',
        'clusters.GetCleanupScript',
      ],
    },
  },
  ClusterCostMonitoring: {
    // costMonitoring.clusters.read, costMonitoring.namespaces.read, organizationManagement.billing.read, costMonitoring.workloads.read, costMonitoring.allocationGroups.read, costMonitoring.nodes.read, costMonitoring.nodeConfigurations.read
    view: {
      permissions: [
        'clusters.GetReportingCapabilities',
        'clusters.GetClusterCostHistory',
        'clusters.GetClusterCostReport',
        'clusters.GetClusterEfficiencyReport',
        'namespaces.GetClusterNamespaceCostReport',
        'namespaces.GetClusterNamespaceCostReportSummaries',
        'namespaces.GetClusterNamespaceDataTransferCost',
        'namespaces.GetClusterNamespaceDataTransferTimedTotalCost',
        'clusters.GetClusterResourceUsage',
        'clusters.GetClusterSavingsReport',
        'clusters.GetClusterSummary',
        'clusters.GetCostAnomalies',
        'clusters.GetCostComparisonReport',
        'clusters.GetRightsizingSummary',
        'clusters.GetSavingsRecommendation',
        'namespaces.GetSingleNamespaceCostReport',
        'billing.GetUsageReport',
        'clusters.GetUsageSummary',
        'workloads.GetClusterWorkloadEfficiencyReport',
        'workloads.GetClusterWorkloadEfficiencyReportByName',
        'workloads.GetClusterWorkloadLabels',
        'workloads.GetClusterWorkloadReport',
        'namespaces.GetNamespacesForClusters',
        'workloads.GetSingleWorkloadCostReport',
        'workloads.GetSingleWorkloadDataTransferCost',
        'workloads.GetSingleWorkloadGPUSummary',
        'workloads.GetWorkloadCostSummaries',
        'workloads.GetWorkloadDataTransferCost',
        'workloads.GetWorkloadLabelValuesDeprecated',
        'workloads.GetWorkloadReportingCapabilities',
        'workloads.GetWorkloadTrafficDestinations',
        'workloads.GetWorkloadTrafficDestinationsHistory',
        'workloads.GetWorkloadsGPUSummary',
        'workloads.GetWorkloadsGPUUtilization',
        'workloads.GetWorkloadsSummary',
        'workloads.GetWorkloadsWastedGPUCostImpact',
        'allocationGroups.ListAllocationGroups',
        'allocationGroups.GetCostAllocationGroupWorkloads',
        'allocationGroups.GetAllocationGroupCostSummaries',
        'allocationGroups.GetAllocationGroupTotalCostTimed',
        'allocationGroups.GetAllocationGroupEfficiencySummary',
        'allocationGroups.GetAllocationGroupWorkloadCosts',
        'allocationGroups.GetCostAllocationGroupDataTransferSummary',
        'allocationGroups.GetCostAllocationGroupDataTransferWorkloads',
        'nodes.GetNodeLabelNames',
        'nodeConfigurations.GetNodeLabelValues',
      ],
      flags: ['ui__page-modules--cluster__cost-monitoring'],
    },
    // costMonitoring.allocationGroups.create
    create: {
      permissions: ['allocationGroups.CreateAllocationGroup'],
      flags: ['ui__page-modules--cluster__cost-monitoring'],
    },
    // costMonitoring.integrations.read, costMonitoring.workloads.read, costMonitoring.allocationGroups.write
    update: {
      permissions: [
        'integrations.GetEgressdScript',
        'integrations.GetGpuMetricsExporterScript',
        'workloads.GetClusterWorkloadRightsizingPatch',
        'allocationGroups.UpdateAllocationGroup',
      ],
      flags: ['ui__page-modules--cluster__cost-monitoring'],
    },
    delete: {
      // costMonitoring.allocationGroups.delete
      permissions: ['allocationGroups.DeleteAllocationGroup'],
      flags: ['ui__page-modules--cluster__cost-monitoring'],
    },
  },
  ClusterWorkloadAutoscaler: {
    // workloadOptimization.workloads.read, workloadOptimization.components.read, workloadOptimization.policies.read
    view: {
      permissions: [
        'workloads.GetAgentStatus',
        'components.GetInstallCmd',
        'components.GetInstallScript',
        'policies.ListWorkloadScalingPolicies',
        'policies.GetWorkloadScalingPolicy',
        'workloads.GetWorkload',
        'workloads.ListWorkloadEvents',
        'workloads.ListWorkloads',
      ],
      flags: ['ui__page-modules--cluster__workload-optimization'],
    },
    // workloadOptimization.workloads.create
    create: {
      permissions: ['policies.CreateWorkloadScalingPolicy'],
      flags: ['ui__page-modules--cluster__workload-optimization'],
    },
    // workloadOptimization.policies.write, workloadOptimization.workloads.write
    update: {
      permissions: [
        'policies.AssignScalingPolicyWorkloads',
        'policies.UpdateWorkloadScalingPolicy',
        'workloads.UpdateWorkloadV2',
      ],
      flags: ['ui__page-modules--cluster__workload-optimization'],
    },
    // workloadOptimization.policies.delete
    delete: {
      permissions: ['policies.DeleteWorkloadScalingPolicy'],
      flags: ['ui__page-modules--cluster__workload-optimization'],
    },
  },
  ClusterNodes: {
    // clusterManagement.nodes.read
    view: {
      permissions: ['nodes.ListNodes', 'nodes.GetNode'],
      flags: ['ui__page-modules--cluster__node'],
    },
    // clusterManagement.nodes.create
    create: {
      permissions: ['nodes.AddNode'],
      flags: ['ui__page-modules--cluster__node'],
    },
    update: {
      permissions: [],
      flags: ['ui__page-modules--cluster__node'],
    },
    // clusterManagement.nodes.delete
    delete: {
      permissions: ['nodes.DrainNode', 'nodes.DeleteNode'],
      flags: ['ui__page-modules--cluster__node'],
    },
  },
  ClusterAutoscaler: {
    // clusterOptimization.evictor.read. clusterManagement.clusterPolicies.read, clusterManagement.{nodeConfigurations,nodes}.read, clusterOptimization.settings.read
    view: {
      permissions: [
        'evictor.GetAdvancedConfig',
        'clusterPolicies.GetClusterPolicies',
        'nodeConfigurations.GetConfiguration',
        'clusters.GetClusterSettings',
        'settings.GetKarpenterDefinitionsMigrationIntent',
        'nodeConfigurations.FilterInstanceTypes',
        'nodes.GetClusterNodeConstraints',
        'nodeConfigurations.GetSuggestedConfiguration',
        'nodeConfigurations.ListConfigurations',
        'nodeConfigurations.ListNodeTemplates',
      ],
      flags: ['ui__page-modules--cluster__autoscaler__settings'],
    },
    // clusterManagement.nodeConfigurations.create
    create: {
      permissions: [
        'nodeConfigurations.CreateConfiguration',
        'nodeConfigurations.CreateNodeTemplate',
      ],
      flags: ['ui__page-modules--cluster__autoscaler__settings'],
    },
    // clusterOptimization.evictor.write, clusterManagement.clusterPolicies.write, clusterManagement.nodeConfigurations.write, clusterManagement.nodeConfigurations.write
    update: {
      permissions: [
        'evictor.UpsertAdvancedConfig',
        'clusterPolicies.UpsertClusterPolicies',
        'settings.MigrateKarpenterDefinitions',
        'nodeConfigurations.SetDefault',
        'nodeConfigurations.UpdateConfiguration',
        'nodeConfigurations.UpdateNodeTemplate',
      ],
      flags: ['ui__page-modules--cluster__autoscaler__settings'],
    },
    // clusterManagement.nodeConfigurations.delete
    delete: {
      permissions: [
        'nodeConfigurations.DeleteConfiguration',
        'nodeConfigurations.DeleteNodeTemplate',
      ],
      flags: ['ui__page-modules--cluster__autoscaler__settings'],
    },
  },
  ClusterPodMutations: {
    // clusterManagement.clusterPolicies.read, clusterManagement.nodeConfigurations.read, clusterOptimization.settings.read, clusterOptimization.podMutations.read
    view: {
      permissions: [
        'clusterPolicies.GetClusterPolicies',
        'nodeConfigurations.ListConfigurations',
        'nodeConfigurations.ListNodeTemplates',
        'components.GetInstallCmdForMutator',
        'podMutation.GetMutatorStatus',
        'podMutation.GetPodMutation',
        'podMutation.ListPodMutations',
      ],
      flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
    },
    // clusterOptimization.podMutations.create
    create: {
      permissions: ['podMutation.CreatePodMutation'],
      flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
    },
    // clusterOptimization.podMutations.write
    update: {
      permissions: ['podMutation.UpdatePodMutation'],
      flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
    },
    // clusterOptimization.podMutations.delete
    delete: {
      permissions: ['podMutation.DeletePodMutation'],
      flags: ['ui__page-modules--cluster__autoscaler__pod-mutations'],
    },
  },
  ClusterRebalancer: {
    // clusterOptimization.{rebalancing,workloads}.read
    view: {
      permissions: [
        'workloads.GetClusterWorkloads',
        'workloads.GetProblematicNodes',
        'workloads.GetProblematicWorkloads',
        'workloads.GetRebalancedWorkloads',
        'rebalancing.ListRebalancingPlans',
        'rebalancing.GetRebalancingPlan',
        'rebalancing.GetRebalancingJob',
        'rebalancing.GetRebalancingSchedule',
        'rebalancing.ListAvailableRebalancingTZ',
        'rebalancing.ListRebalancingJobs',
        'rebalancing.ListRebalancingSchedules',
      ],
      flags: ['ui__page-modules--cluster__rebalancer'],
    },
    // clusterOptimization.rebalancing.create
    create: {
      permissions: [
        'rebalancing.GenerateRebalancingPlan',
        'rebalancing.CreateRebalancingJob',
        'rebalancing.CreateRebalancingSchedule',
      ],
      flags: ['ui__page-modules--cluster__rebalancer'],
    },
    // clusterOptimization.rebalancing.write
    update: {
      permissions: [
        'rebalancing.ExecuteRebalancingPlan',
        'rebalancing.UpdateRebalancingJob',
        'rebalancing.UpdateRebalancingSchedule',
      ],
      flags: ['ui__page-modules--cluster__rebalancer'],
    },
    // clusterOptimization.rebalancing.delete
    delete: {
      permissions: [
        'rebalancing.DeleteRebalancingJob',
        'rebalancing.DeleteRebalancingSchedule',
      ],
      flags: ['ui__page-modules--cluster__rebalancer'],
    },
  },
  ClusterAuditLog: {
    // organizationManagement.audit.read
    view: {
      permissions: ['audit.ListAuditEntries'],
      flags: ['ui__page-modules--cluster__audit-log'],
    },
  },
  FeatureChargebee: {
    view: {
      flags: ['ui__external-features--chargebee'],
    },
  },
  FeatureGoogleOptimize: {
    view: {
      flags: ['ui__external-features--google-optimize'],
    },
  },
  FeatureGoogleTagManager: {
    view: {
      flags: ['ui__external-features--google-tag-manager'],
    },
  },
  FeatureHubspot: {
    view: {
      flags: ['ui__external-features--hubspot'],
    },
  },
  FeatureSentry: {
    view: {
      flags: ['ui__external-features--sentry'],
    },
  },
  FeatureOrganizationRBACV2: {
    view: {
      flags: ['wire-rbacv2-enabled'],
    },
  },
  FeatureOrganizationSecurityPolicyEnforcement: {
    view: {
      flags: ['ui__standalone--security-policy-enforcement'],
    },
  },
  FeatureClusterCostMonitoringBlurOptimizedNodes: {
    view: {
      flags: ['ui__standalone--available-savings-blur-optimized-nodes'],
    },
  },
  FeatureClusterCostMonitoringSavingsProgress: {
    view: {
      flags: ['ui__page-modules--cluster__savings-progress'],
    },
  },
  FeatureClusterCostMonitoringShortDateRange: {
    view: {
      flags: ['ui__standalone--cost-monitoring-short-date-range'],
    },
  },
  FeatureClusterAutoscalerSwitch: {
    view: {
      flags: ['ui__standalone--autoscaler-switch'],
    },
  },
  FeatureClusterCostMonitoringSavingsPercentages: {
    view: {
      flags: ['ui__standalone--cost-monitoring__show-savings_percentages'],
    },
  },
  FeatureDatabaseOptimizationPhase2: {
    view: {
      flags: ['ui__standalone--enable-dbo-phase-2'],
    },
  },
  FeatureOrganizationSecurityCustomDenyLists: {
    view: {
      flags: ['ui__standalone--security-custom-deny-lists'],
    },
  },
};
