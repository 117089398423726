import { cloneElement, ReactNode } from 'react';

import pluralize from 'pluralize';

import { Icons, mergeSx } from '@cast/design-system';

import { HexIcon, HexIconProps } from 'components/hex-icons';
import { StatusMessage, StatusMessageProps } from 'components/messages';

type NoDataProps = Omit<StatusMessageProps, 'title'> & {
  title?: ReactNode;
  entity?: string;
  hexProps?: HexIconProps;
};

export const NoData = ({
  icon = <Icons.BellSimpleRinging weight="fill" />,
  entity,
  sx,
  hexProps = { iconColor: 'grey.500', hexColor: 'grey.300' },
  ...rest
}: NoDataProps) => {
  return (
    <StatusMessage
      icon={
        <HexIcon
          size={64}
          icon={cloneElement(icon, {
            weight: 'fill',
          })}
          {...hexProps}
        />
      }
      title={`No ${entity ? pluralize(entity, 2) : 'data'}`}
      titleProps={{ sx: { margin: 0 } }}
      sx={mergeSx(
        {
          marginTop: '32px',
        },
        sx
      )}
      testId="no-data"
      {...rest}
    />
  );
};
