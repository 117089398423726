/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { BillingV1GetUsageReportResponse } from '../models';
// @ts-ignore
import type { HostedPage } from '../models';
// @ts-ignore
import type { PortalSession } from '../models';
// @ts-ignore
import type { Subscription } from '../models';
/**
 * BillingAPIApi - axios parameter creator
 * @export
 */
export const BillingAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary GetUsageReport returns resource usage report.
         * @param {string} periodFrom Start time of resource usage period.
         * @param {string} periodTo End time of resource usage period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAPIGetUsageReport: async (periodFrom: string, periodTo: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodFrom' is not null or undefined
            assertParamExists('billingAPIGetUsageReport', 'periodFrom', periodFrom)
            // verify required parameter 'periodTo' is not null or undefined
            assertParamExists('billingAPIGetUsageReport', 'periodTo', periodTo)
            const localVarPath = `/v1/billing/usage-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (periodFrom !== undefined) {
                localVarQueryParameter['period.from'] = (periodFrom as any instanceof Date) ?
                    (periodFrom as any).toISOString() :
                    periodFrom;
            }

            if (periodTo !== undefined) {
                localVarQueryParameter['period.to'] = (periodTo as any instanceof Date) ?
                    (periodTo as any).toISOString() :
                    periodTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee portal session. Can be tailored with specific portal URL. When application/json media type is requested - returns portal session object for generating a chargeBee hosted portal session popup.
         * @summary Single sign-on of ChargeBee portal.
         * @param {string} [forwardUrl] Desired destination URL within ChargeBee. See https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session_forward_url for more details. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSso: async (forwardUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/billing/sso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forwardUrl !== undefined) {
                localVarQueryParameter['forwardUrl'] = forwardUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves user\'s subscription details from ChargeBee.
         * @summary Retrieves current user\'s subscription details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscription: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/billing/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee default subscription checkout form. When application/json media type is requested - returns hosted page object for generating checkout popup in end-customer\'s browser session.
         * @summary Checkout current user\'s subscription.
         * @param {string} [plan] Checkout specific subscription plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCheckout: async (plan?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/billing/subscription/checkout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (plan !== undefined) {
                localVarQueryParameter['plan'] = plan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingAPIApi - functional programming interface
 * @export
 */
export const BillingAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary GetUsageReport returns resource usage report.
         * @param {string} periodFrom Start time of resource usage period.
         * @param {string} periodTo End time of resource usage period.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAPIGetUsageReport(periodFrom: string, periodTo: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingV1GetUsageReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAPIGetUsageReport(periodFrom, periodTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingAPIApi.billingAPIGetUsageReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee portal session. Can be tailored with specific portal URL. When application/json media type is requested - returns portal session object for generating a chargeBee hosted portal session popup.
         * @summary Single sign-on of ChargeBee portal.
         * @param {string} [forwardUrl] Desired destination URL within ChargeBee. See https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session_forward_url for more details. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSso(forwardUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PortalSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSso(forwardUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingAPIApi.billingSso']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieves user\'s subscription details from ChargeBee.
         * @summary Retrieves current user\'s subscription details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscription(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Subscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscription(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingAPIApi.subscription']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee default subscription checkout form. When application/json media type is requested - returns hosted page object for generating checkout popup in end-customer\'s browser session.
         * @summary Checkout current user\'s subscription.
         * @param {string} [plan] Checkout specific subscription plan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionCheckout(plan?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HostedPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionCheckout(plan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BillingAPIApi.subscriptionCheckout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BillingAPIApi - factory interface
 * @export
 */
export const BillingAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary GetUsageReport returns resource usage report.
         * @param {BillingAPIApiBillingAPIGetUsageReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAPIGetUsageReport(requestParameters: BillingAPIApiBillingAPIGetUsageReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<BillingV1GetUsageReportResponse> {
            return localVarFp.billingAPIGetUsageReport(requestParameters.periodFrom, requestParameters.periodTo, options).then((request) => request(axios, basePath));
        },
        /**
         * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee portal session. Can be tailored with specific portal URL. When application/json media type is requested - returns portal session object for generating a chargeBee hosted portal session popup.
         * @summary Single sign-on of ChargeBee portal.
         * @param {BillingAPIApiBillingSsoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSso(requestParameters: BillingAPIApiBillingSsoRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PortalSession> {
            return localVarFp.billingSso(requestParameters.forwardUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves user\'s subscription details from ChargeBee.
         * @summary Retrieves current user\'s subscription details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscription(options?: RawAxiosRequestConfig): AxiosPromise<Subscription> {
            return localVarFp.subscription(options).then((request) => request(axios, basePath));
        },
        /**
         * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee default subscription checkout form. When application/json media type is requested - returns hosted page object for generating checkout popup in end-customer\'s browser session.
         * @summary Checkout current user\'s subscription.
         * @param {BillingAPIApiSubscriptionCheckoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCheckout(requestParameters: BillingAPIApiSubscriptionCheckoutRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<HostedPage> {
            return localVarFp.subscriptionCheckout(requestParameters.plan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for billingAPIGetUsageReport operation in BillingAPIApi.
 * @export
 * @interface BillingAPIApiBillingAPIGetUsageReportRequest
 */
export interface BillingAPIApiBillingAPIGetUsageReportRequest {
    /**
     * Start time of resource usage period.
     * @type {string}
     * @memberof BillingAPIApiBillingAPIGetUsageReport
     */
    readonly periodFrom: string

    /**
     * End time of resource usage period.
     * @type {string}
     * @memberof BillingAPIApiBillingAPIGetUsageReport
     */
    readonly periodTo: string
}

/**
 * Request parameters for billingSso operation in BillingAPIApi.
 * @export
 * @interface BillingAPIApiBillingSsoRequest
 */
export interface BillingAPIApiBillingSsoRequest {
    /**
     * Desired destination URL within ChargeBee. See https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session_forward_url for more details. 
     * @type {string}
     * @memberof BillingAPIApiBillingSso
     */
    readonly forwardUrl?: string
}

/**
 * Request parameters for subscriptionCheckout operation in BillingAPIApi.
 * @export
 * @interface BillingAPIApiSubscriptionCheckoutRequest
 */
export interface BillingAPIApiSubscriptionCheckoutRequest {
    /**
     * Checkout specific subscription plan.
     * @type {string}
     * @memberof BillingAPIApiSubscriptionCheckout
     */
    readonly plan?: string
}

/**
 * BillingAPIApi - object-oriented interface
 * @export
 * @class BillingAPIApi
 * @extends {BaseAPI}
 */
export class BillingAPIApi extends BaseAPI {
    /**
     * 
     * @summary GetUsageReport returns resource usage report.
     * @param {BillingAPIApiBillingAPIGetUsageReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAPIApi
     */
    public billingAPIGetUsageReport(requestParameters: BillingAPIApiBillingAPIGetUsageReportRequest, options?: RawAxiosRequestConfig) {
        return BillingAPIApiFp(this.configuration).billingAPIGetUsageReport(requestParameters.periodFrom, requestParameters.periodTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee portal session. Can be tailored with specific portal URL. When application/json media type is requested - returns portal session object for generating a chargeBee hosted portal session popup.
     * @summary Single sign-on of ChargeBee portal.
     * @param {BillingAPIApiBillingSsoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAPIApi
     */
    public billingSso(requestParameters: BillingAPIApiBillingSsoRequest = {}, options?: RawAxiosRequestConfig) {
        return BillingAPIApiFp(this.configuration).billingSso(requestParameters.forwardUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves user\'s subscription details from ChargeBee.
     * @summary Retrieves current user\'s subscription details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAPIApi
     */
    public subscription(options?: RawAxiosRequestConfig) {
        return BillingAPIApiFp(this.configuration).subscription(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Builds URL to use in end-customer\'s browser session as an iframe. URL will direct to ChargeBee default subscription checkout form. When application/json media type is requested - returns hosted page object for generating checkout popup in end-customer\'s browser session.
     * @summary Checkout current user\'s subscription.
     * @param {BillingAPIApiSubscriptionCheckoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAPIApi
     */
    public subscriptionCheckout(requestParameters: BillingAPIApiSubscriptionCheckoutRequest = {}, options?: RawAxiosRequestConfig) {
        return BillingAPIApiFp(this.configuration).subscriptionCheckout(requestParameters.plan, options).then((request) => request(this.axios, this.basePath));
    }
}

