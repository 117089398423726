import {
  useState,
  useCallback,
  useMemo,
  useRef,
  ReactNode,
  useImperativeHandle,
  forwardRef,
} from 'react';

import { Box, Stack, SxProps } from '@mui/material';
import { DayPicker, DayPickerProps, TZDate } from 'react-day-picker';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

import {
  blueTheme,
  getMonthIndex,
  getScrollableMonths,
  greenTheme,
} from './_utils';
import { DayWithEvents } from './DayWithEvents';
import { useDatePickerContext } from './useDatePickerContext';
import { WeekdaysHeader } from './WeekdaysHeader';
import { VirtuosoScroller } from '../../scroller';
import { InfiniteDayPickerRef } from '../types';
import { utcNow } from '../utils';

const LIST_HEIGHT = 320;
const PICKER_WIDTH = 272;

export type InfiniteDayPickerProps = {
  dayPickerProps: DayPickerProps;
  startMonth?: TZDate;
  initialMonth: TZDate;
  endMonth?: TZDate;
  variant?: 'main' | 'secondary';
  help?: ReactNode;
  helpSx?: SxProps;
};

export const InfiniteDayPicker = forwardRef<
  InfiniteDayPickerRef,
  InfiniteDayPickerProps
>(
  (
    {
      startMonth,
      initialMonth,
      endMonth,
      variant = 'main',
      dayPickerProps,
      help,
      helpSx,
    },
    ref
  ) => {
    const ctx = useDatePickerContext();

    const virtuosoRef = useRef<VirtuosoHandle>(null);
    const [height, setHeight] = useState<number>(LIST_HEIGHT);
    const handleListHeight = useCallback((h: number) => {
      setHeight(h);
    }, []);

    const buildTheme = variant === 'main' ? blueTheme : greenTheme;

    const months = useMemo(() => {
      return getScrollableMonths(
        startMonth ?? new TZDate(2020, 0, 1, ctx.timezone),
        endMonth ?? new TZDate(utcNow(), ctx.timezone),
        ctx.timezone
      );
    }, [endMonth, startMonth, ctx.timezone]);

    const [visibleMonthIndex, setVisibleMonthIndex] = useState(
      getMonthIndex(initialMonth, months)
    );

    useImperativeHandle(
      ref,
      () => ({
        scrollToMonth: (month) => {
          if (virtuosoRef.current) {
            const monthToScroll =
              typeof month === 'string'
                ? new TZDate(month, ctx.timezone)
                : month;

            const activeMonthIndex = getMonthIndex(monthToScroll, months);

            if (
              activeMonthIndex !== -1 &&
              visibleMonthIndex !== activeMonthIndex
            ) {
              virtuosoRef.current.scrollToIndex(activeMonthIndex);
            }
          }
        },
      }),
      [ctx.timezone, months, visibleMonthIndex]
    );

    return (
      <Stack width={PICKER_WIDTH}>
        <WeekdaysHeader />
        <Box
          borderTop="grey.100"
          boxShadow="inset 0px 12px 10px -10px  rgba(109, 127, 136, 0.06), inset 0px -12px 6px -6px  rgba(109, 127, 136, 0.06)"
          sx={buildTheme}
          px={24}
          position="relative"
        >
          {help && (
            <Box
              className="date-picker-help-container"
              data-testid="date-picker-help-container"
              sx={helpSx}
            >
              {help}
            </Box>
          )}
          <Virtuoso
            ref={virtuosoRef}
            totalCount={months.length}
            initialTopMostItemIndex={
              visibleMonthIndex === -1 ? 0 : visibleMonthIndex
            }
            rangeChanged={(range) => {
              setVisibleMonthIndex(range.startIndex);
            }}
            itemContent={(index) => (
              <DayPicker
                className={`rdp-mode_${ctx.mode}`}
                {...dayPickerProps}
                components={{
                  DayButton: DayWithEvents,
                  ...dayPickerProps.components,
                }}
                month={months[index].date}
                numberOfMonths={1}
                hideNavigation
                hideWeekdays
                weekStartsOn={1}
                timeZone={ctx.timezone}
              />
            )}
            totalListHeightChanged={handleListHeight}
            style={{
              height,
              maxHeight: LIST_HEIGHT,
            }}
            components={{
              Scroller: VirtuosoScroller,
            }}
            overscan={LIST_HEIGHT}
          />
        </Box>
      </Stack>
    );
  }
);

InfiniteDayPicker.displayName = 'InfiniteDayPicker';
