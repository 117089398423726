import { useEffect, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { usePrevious } from '@cast/utils';

import { isE2E } from 'utils/isE2E';

export const useIsAnimationActive = <T>(value: T | T[]) => {
  const previousValue = usePrevious<T | T[]>(value);
  const [isAnimationActive, setIsAnimationActive] = useState<boolean>(false);

  useEffect(() => {
    if (previousValue && !isEqual(previousValue, value)) {
      setIsAnimationActive(true);
    }
  }, [value, previousValue]);

  // Disables animation in E2E/integration tests for faster tests
  if (isE2E()) {
    return false;
  }

  return isAnimationActive;
};
