/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ListComponentsResponse } from '../models';
// @ts-ignore
import type { Status } from '../models';
/**
 * ComponentsAPIApi - axios parameter creator
 * @export
 */
export const ComponentsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the list of CASTware components managed by AI Enabler for given organization.  Max page limit is 1000.
         * @summary List CASTware components.
         * @param {string} organizationId The ID of the organization.
         * @param {string} clusterId The ID of the cluster. Use \&quot;-\&quot; placeholder to list CASTware components across all clusters.
         * @param {string} [pageLimit] Defines the number of items that should be returned
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsAPIListComponents: async (organizationId: string, clusterId: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('componentsAPIListComponents', 'organizationId', organizationId)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('componentsAPIListComponents', 'clusterId', clusterId)
            const localVarPath = `/ai-optimizer/v1beta/organizations/{organizationId}/clusters/{clusterId}/components`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComponentsAPIApi - functional programming interface
 * @export
 */
export const ComponentsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the list of CASTware components managed by AI Enabler for given organization.  Max page limit is 1000.
         * @summary List CASTware components.
         * @param {string} organizationId The ID of the organization.
         * @param {string} clusterId The ID of the cluster. Use \&quot;-\&quot; placeholder to list CASTware components across all clusters.
         * @param {string} [pageLimit] Defines the number of items that should be returned
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async componentsAPIListComponents(organizationId: string, clusterId: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListComponentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.componentsAPIListComponents(organizationId, clusterId, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComponentsAPIApi.componentsAPIListComponents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ComponentsAPIApi - factory interface
 * @export
 */
export const ComponentsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComponentsAPIApiFp(configuration)
    return {
        /**
         * Gets the list of CASTware components managed by AI Enabler for given organization.  Max page limit is 1000.
         * @summary List CASTware components.
         * @param {ComponentsAPIApiComponentsAPIListComponentsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        componentsAPIListComponents(requestParameters: ComponentsAPIApiComponentsAPIListComponentsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListComponentsResponse> {
            return localVarFp.componentsAPIListComponents(requestParameters.organizationId, requestParameters.clusterId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for componentsAPIListComponents operation in ComponentsAPIApi.
 * @export
 * @interface ComponentsAPIApiComponentsAPIListComponentsRequest
 */
export interface ComponentsAPIApiComponentsAPIListComponentsRequest {
    /**
     * The ID of the organization.
     * @type {string}
     * @memberof ComponentsAPIApiComponentsAPIListComponents
     */
    readonly organizationId: string

    /**
     * The ID of the cluster. Use \&quot;-\&quot; placeholder to list CASTware components across all clusters.
     * @type {string}
     * @memberof ComponentsAPIApiComponentsAPIListComponents
     */
    readonly clusterId: string

    /**
     * Defines the number of items that should be returned
     * @type {string}
     * @memberof ComponentsAPIApiComponentsAPIListComponents
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof ComponentsAPIApiComponentsAPIListComponents
     */
    readonly pageCursor?: string
}

/**
 * ComponentsAPIApi - object-oriented interface
 * @export
 * @class ComponentsAPIApi
 * @extends {BaseAPI}
 */
export class ComponentsAPIApi extends BaseAPI {
    /**
     * Gets the list of CASTware components managed by AI Enabler for given organization.  Max page limit is 1000.
     * @summary List CASTware components.
     * @param {ComponentsAPIApiComponentsAPIListComponentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsAPIApi
     */
    public componentsAPIListComponents(requestParameters: ComponentsAPIApiComponentsAPIListComponentsRequest, options?: RawAxiosRequestConfig) {
        return ComponentsAPIApiFp(this.configuration).componentsAPIListComponents(requestParameters.organizationId, requestParameters.clusterId, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }
}

