import { InfiniteData, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  RebalancingPlan,
  RebalancingPlanStatusEnum,
  RebalancingPlansResponse,
  RecursiveRequired,
} from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';
import { REBALANCING_IN_PROGRESS_STATES } from 'misc/constants';

type UseRebalancePlanQuery = {
  isLoading: boolean;
  rebalancePlan?: RecursiveRequired<RebalancingPlan>;
};

type UseRebalancePlanQueryProps = {
  clusterId?: string;
  rebalancePlanId?: string;
  includeOperations?: boolean;
  includeConfigurations?: boolean;
};

export const useRebalancePlanQuery = ({
  clusterId,
  rebalancePlanId,
  includeOperations = false,
  includeConfigurations = false,
}: UseRebalancePlanQueryProps): UseRebalancePlanQuery => {
  const queryClient = useQueryClient();
  const { isLoading, data: rebalancePlan } = useQuery({
    queryKey: [
      QueryKeys.EXTERNAL_CLUSTER_REBALANCE_PLAN,
      clusterId,
      rebalancePlanId,
      includeOperations,
      includeConfigurations,
    ],
    queryFn: async () => {
      const { data } = await apiClient.autoscaler.getRebalancingPlan({
        clusterId: clusterId!,
        rebalancingPlanId: rebalancePlanId!,
        includeOperations,
        includeConfigurations,
      });

      const queryData: InfiniteData<RebalancingPlansResponse> | undefined =
        queryClient.getQueryData([
          QueryKeys.EXTERNAL_CLUSTER_REBALANCE_PLAN,
          clusterId,
          20,
          undefined,
          false,
          true,
        ]);

      if (queryData && includeConfigurations) {
        const newPages = queryData.pages.map((page) => {
          const index = page.items?.findIndex(
            (plan) => plan.rebalancingPlanId === rebalancePlanId
          );

          if (index && page.items) {
            page.items[index] = data;
          }

          return page;
        });

        queryClient.setQueryData(
          [
            QueryKeys.EXTERNAL_CLUSTER_REBALANCE_PLAN,
            clusterId,
            20,
            undefined,
            false,
            true,
          ],
          { pages: newPages, pageParams: queryData.pageParams }
        );
      }

      return data || null;
    },
    enabled: !!clusterId && !!rebalancePlanId,
    refetchInterval: ({ queryKey }) => {
      const data = queryClient.getQueryData<any>(queryKey);
      if (
        data &&
        ![
          RebalancingPlanStatusEnum.generating,
          ...REBALANCING_IN_PROGRESS_STATES,
        ].includes(data.status!)
      ) {
        return false;
      }

      return 5000;
    },
  });

  return {
    isLoading,
    rebalancePlan: rebalancePlan as RecursiveRequired<RebalancingPlan>,
  };
};
