export enum QueryKeys {
  FLAGS = 'flags',
  AUDIT = 'audit',

  API_ACCESS_KEYS = 'api-access-keys',
  AUTH_SESSION = 'auth-session',
  COUNTRY_CODE = 'country-code',

  EXTERNAL_CLUSTERS = 'external-clusters',
  EXTERNAL_CLUSTER = 'external-cluster',
  EXTERNAL_CLUSTER_REGISTRATION_SCRIPT = 'external-cluster-registration-script',
  EXTERNAL_CLUSTER_CREDENTIALS = 'external-cluster-credentials',
  EXTERNAL_CLUSTER_CLEANUP = 'external-cluster-cleanup',
  EXTERNAL_CLUSTER_NODES = 'external-cluster-nodes',
  EXTERNAL_CLUSTER_NODE = 'external-cluster-node',
  EXTERNAL_CLUSTER_REBALANCE_WORKLOADS = 'external-cluster-rebalance-workloads',
  EXTERNAL_CLUSTER_REBALANCE_PLAN = 'external-cluster-rebalance-plan',

  POLICIES = 'autoscaler-policies',
  POLICY_NODE_CONSTRAINTS = 'autoscaler-policy-node-constraints',
  SELECTION_TEMPLATES = 'autoscaler-selection-templates',
  AUTOSCALER_FEATURES = 'autoscaler-features',
  KARPENTER_INTENTS = 'karpenter-intents',
  SELECTION_TEMPLATE = 'autoscaler-selection-template',
  SELECTION_TEMPLATE_PREVIEW = 'autoscaler-selection-template-preview',
  ADVANCED_EVICTOR = 'autoscaler-advanced-evictor-config',
  POD_MUTATIONS = 'autoscaler-pod-mutations',
  POD_MUTATION = 'autoscaler-pod-mutation',
  POD_MUTATOR_SCRIPT = 'autoscaler-pod-mutator-script',
  POD_MUTATOR_STATUS = 'autoscaler-pod-mutator-status',

  CLUSTER_COST_REPORT = 'cluster-cost-report',
  CLUSTER_EFFICIENCY_DETAILS = 'cluster-efficiency-details',
  NAMESPACE_FILTERS = 'namespace-filters',
  NAMESPACE_REPORT = 'namespace-report',
  NAMESPACES_COST_SUMMARY_REPORT = 'namespaces-cost-summary',
  NAMESPACES_COST_TOTAL_COSTS = 'namespaces-cost-total-costs',
  NAMESPACES_DATA_TRANSFER_SUMMARY_REPORT = 'namespaces-data-transfer-summary',
  NAMESPACES_DATA_TRANSFER_TOTAL_COSTS = 'namespaces-data-transfer-total-costs',
  WORKLOADS_COST_REPORT = 'workloads-cost-report',
  WORKLOAD_COST_REPORT = 'workload-cost-report',
  WORKLOADS_COST_EFFICIENCY = 'workloads-cost-efficiency',
  WORKLOADS_LABELS_COST_REPORT = 'workloads-labels-cost-report',
  WORKLOADS_LABEL_NAMES_COST_REPORT = 'workload-labels-names-cost-report',
  WORKLOADS_LABEL_VALUES_COST_REPORT = 'workloads-label-values-cost-report',
  WORKLOADS_GPU_UTILIZATION = 'workloads-gpu-utilization',
  WORKLOADS_GPU_UTILIZATION_SUMMARY = 'workloads-gpu-utilization-summary',
  WORKLOADS_GPU_WASTE_COST_IMPACT = 'workloads-gpu-waste-cost-impact',
  WORKLOAD_GPU_UTILIZATION_SUMMARY = 'workload-gpu-utilization-summary',
  WORKLOAD_EFFICIENCY_DETAILS = 'workload-efficiency-details',
  WORKLOAD_DATA_TRANSFER_COST = 'workload-data-transfer-cost',
  WORKLOAD_DESTINATIONS = 'workload-destinations',
  WORKLOAD_DESTINATIONS_HISTORY = 'workload-destinations-history',
  ORGANIZATION_DAILY_COST = 'organization-daily-cost',
  ORGANIZATION_CLUSTERS_REPORT = 'organization-clusters-report',
  ORGANIZATION_CLUSTERS_SUMMARY = 'organizations-clusters-summary',
  CLUSTER_DASHBOARD_SUMMARY = 'cluster-dashboard-summary',
  CLUSTER_DASHBOARD_RESOURCE_USAGE = 'cluster-dashboard-resource-usage',
  CLUSTER_UNSCHEDULED_PODS = 'cluster-unscheduled-pods',
  CLUSTER_DATA_TRANSFER_COST = 'cluster-data-transfer-cost',
  CLUSTER_ANOMALIES = 'cluster-anomalies',
  EGRESS_D_SCRIPT = 'egress-d-script',
  GPU_EXPORTER_SCRIPT = 'gpu-exporter-script',
  REPORTING_CAPABILITIES = 'reporting-capabilities',
  RIGHTSIZING_PATCH_SCRIPT = 'rightsizing-patch-script',

  ALLOCATION_GROUPS = 'allocation-groups',
  ALLOCATION_GROUPS_SUMMARY = 'allocation-groups-summary',
  ALLOCATION_GROUPS_SUMMARIES = 'allocation-groups-summaries',
  ALLOCATION_GROUPS_TIMED_SUMMARIES = 'allocation-groups-timed-summaries',
  ALLOCATION_GROUPS_TOTAL_COSTS = 'allocation-groups-total-costs',
  ALLOCATION_GROUPS_WORKLOADS = 'allocation-groups-workloads',
  ALLOCATION_GROUPS_WORKLOADS_EFFICIENCY = 'allocation-groups-workloads-efficiency',
  ALLOCATION_GROUPS_DATA_TRANSFER_SUMMARY = 'allocation-groups-data-transfer-summary',
  ALLOCATION_GROUPS_DATA_TRANSFER_WORKLOADS = 'allocation-groups-data-transfer-workloads',
  ALLOCATION_GROUPS_EFFICIENCY_SUMMARY = 'allocation-groups-efficiency-summary',

  COST_COMPARISON = 'cost-comparison',

  CPU_USAGE_REPORT = 'cpu-usage-report',
  CPU_USAGE_SUMMARY = 'cpu-usage-summary',

  BILLING_REPORT = 'billing-report',

  ESTIMATED_SAVINGS = 'estimated-savings',
  ESTIMATED_SAVINGS_HISTORY = 'estimated-savings-history',
  RIGHTSIZING_SUMMARY = 'rightsizing-summary',

  SAVINGS_PROGRESS = 'savings-progress',

  SCHEDULED_REBALANCING_SCHEDULES = 'scheduled-rebalancing-schedules',
  SCHEDULED_REBALANCING_JOBS = 'scheduled-rebalancing-jobs',
  SCHEDULED_REBALANCING_TIMEZONES = 'scheduled-rebalancing-timezones',

  USER_PROFILE = 'user-profile',

  ORGANIZATIONS = 'organizations',
  ORGANIZATION_USERS = 'organization-users',
  ORGANIZATION_INVITATIONS = 'organization-invitations',

  NODE_CONFIG_TEMPLATES = 'node-config-templates',
  NODE_CONFIG_TEMPLATE = 'node-config-template',
  NODE_CONFIG_SUGGESTIONS = 'node-config-suggestions',
  NODE_CONFIG_MAX_PODS_FORMULA_PRESETS = 'node-config-max-pods-formula-presets',

  NODES_WITH_ISSUES = 'nodes-with-issues',

  SECURITY_AGENT_STATUS = 'security-agent-status',
  SECURITY_AGENT_STATUSES = 'security-agent-statuses',
  SECURITY_BEST_PRACTICE_FILTERS = 'security-best-practice-filters',
  SECURITY_BEST_PRACTICE_CHECK = 'security-best-practice-check',
  SECURITY_BEST_PRACTICE_CHECKS = 'security-best-practice-checks',
  SECURITY_BEST_PRACTICE_SUMMARY = 'security-best-practice-summary',
  SECURITY_BEST_PRACTICE_CHECK_RESOURCES = 'security-best-practice-check-resources',
  SECURITY_BEST_PRACTICES_CHECK_EXCEPTIONS = 'security-best-practices-check-exceptions',
  SECURITY_BEST_PRACTICES_CHECK_EXCEPTIONS_RESOURCES = 'security-best-practices-check-exceptions-resources',
  SECURITY_VULNERABILITIES = 'security-vulnerabilities',
  SECURITY_OVERVIEW = 'security-overview',
  SECURITY_CONTAINER_IMAGES = 'security-container-images',
  SECURITY_CONTAINER_IMAGES_SUMMARY = 'security-container-images-summary',
  SECURITY_CONTAINER_IMAGES_FILTERS = 'security-container-images-filters',
  SECURITY_IMAGE_DETAILS = 'security-image-details',
  SECURITY_IMAGE_DIGESTS = 'security-image-digests',
  SECURITY_IMAGE_RESOURCES = 'security-image-resources',
  SECURITY_IMAGE_VULNERABILITIES = 'security-image-vulnerabilities',
  SECURITY_IMAGE_PACKAGE_VULNERABILITIES = 'security-image-package-vulnerabilities',
  SECURITY_IMAGE_PACKAGES = 'security-image-packages',
  SECURITY_ATTACK_PATHS = 'security-attack-paths',
  SECURITY_ATTACK_PATH_DETAILS = 'security-attack-path-details',
  SECURITY_RESOURCE_DETAILS = 'security-resource-details',
  SECURITY_ANOMALIES = 'security-anomalies',
  SECURITY_ANOMALY = 'security-anomaly',
  SECURITY_ANOMALY_EVENTS = 'security-anomaly-events',
  SECURITY_WORKLOADS = 'security-workloads',
  SECURITY_INTEGRATIONS = 'security-integrations',
  SECURITY_INTEGRATION_PROJECTS = 'security-integration-projects',
  SECURITY_IMAGE_ALTERNATIVES = 'security-image-alternatives',
  SECURITY_OVERVIEW_BEST_PRACTICE = 'security-overview-best-practice',
  SECURITY_IMAGE_SECURITY_OVERVIEW = 'security-image-security-overview',
  SECURITY_ATTACK_PATHS_OVERVIEW = 'security-attack-paths-overview',
  SECURITY_ANOMALIES_OVERVIEW = 'security-anomalies-overview',
  SECURITY_CLUSTERS_SETTINGS = 'security-clusters-settings',
  SECURITY_RUNTIME_RULES = 'security-runtime-rules',
  SECURITY_RUNTIME_RULE = 'security-runtime-rule',
  SECURITY_RUNTIME_EVENTS_PAGINATED = 'security-runtime-events-paginated',
  SECURITY_RUNTIME_EVENTS_INFINITE = 'security-runtime-events-infinite',
  SECURITY_RUNTIME_EVENTS_PROCESS_TREE = 'security-runtime-events-process-tree',
  SECURITY_RUNTIME_CUSTOM_DENY_LISTS = 'security-runtime-custom-deny-lists',

  WOOP_WORKLOADS_LIST = 'woop-workloads-list',
  WOOP_WORKLOAD = 'woop-workload',
  WOOP_AGENT_STATUS = 'woop-agent-status',
  WOOP_INSTALL_AGENT_CMD = 'woop-install-agent-cmd',
  WOOP_EVENTS = 'woop-events',
  WOOP_POLICIES = 'woop-policies',
  WOOP_WORKLOADS_SUMMARY = 'woop-workloads-summary',

  COMMITMENTS_LIST = 'commitments-list',
  COMMITMENTS_ASSIGNMENTS_LIST = 'commitments-assignments-list',
  COMMITMENTS_GCP_IMPORT_SCRIPT = 'commitments-gcp-import-script',

  NOTIFICATIONS = 'notifications',
  WEBHOOKS = 'webhooks',
  WEBHOOK_CATEGORIES = 'webhook-categories',

  SSO_CONNECTIONS = 'sso-connections',
  SSO_CONNECTION = 'sso-connection',

  YAML_PREVIEW = 'yaml-preview',

  LLM_SUPPORTED_PROVIDERS = 'llm-supported-providers',
  LLM_PROVIDERS = 'llm-providers',
  LLM_MODELS = 'llm-models',
  LLM_SETTINGS = 'llm-settings',

  LLM_CHAT_COMPLETIONS_API_KEYS_SAVINGS = 'llm-chat-completions-api-keys-savings',
  LLM_CHAT_COMPLETIONS_RECOMMENDATIONS = 'llm-chat-completions-recommendations',
  LLM_CHAT_COMPLETIONS_USAGE = 'llm-chat-completions-usage',

  RBAC_ORGANIZATION_USER_GROUPS = 'rbac-organization-user-groups',
  RBAC_RESOLVE_ACCESS = 'rbac-resolve-access',
  RBAC_ROLES = 'rbac-roles',
  RBAC_ROLE_BINDINGS = 'rbac-role-bindings',
  RBAC_RESOURCES = 'rbac-resources',
  LLM_ROUTING_EVENTS = 'llm-routing-events',

  LLM_HOSTED_MODELS_SPECS = 'llm-hosted-models-specs',
  LLM_HOSTED_MODELS = 'llm-hosted-models',
  LLM_CASTWARE_COMPONENTS = 'llm-castware-components',

  DBO_DB_INSTANCES = 'dbo-db-instances',
  DBO_DB_INSTANCE = 'dbo-db-instance',
  DBO_ACCOUNTS = 'dbo-accounts',
  DBO_INSTANCE_ONBOARDING_SCRIPT = 'dbo-instance-onboarding-script',
  DBO_INSTANCE_ONBOARDING_STATUS = 'dbo-instance-onboarding-status',
  DBO_CACHE_CONFIGURATIONS = 'dbo-cache-configurations',
  DBO_CACHING_SUMMARY = 'dbo-caching-summary',
  DBO_CACHE_RULES = 'dbo-cache-rules',
  DBO_CACHE_QUERIES = 'dbo-cache-queries',

  DISCOUNTS_LIST = 'discounts-list',

  PRICING_CLUSTER_NODES_PRICING = 'pricing-cluster-nodes-pricing',
  PRICING_NODE_PRICING = 'pricing-node-pricing',

  INVENTORY_REGIONS = 'inventory-regions',
  INVENTORY_INSTANCE_TYPES = 'inventory-instance-types',
}
