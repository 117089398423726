/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiSsoV1beta1CreateSSOConnection } from '../models';
// @ts-ignore
import type { CastaiSsoV1beta1ListSSOConnectionsResponse } from '../models';
// @ts-ignore
import type { CastaiSsoV1beta1SSOConnection } from '../models';
// @ts-ignore
import type { CastaiSsoV1beta1TrustSSOConnectionDomainRequest } from '../models';
// @ts-ignore
import type { CastaiSsoV1beta1TrustSSOConnectionDomainResponse } from '../models';
// @ts-ignore
import type { CastaiSsoV1beta1UpdateSSOConnection } from '../models';
/**
 * SSOAPIApi - axios parameter creator
 * @export
 */
export const SSOAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create organization SSO connection.
         * @param {CastaiSsoV1beta1CreateSSOConnection} connection Connection to add to an organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPICreateSSOConnection: async (connection: CastaiSsoV1beta1CreateSSOConnection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connection' is not null or undefined
            assertParamExists('sSOAPICreateSSOConnection', 'connection', connection)
            const localVarPath = `/v1/sso-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete organization SSO connection.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIDeleteSSOConnection: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sSOAPIDeleteSSOConnection', 'id', id)
            const localVarPath = `/v1/sso-connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get organization SSO connection.
         * @param {string} id SSO connection ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIGetSSOConnection: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sSOAPIGetSSOConnection', 'id', id)
            const localVarPath = `/v1/sso-connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List organization SSO connections.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIListSSOConnections: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/sso-connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trusts a domain for SSO connection and returns the trusted connection provider name. Not visible in api documentation.
         * @param {CastaiSsoV1beta1TrustSSOConnectionDomainRequest} body Defines the container for the sso trust request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPITrustSSOConnectionDomain: async (body: CastaiSsoV1beta1TrustSSOConnectionDomainRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('sSOAPITrustSSOConnectionDomain', 'body', body)
            const localVarPath = `/v1/trust-sso-domain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization SSO connection.
         * @param {string} id 
         * @param {CastaiSsoV1beta1UpdateSSOConnection} connection Updated SSO connection object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIUpdateSSOConnection: async (id: string, connection: CastaiSsoV1beta1UpdateSSOConnection, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sSOAPIUpdateSSOConnection', 'id', id)
            // verify required parameter 'connection' is not null or undefined
            assertParamExists('sSOAPIUpdateSSOConnection', 'connection', connection)
            const localVarPath = `/v1/sso-connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SSOAPIApi - functional programming interface
 * @export
 */
export const SSOAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SSOAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create organization SSO connection.
         * @param {CastaiSsoV1beta1CreateSSOConnection} connection Connection to add to an organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sSOAPICreateSSOConnection(connection: CastaiSsoV1beta1CreateSSOConnection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiSsoV1beta1SSOConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sSOAPICreateSSOConnection(connection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SSOAPIApi.sSOAPICreateSSOConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete organization SSO connection.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sSOAPIDeleteSSOConnection(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sSOAPIDeleteSSOConnection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SSOAPIApi.sSOAPIDeleteSSOConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get organization SSO connection.
         * @param {string} id SSO connection ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sSOAPIGetSSOConnection(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiSsoV1beta1SSOConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sSOAPIGetSSOConnection(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SSOAPIApi.sSOAPIGetSSOConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List organization SSO connections.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sSOAPIListSSOConnections(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiSsoV1beta1ListSSOConnectionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sSOAPIListSSOConnections(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SSOAPIApi.sSOAPIListSSOConnections']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Trusts a domain for SSO connection and returns the trusted connection provider name. Not visible in api documentation.
         * @param {CastaiSsoV1beta1TrustSSOConnectionDomainRequest} body Defines the container for the sso trust request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sSOAPITrustSSOConnectionDomain(body: CastaiSsoV1beta1TrustSSOConnectionDomainRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiSsoV1beta1TrustSSOConnectionDomainResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sSOAPITrustSSOConnectionDomain(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SSOAPIApi.sSOAPITrustSSOConnectionDomain']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update organization SSO connection.
         * @param {string} id 
         * @param {CastaiSsoV1beta1UpdateSSOConnection} connection Updated SSO connection object.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sSOAPIUpdateSSOConnection(id: string, connection: CastaiSsoV1beta1UpdateSSOConnection, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiSsoV1beta1SSOConnection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sSOAPIUpdateSSOConnection(id, connection, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SSOAPIApi.sSOAPIUpdateSSOConnection']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SSOAPIApi - factory interface
 * @export
 */
export const SSOAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SSOAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create organization SSO connection.
         * @param {SSOAPIApiSSOAPICreateSSOConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPICreateSSOConnection(requestParameters: SSOAPIApiSSOAPICreateSSOConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiSsoV1beta1SSOConnection> {
            return localVarFp.sSOAPICreateSSOConnection(requestParameters.connection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete organization SSO connection.
         * @param {SSOAPIApiSSOAPIDeleteSSOConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIDeleteSSOConnection(requestParameters: SSOAPIApiSSOAPIDeleteSSOConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.sSOAPIDeleteSSOConnection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get organization SSO connection.
         * @param {SSOAPIApiSSOAPIGetSSOConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIGetSSOConnection(requestParameters: SSOAPIApiSSOAPIGetSSOConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiSsoV1beta1SSOConnection> {
            return localVarFp.sSOAPIGetSSOConnection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List organization SSO connections.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIListSSOConnections(options?: RawAxiosRequestConfig): AxiosPromise<CastaiSsoV1beta1ListSSOConnectionsResponse> {
            return localVarFp.sSOAPIListSSOConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trusts a domain for SSO connection and returns the trusted connection provider name. Not visible in api documentation.
         * @param {SSOAPIApiSSOAPITrustSSOConnectionDomainRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPITrustSSOConnectionDomain(requestParameters: SSOAPIApiSSOAPITrustSSOConnectionDomainRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiSsoV1beta1TrustSSOConnectionDomainResponse> {
            return localVarFp.sSOAPITrustSSOConnectionDomain(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization SSO connection.
         * @param {SSOAPIApiSSOAPIUpdateSSOConnectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sSOAPIUpdateSSOConnection(requestParameters: SSOAPIApiSSOAPIUpdateSSOConnectionRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiSsoV1beta1SSOConnection> {
            return localVarFp.sSOAPIUpdateSSOConnection(requestParameters.id, requestParameters.connection, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sSOAPICreateSSOConnection operation in SSOAPIApi.
 * @export
 * @interface SSOAPIApiSSOAPICreateSSOConnectionRequest
 */
export interface SSOAPIApiSSOAPICreateSSOConnectionRequest {
    /**
     * Connection to add to an organization.
     * @type {CastaiSsoV1beta1CreateSSOConnection}
     * @memberof SSOAPIApiSSOAPICreateSSOConnection
     */
    readonly connection: CastaiSsoV1beta1CreateSSOConnection
}

/**
 * Request parameters for sSOAPIDeleteSSOConnection operation in SSOAPIApi.
 * @export
 * @interface SSOAPIApiSSOAPIDeleteSSOConnectionRequest
 */
export interface SSOAPIApiSSOAPIDeleteSSOConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof SSOAPIApiSSOAPIDeleteSSOConnection
     */
    readonly id: string
}

/**
 * Request parameters for sSOAPIGetSSOConnection operation in SSOAPIApi.
 * @export
 * @interface SSOAPIApiSSOAPIGetSSOConnectionRequest
 */
export interface SSOAPIApiSSOAPIGetSSOConnectionRequest {
    /**
     * SSO connection ID.
     * @type {string}
     * @memberof SSOAPIApiSSOAPIGetSSOConnection
     */
    readonly id: string
}

/**
 * Request parameters for sSOAPITrustSSOConnectionDomain operation in SSOAPIApi.
 * @export
 * @interface SSOAPIApiSSOAPITrustSSOConnectionDomainRequest
 */
export interface SSOAPIApiSSOAPITrustSSOConnectionDomainRequest {
    /**
     * Defines the container for the sso trust request.
     * @type {CastaiSsoV1beta1TrustSSOConnectionDomainRequest}
     * @memberof SSOAPIApiSSOAPITrustSSOConnectionDomain
     */
    readonly body: CastaiSsoV1beta1TrustSSOConnectionDomainRequest
}

/**
 * Request parameters for sSOAPIUpdateSSOConnection operation in SSOAPIApi.
 * @export
 * @interface SSOAPIApiSSOAPIUpdateSSOConnectionRequest
 */
export interface SSOAPIApiSSOAPIUpdateSSOConnectionRequest {
    /**
     * 
     * @type {string}
     * @memberof SSOAPIApiSSOAPIUpdateSSOConnection
     */
    readonly id: string

    /**
     * Updated SSO connection object.
     * @type {CastaiSsoV1beta1UpdateSSOConnection}
     * @memberof SSOAPIApiSSOAPIUpdateSSOConnection
     */
    readonly connection: CastaiSsoV1beta1UpdateSSOConnection
}

/**
 * SSOAPIApi - object-oriented interface
 * @export
 * @class SSOAPIApi
 * @extends {BaseAPI}
 */
export class SSOAPIApi extends BaseAPI {
    /**
     * 
     * @summary Create organization SSO connection.
     * @param {SSOAPIApiSSOAPICreateSSOConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOAPIApi
     */
    public sSOAPICreateSSOConnection(requestParameters: SSOAPIApiSSOAPICreateSSOConnectionRequest, options?: RawAxiosRequestConfig) {
        return SSOAPIApiFp(this.configuration).sSOAPICreateSSOConnection(requestParameters.connection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete organization SSO connection.
     * @param {SSOAPIApiSSOAPIDeleteSSOConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOAPIApi
     */
    public sSOAPIDeleteSSOConnection(requestParameters: SSOAPIApiSSOAPIDeleteSSOConnectionRequest, options?: RawAxiosRequestConfig) {
        return SSOAPIApiFp(this.configuration).sSOAPIDeleteSSOConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get organization SSO connection.
     * @param {SSOAPIApiSSOAPIGetSSOConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOAPIApi
     */
    public sSOAPIGetSSOConnection(requestParameters: SSOAPIApiSSOAPIGetSSOConnectionRequest, options?: RawAxiosRequestConfig) {
        return SSOAPIApiFp(this.configuration).sSOAPIGetSSOConnection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List organization SSO connections.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOAPIApi
     */
    public sSOAPIListSSOConnections(options?: RawAxiosRequestConfig) {
        return SSOAPIApiFp(this.configuration).sSOAPIListSSOConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trusts a domain for SSO connection and returns the trusted connection provider name. Not visible in api documentation.
     * @param {SSOAPIApiSSOAPITrustSSOConnectionDomainRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOAPIApi
     */
    public sSOAPITrustSSOConnectionDomain(requestParameters: SSOAPIApiSSOAPITrustSSOConnectionDomainRequest, options?: RawAxiosRequestConfig) {
        return SSOAPIApiFp(this.configuration).sSOAPITrustSSOConnectionDomain(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization SSO connection.
     * @param {SSOAPIApiSSOAPIUpdateSSOConnectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SSOAPIApi
     */
    public sSOAPIUpdateSSOConnection(requestParameters: SSOAPIApiSSOAPIUpdateSSOConnectionRequest, options?: RawAxiosRequestConfig) {
        return SSOAPIApiFp(this.configuration).sSOAPIUpdateSSOConnection(requestParameters.id, requestParameters.connection, options).then((request) => request(this.axios, this.basePath));
    }
}

