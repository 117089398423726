/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CostreportV1beta1GetCostComparisonReportResponse } from '../models';
/**
 * PlatformImpactReportAPIApi - axios parameter creator
 * @export
 */
export const PlatformImpactReportAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets cost comparison report for two different periods. All clusters or selected.
         * @param {string} startTimeA Start of period A.
         * @param {string} startTimeB Start of period B.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty to include all.
         * @param {number} [rangeDays] Period range in days.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformImpactReportAPIGetCostComparisonReport: async (startTimeA: string, startTimeB: string, clusterIds?: Array<string>, rangeDays?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTimeA' is not null or undefined
            assertParamExists('platformImpactReportAPIGetCostComparisonReport', 'startTimeA', startTimeA)
            // verify required parameter 'startTimeB' is not null or undefined
            assertParamExists('platformImpactReportAPIGetCostComparisonReport', 'startTimeB', startTimeB)
            const localVarPath = `/v1/cost-reports/organization/cost-comparison`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (startTimeA !== undefined) {
                localVarQueryParameter['startTimeA'] = (startTimeA as any instanceof Date) ?
                    (startTimeA as any).toISOString() :
                    startTimeA;
            }

            if (startTimeB !== undefined) {
                localVarQueryParameter['startTimeB'] = (startTimeB as any instanceof Date) ?
                    (startTimeB as any).toISOString() :
                    startTimeB;
            }

            if (rangeDays !== undefined) {
                localVarQueryParameter['rangeDays'] = rangeDays;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlatformImpactReportAPIApi - functional programming interface
 * @export
 */
export const PlatformImpactReportAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlatformImpactReportAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets cost comparison report for two different periods. All clusters or selected.
         * @param {string} startTimeA Start of period A.
         * @param {string} startTimeB Start of period B.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty to include all.
         * @param {number} [rangeDays] Period range in days.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async platformImpactReportAPIGetCostComparisonReport(startTimeA: string, startTimeB: string, clusterIds?: Array<string>, rangeDays?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetCostComparisonReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.platformImpactReportAPIGetCostComparisonReport(startTimeA, startTimeB, clusterIds, rangeDays, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PlatformImpactReportAPIApi.platformImpactReportAPIGetCostComparisonReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PlatformImpactReportAPIApi - factory interface
 * @export
 */
export const PlatformImpactReportAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlatformImpactReportAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets cost comparison report for two different periods. All clusters or selected.
         * @param {PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        platformImpactReportAPIGetCostComparisonReport(requestParameters: PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetCostComparisonReportResponse> {
            return localVarFp.platformImpactReportAPIGetCostComparisonReport(requestParameters.startTimeA, requestParameters.startTimeB, requestParameters.clusterIds, requestParameters.rangeDays, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for platformImpactReportAPIGetCostComparisonReport operation in PlatformImpactReportAPIApi.
 * @export
 * @interface PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReportRequest
 */
export interface PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReportRequest {
    /**
     * Start of period A.
     * @type {string}
     * @memberof PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReport
     */
    readonly startTimeA: string

    /**
     * Start of period B.
     * @type {string}
     * @memberof PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReport
     */
    readonly startTimeB: string

    /**
     * Cluster IDs for filtering. Leave empty to include all.
     * @type {Array<string>}
     * @memberof PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReport
     */
    readonly clusterIds?: Array<string>

    /**
     * Period range in days.
     * @type {number}
     * @memberof PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReport
     */
    readonly rangeDays?: number
}

/**
 * PlatformImpactReportAPIApi - object-oriented interface
 * @export
 * @class PlatformImpactReportAPIApi
 * @extends {BaseAPI}
 */
export class PlatformImpactReportAPIApi extends BaseAPI {
    /**
     * 
     * @summary Gets cost comparison report for two different periods. All clusters or selected.
     * @param {PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlatformImpactReportAPIApi
     */
    public platformImpactReportAPIGetCostComparisonReport(requestParameters: PlatformImpactReportAPIApiPlatformImpactReportAPIGetCostComparisonReportRequest, options?: RawAxiosRequestConfig) {
        return PlatformImpactReportAPIApiFp(this.configuration).platformImpactReportAPIGetCostComparisonReport(requestParameters.startTimeA, requestParameters.startTimeB, requestParameters.clusterIds, requestParameters.rangeDays, options).then((request) => request(this.axios, this.basePath));
    }
}

