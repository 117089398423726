/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AioptimizerV1GetSettingsResponse } from '../models';
// @ts-ignore
import type { AioptimizerV1UpsertSettingsRequest } from '../models';
// @ts-ignore
import type { AioptimizerV1UpsertSettingsResponse } from '../models';
/**
 * AIEnablerSettingsAPIApi - axios parameter creator
 * @export
 */
export const AIEnablerSettingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
         * @param {string} [apiKey] The API key for which the settings are being fetched. If not provided, the settings are fetched for the current organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerSettingsAPIGetSettings: async (apiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/llm/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apiKey !== undefined) {
                localVarQueryParameter['apiKey'] = apiKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the settings of the LLM Optimizer.
         * @param {AioptimizerV1UpsertSettingsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerSettingsAPIUpsertSettings: async (body: AioptimizerV1UpsertSettingsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIEnablerSettingsAPIUpsertSettings', 'body', body)
            const localVarPath = `/v1/llm/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIEnablerSettingsAPIApi - functional programming interface
 * @export
 */
export const AIEnablerSettingsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIEnablerSettingsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
         * @param {string} [apiKey] The API key for which the settings are being fetched. If not provided, the settings are fetched for the current organization.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerSettingsAPIGetSettings(apiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1GetSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerSettingsAPIGetSettings(apiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerSettingsAPIApi.aIEnablerSettingsAPIGetSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the settings of the LLM Optimizer.
         * @param {AioptimizerV1UpsertSettingsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIEnablerSettingsAPIUpsertSettings(body: AioptimizerV1UpsertSettingsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1UpsertSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIEnablerSettingsAPIUpsertSettings(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIEnablerSettingsAPIApi.aIEnablerSettingsAPIUpsertSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIEnablerSettingsAPIApi - factory interface
 * @export
 */
export const AIEnablerSettingsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIEnablerSettingsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
         * @param {AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerSettingsAPIGetSettings(requestParameters: AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1GetSettingsResponse> {
            return localVarFp.aIEnablerSettingsAPIGetSettings(requestParameters.apiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the settings of the LLM Optimizer.
         * @param {AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIEnablerSettingsAPIUpsertSettings(requestParameters: AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettingsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1UpsertSettingsResponse> {
            return localVarFp.aIEnablerSettingsAPIUpsertSettings(requestParameters.body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIEnablerSettingsAPIGetSettings operation in AIEnablerSettingsAPIApi.
 * @export
 * @interface AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettingsRequest
 */
export interface AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettingsRequest {
    /**
     * The API key for which the settings are being fetched. If not provided, the settings are fetched for the current organization.
     * @type {string}
     * @memberof AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettings
     */
    readonly apiKey?: string
}

/**
 * Request parameters for aIEnablerSettingsAPIUpsertSettings operation in AIEnablerSettingsAPIApi.
 * @export
 * @interface AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettingsRequest
 */
export interface AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettingsRequest {
    /**
     * 
     * @type {AioptimizerV1UpsertSettingsRequest}
     * @memberof AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettings
     */
    readonly body: AioptimizerV1UpsertSettingsRequest
}

/**
 * AIEnablerSettingsAPIApi - object-oriented interface
 * @export
 * @class AIEnablerSettingsAPIApi
 * @extends {BaseAPI}
 */
export class AIEnablerSettingsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Returns the settings of the LLM Optimizer. If the apiKey query parameter is specified, fetches the settings for that apiKey. Otherwise, fetches the settings for the current organization. If there are no apiKey-specific settings, returns organization settings.
     * @param {AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerSettingsAPIApi
     */
    public aIEnablerSettingsAPIGetSettings(requestParameters: AIEnablerSettingsAPIApiAIEnablerSettingsAPIGetSettingsRequest = {}, options?: RawAxiosRequestConfig) {
        return AIEnablerSettingsAPIApiFp(this.configuration).aIEnablerSettingsAPIGetSettings(requestParameters.apiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the settings of the LLM Optimizer.
     * @param {AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIEnablerSettingsAPIApi
     */
    public aIEnablerSettingsAPIUpsertSettings(requestParameters: AIEnablerSettingsAPIApiAIEnablerSettingsAPIUpsertSettingsRequest, options?: RawAxiosRequestConfig) {
        return AIEnablerSettingsAPIApiFp(this.configuration).aIEnablerSettingsAPIUpsertSettings(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}

