import { Theme } from '@mui/material';
import dayjs from 'dayjs';
import { TZDate } from 'react-day-picker';

import { dayPickerStyles } from '../dayPickerStyles';

type IndexedMonth = {
  date: TZDate;
  index: number;
};

export const getScrollableMonths = (
  startDate: TZDate,
  endDate: TZDate,
  timezone: string
): IndexedMonth[] => {
  const result = [];

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  for (let year = startYear; year <= endYear; year++) {
    const monthStart = year === startYear ? startMonth : 0;
    const monthEnd = year === endYear ? endMonth : 11;

    for (let month = monthStart; month <= monthEnd; month++) {
      const monthDate = new TZDate(year, month, 1, timezone);
      result.push({
        date: monthDate,
        index: year * 12 + month,
      });
    }
  }

  return result;
};

export const blueTheme = (theme: Theme) =>
  dayPickerStyles({
    typography: theme.typography,
    text: theme.palette.grey[600]!,
    textDisabled: theme.palette.grey[300]!,
    label: theme.palette.grey[900]!,
    accentLight: theme.palette.blue[100]!,
    accentExtraLight: theme.palette.blue[50]!,
    accentContrast: theme.palette.blue[200]!,
    accent: theme.palette.blue[300]!,
    accentDark: theme.palette.blue[500]!,
    accentExtraDark: theme.palette.blue[700]!,
    background: theme.palette.grey[100]!,
    backgroundTrail: theme.palette.grey[100]!,
    activeBackground: theme.palette.blue[600]!,
  });

export const greenTheme = (theme: Theme) =>
  dayPickerStyles({
    typography: theme.typography,
    text: theme.palette.grey[600]!,
    textDisabled: theme.palette.grey[300]!,
    label: theme.palette.grey[900]!,
    accentLight: theme.palette.green[100]!,
    accentExtraLight: theme.palette.green[50]!,
    accentContrast: theme.palette.green[200]!,
    accent: theme.palette.green[300]!,
    accentDark: theme.palette.green[500]!,
    accentExtraDark: theme.palette.green[700]!,
    background: theme.palette.grey[100]!,
    backgroundTrail: theme.palette.grey[100]!,
    activeBackground: theme.palette.blue[600]!,
  });

export const getWeekDays = () => {
  return [...Array(7)].map((_, index) =>
    dayjs()
      .day(index + 1)
      .format('ddd')
  );
};

export const getMonthIndex = (date: TZDate, allMonths: IndexedMonth[]) => {
  const initialMonthNumber = date.getFullYear() * 12 + date.getMonth();

  return allMonths.findIndex((m) => m.index === initialMonthNumber);
};

export const validateDateInput = (dateString: string, allowTime: boolean) => {
  const datePattern = /^\d{4}\/\d{2}\/\d{2}$/;
  const dateTimePattern = /^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}$/;

  return allowTime
    ? dateTimePattern.test(dateString)
    : datePattern.test(dateString);
};
