import { DefaultError } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';

import { organizationQueryKey } from '@cast/constants';
import { SortingState } from '@cast/design-system';
import {
  AuditLogFilter,
  Cluster,
  DbCachingRule,
  Discount,
  LogicalDatabase,
  MutateWorkloadScalingPolicy,
  NodeConfigurationTemplate,
  NodeSelectionTemplate,
  OrganizationRole,
  PoliciesConfig,
  RebalancingSchedule,
  SSOConnection,
  WorkloadContainerConstraints,
  WorkloadScalingPolicy,
  WorkloadUpdatedResponse,
} from '@cast/types';
import { getQueryParam } from '@cast/utils';

import { ANALYTICS_ENABLED } from 'common/constants';
import { userId } from 'core/auth';
import { CostReportTab } from 'features/cost-report/types/common';
import { parseApiError } from 'utils/api';

import { checkForChanges, excludeEmail } from './utils';

export type ClusterAnalyticsEvent = keyof typeof clusterEvents;
export type ClusterAnalyticsEventArg<T extends ClusterAnalyticsEvent> =
  Parameters<(typeof clusterEvents)[T]>[1];

export type KarpenterAnalyticsEvent = keyof typeof karpenterEvents;

export type KarpenterEventOptions = {
  cluster: Cluster;
  organizationId: string;
  karpenterVersion: string;
  page?: string;
};

export type EventArgs = {
  cluster?: Cluster;
} & Record<string, any>;

export type SearchEventArgs = {
  freeText?: string;
  chips?: Record<string, any>;
};

const boolToStr = (val: boolean) => (val ? 'Yes' : 'No');

const raiseEvent = (eventName: string, args?: EventArgs) => {
  if (ANALYTICS_ENABLED) {
    const email: string = mixpanel.get_distinct_id();
    if (email && !excludeEmail(email)) {
      const organizationId = getQueryParam(organizationQueryKey);
      let postArgs: Record<string, any> = {
        'Page link': window.location.pathname.replace(
          /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/,
          'id'
        ),
        organizationId,
      };

      if (args) {
        const { cluster, ...rest } = args;
        if (cluster) {
          postArgs['Cluster name'] = cluster.name;
          postArgs.Managed = boolToStr(Boolean(cluster.credentialsId));
          postArgs.ClientId = userId();
        }

        postArgs = {
          ...postArgs,
          ...rest,
        };
      }
      mixpanel.track(eventName, postArgs);
    }
  }
};

export const woopEvents = {
  visitedWorkloadsOptimization: (
    cluster: Cluster,
    { isWoopAgentInstalled }: { isWoopAgentInstalled: boolean }
  ) =>
    raiseEvent('Visited workload optimization', {
      cluster,
      'WOOP agent installed': isWoopAgentInstalled,
    }),
  visitedWorkloadsOptimizationDetails: (
    cluster: Cluster,
    { workloadId }: { workloadId: string }
  ) =>
    raiseEvent('Visited workload optimization details', {
      cluster,
      'Workload ID': workloadId,
    }),
  editedWorkloadDetails: ({
    cluster,
    workload,
  }: {
    cluster: Cluster;
    workload: WorkloadUpdatedResponse;
  }) => {
    raiseEvent('Edited workload details', {
      cluster,
      workload,
    });
  },
  seenHpaFeatureBanner: (cluster: Cluster) =>
    raiseEvent('Seen HPA feature banner', {
      cluster,
    }),
  closedHpaFeatureBanner: (cluster: Cluster) =>
    raiseEvent('Closed HPA feature banner', {
      cluster,
    }),
  visitedWorkloadsOptimizationEvents: (
    cluster: Cluster,
    { isWoopAgentInstalled }: { isWoopAgentInstalled: boolean }
  ) =>
    raiseEvent('Visited events of workload optimization', {
      cluster,
      'WOOP agent installed': isWoopAgentInstalled,
    }),
  toggledWorkloadOptimization: (
    cluster: Cluster,
    {
      workload,
      namespace,
      workloadType,
      isOn,
    }: {
      workload: string;
      namespace: string;
      workloadType: string;
      isOn: boolean;
    }
  ) =>
    raiseEvent('Toggle workload optimization', {
      cluster,
      workload,
      namespace,
      workloadType,
      isOn: boolToStr(isOn),
    }),
  scalingPolicyAssigned: (
    cluster: Cluster,
    {
      error,
      ...args
    }: {
      place: string;
      workloadIds: string[];
      scalingPolicyId: string;
      error?: unknown;
    }
  ) => {
    raiseEvent('Scaling policy assigned', {
      cluster,
      ...args,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    });
  },
  scalingPolicyCreated: (
    cluster: Cluster,
    {
      policy,
      error,
    }: {
      policy?: WorkloadScalingPolicy | MutateWorkloadScalingPolicy;
      error?: unknown;
    }
  ) => {
    raiseEvent('Scaling policy created', {
      cluster,
      id: (policy as WorkloadScalingPolicy)?.id,
      autoscalerMode: policy?.applyType,
      rawPolicy: policy,
      success: !error,
      error: parseApiError(error),
    });
  },
  scalingPolicyEdited: (
    cluster: Cluster,
    {
      policy,
      error,
    }: {
      policy?: WorkloadScalingPolicy | MutateWorkloadScalingPolicy;
      error?: unknown;
    }
  ) => {
    raiseEvent('Scaling policy edited', {
      cluster,
      id: (policy as WorkloadScalingPolicy)?.id,
      autoscalerMode: policy?.applyType,
      rawPolicy: policy,
      success: !error,
      error: parseApiError(error),
    });
  },
  scalingPolicyDeleted: (
    cluster: Cluster,
    {
      policy,
      error,
    }: {
      policy?: WorkloadScalingPolicy;
      error?: unknown;
    }
  ) => {
    raiseEvent('Scaling policy deleted', {
      cluster,
      id: (policy as WorkloadScalingPolicy)?.id,
      rawPolicy: policy,
      success: !error,
      error: parseApiError(error),
    });
  },
  leftWithoutSaving: (cluster: Cluster, { place }: { place: string }) => {
    raiseEvent('Left without saving', {
      cluster,
      place,
    });
  },
  containerConstraintsUpdated: ({
    cluster,
    place,
    organizationId,
    containerConstraints,
    error,
  }: {
    cluster: Cluster;
    place: string;
    organizationId?: string;
    containerConstraints?: WorkloadContainerConstraints[];
    error?: unknown;
  }) => {
    raiseEvent('Container constraints updated', {
      cluster,
      place,
      organizationId,
      containerConstraints,
      success: !error,
      error: parseApiError(error),
    });
  },
};

export const dboEvents = {
  visitedInstancesPage: () => raiseEvent('DBO: Visited instances page'),
  toggledDemoVisibility: () => raiseEvent('DBO: Toggled demo visibility'),
  visitedInstanceDetailsPage: ({ instanceId }: { instanceId: string }) =>
    raiseEvent('DBO: Visited instance details page', { instanceId }),
  deployCacheDialogSeen: ({ place, slide }: { place: string; slide: string }) =>
    raiseEvent('DBO: Cache deployment dialog', { place, slide }),
  instanceOnboardingDialog: ({
    place,
    slide,
  }: {
    place: string;
    slide: string;
  }) => raiseEvent('DBO: Instance onboarding dialog', { place, slide }),
  visitedQuerySelectionPage: () =>
    raiseEvent('DBO: Visited query selection page'),
  openedRuleDrawer: () => raiseEvent('DBO: Opened rule drawer'),
  createdRule: (args: {
    place: string;
    rule?: DbCachingRule;
    error?: DefaultError;
  }) => raiseEvent('DBO: Created rule', { ...args, success: !args.error }),
  updatedRule: (args: {
    place: string;
    rule?: DbCachingRule;
    error?: DefaultError;
  }) => raiseEvent('DBO: Updated rule', { ...args, success: !args.error }),
  openedQueryDrawer: () => raiseEvent('DBO: Opened query drawer'),
  openedCacheSettingsDrawer: ({
    place,
    database,
  }: {
    place: string;
    database: LogicalDatabase;
  }) => raiseEvent('DBO: Opened cache settings drawer', { place, database }),
  changedCacheSettings: ({
    place,
    database,
    updatedConfig,
    error,
  }: {
    place: string;
    database?: LogicalDatabase;
    cluster?: Cluster;
    updatedConfig?: any;
    error?: unknown;
  }) =>
    raiseEvent('DBO: Changed cache settings', {
      place,
      database,
      updatedConfig,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  joinedWaitingList: () => raiseEvent('DBO: Joined waiting list'),
  bookedDemo: () => raiseEvent('DBO: Booked a live demo'),
  openedSurveyDialog: (place: string) =>
    raiseEvent('DBO: Opened survey dialog', { place }),
  submittedSurveyDialog: (answers: Record<string, string>) =>
    raiseEvent('DBO: Submitted survey dialog', answers),
};

export const clusterEvents = {
  startSavingNow: (cluster: Cluster) =>
    raiseEvent('Started onboarding cluster to phase 2', { cluster }),
  visitedDashboard: (cluster: Cluster) =>
    raiseEvent('Visited dashboard', { cluster }),
  visitedClusterCost: (cluster: Cluster) =>
    raiseEvent('Visited cluster cost monitoring', { cluster }),
  visitedWorkloadsCost: (cluster: Cluster) =>
    raiseEvent('Visited workload cost monitoring', { cluster }),
  visitedNamespacesCost: (cluster: Cluster) =>
    raiseEvent('Visited namespace cost monitoring', { cluster }),

  visitedAvailableSavings: (
    cluster: Cluster,
    {
      currentPrice,
      spotFriendlyWorkloadsSavings,
      allWorkloadsSavings,
    }: {
      currentPrice: number;
      spotFriendlyWorkloadsSavings: number;
      allWorkloadsSavings: number;
    }
  ) =>
    raiseEvent('Visited available savings', {
      cluster,
      'Monthly cluster price before optimization': currentPrice,
      'Available monthly savings for spot-friendly workloads':
        spotFriendlyWorkloadsSavings,
      'Available monthly savings for all workloads': allWorkloadsSavings,
    }),
  visitedNodeList: (cluster: Cluster, numberOfNodes: number) =>
    raiseEvent('Visited node list', {
      cluster,
      'Number of nodes': numberOfNodes,
    }),
  visitedCostComparison: (cluster: Cluster) =>
    raiseEvent('Visited cost comparison page', {
      cluster,
    }),
  visitedRebalance: (cluster: Cluster) =>
    raiseEvent('Visited rebalance page', { cluster }),
  visitedGeneratedRebalancePlan: (cluster: Cluster) =>
    raiseEvent('Generated rebalance plan', { cluster }),
  visitedAutoscaler: (cluster: Cluster) =>
    raiseEvent('Visited autoscaler page', { cluster }),
  visitedAutoscalerSettings: (cluster: Cluster) =>
    raiseEvent('Visited autoscaler settings', { cluster }),
  visitedAutoscalerNodeConfiguration: (cluster: Cluster) =>
    raiseEvent('Visited autoscaler node configuration', { cluster }),
  createAutoscalerNodeConfigurationSucceeded: (
    cluster: Cluster,
    configuration: NodeConfigurationTemplate
  ) =>
    raiseEvent('Create autoscaler node configuration succeeded', {
      cluster,
      configuration: checkForChanges({}, configuration),
    }),
  createAutoscalerNodeConfigurationFailed: (cluster: Cluster, error: string) =>
    raiseEvent('Create autoscaler node configuration failed', {
      cluster,
      error,
    }),
  updateAutoscalerNodeConfigurationSucceeded: (
    cluster: Cluster,
    {
      configuration,
      newConfiguration,
    }: {
      configuration: NodeConfigurationTemplate;
      newConfiguration: NodeConfigurationTemplate;
    }
  ) =>
    raiseEvent('Update autoscaler node configuration succeeded', {
      cluster,
      configuration: checkForChanges(configuration, newConfiguration),
    }),
  updateAutoscalerNodeConfigurationFailed: (cluster: Cluster, error: string) =>
    raiseEvent('Update autoscaler node configuration failed', {
      cluster,
      error,
    }),
  visitedAutoscalerNodeTemplates: (cluster: Cluster) =>
    raiseEvent('Visited autoscaler node templates', { cluster }),
  advancedEvictorSaved: (cluster: Cluster, organizationId: string) =>
    raiseEvent('Saved advanced evictor configuration', {
      cluster,
      organizationId,
    }),
  visitedAuditLog: (cluster: Cluster) =>
    raiseEvent('Visited audit log page', { cluster }),
  toggledUseSpotInstances: (cluster: Cluster, isOn: boolean) =>
    raiseEvent('Toggled Use spot instances', {
      cluster,
      isOn: boolToStr(isOn),
    }),
  toggledWorkloadRightsizing: (cluster: Cluster, isOn: boolean) =>
    raiseEvent('Toggled Workload rightsizing', {
      cluster,
      isOn: boolToStr(isOn),
    }),
  toggledRecommendedArm: (cluster: Cluster, isOn: boolean) =>
    raiseEvent('Toggled Recommend ARM', {
      cluster,
      isOn: boolToStr(isOn),
    }),
  toggledUseClusterSchedule: (cluster: Cluster, isOn: boolean) =>
    raiseEvent('Toggled Use cluster schedule', {
      cluster,
      isOn: boolToStr(isOn),
    }),
  clickedBookTechSession: (cluster: Cluster, title: string) =>
    raiseEvent(`Pressed Book a Tech session - ${title}`, {
      cluster,
    }),
  openedAllocationGroupWorkloads: () => {
    raiseEvent('User opened allocation group workloads');
  },
};

export const costReportEvents = {
  visitedWorkloadsCostDetails: (
    workloadCost: number,
    workloadName: string,
    controllerName: string,
    replicaCount: number,
    cpuCount: number
  ) => {
    raiseEvent('Visited workload cost over time', {
      'Workload cost': workloadCost,
      'Workload name': workloadName,
      'Controller name': controllerName,
      'Replica count': replicaCount,
      'CPU count': cpuCount,
    });
  },
  createdAllocationGroup: () => {
    raiseEvent('Created allocation group');
  },
  visitedAllocationGroupComputeCost: (cluster: Cluster) => {
    raiseEvent('Visited allocation group compute cost report', {
      cluster,
    });
  },
  visitedNetworkCostPage: (
    report: string,
    cluster: Cluster,
    networkDataAvailable: boolean
  ) => {
    raiseEvent(`Visited ${report} network cost report`, {
      cluster,
      'network data available': networkDataAvailable,
    });
  },
  visitedEfficiencyPage: (report: string, cluster: Cluster) => {
    raiseEvent(`Visited ${report} efficiency report`, {
      cluster,
    });
  },
  visitedGpuUtilizationPage: (
    report: string,
    cluster: Cluster,
    isGpuExporterInstalled: boolean
  ) => {
    raiseEvent(`Visited ${report} gpu utilization report`, {
      cluster,
      'GPU exporter installed': isGpuExporterInstalled,
    });
  },
  clickedLabelsFilter: (
    report: 'workloads' | 'namespaces',
    filter: 'labels' | 'namespaces',
    tab: CostReportTab
  ) => raiseEvent(`User clicks ${filter} filtering`, { report, tab }),
  selectedLabelsFilter: (
    report: 'workloads' | 'network-cost',
    filter: 'labels' | 'namespaces',
    tab: CostReportTab
  ) => raiseEvent(`User applies ${filter} filtering`, { report, tab }),
};

export const analyticsEvents = {
  errorWhenSignup: (error: string[]) =>
    raiseEvent('Error when signing up', { 'error field': error }),
  signupFormVisited: (step?: number) =>
    step !== undefined
      ? raiseEvent('Signup form viewed (B)', { step })
      : raiseEvent('Signup form viewed (A)'),
  signupCompleted: () => raiseEvent('Signup completed'),
  startedClusterConnection: ({
    firstCluster,
    securityFlow = false,
  }: {
    firstCluster: boolean;
    securityFlow?: boolean;
  }) =>
    raiseEvent('Started cluster connection', {
      'First cluster?': boolToStr(firstCluster),
      'Security flow?': boolToStr(securityFlow),
    }),
  viewedAllClusters: (numberOfClusters: number) =>
    raiseEvent('Viewed cluster list', {
      'Number of clusters connected': numberOfClusters,
    }),
  visitedHelpPage: (linkTitle: string) =>
    raiseEvent('Clicked on page link on cluster connection page', {
      'Page link': linkTitle,
    }),
  visitedPricingPlans: () => raiseEvent('Visited pricing plans'),
  visitedSubscriptionManagment: () =>
    raiseEvent('Visited subscription management'),
  tableSortedEvent: (tableName: string, sortingState?: SortingState) =>
    raiseEvent('Table sorted', {
      Table: tableName,
      'Sort by': sortingState?.columnId || '--',
      'Sort direction': sortingState
        ? sortingState.sortBy?.direction === 'asc'
          ? 'Ascending'
          : 'Descending'
        : '--',
    }),
  clickBookDemo: () => raiseEvent('Clicked book a demo'),
  clickOnHeader: (link: string, currentPageURL: string) =>
    raiseEvent('Clicked on header link', {
      Link: link,
      'Current page URL': currentPageURL,
    }),
  reportDownloaded: (filetype: string, reportType: string) =>
    raiseEvent('Report downloaded', {
      'Export file type': filetype,
      'Report type': reportType,
    }),
  ranThescript: (clusterProvider: string) =>
    raiseEvent('Cluster connection “I ran the script”', {
      'Cluster provider': clusterProvider,
    }),
  memberInvited: (
    inviteList: {
      userEmail: string;
      role?: OrganizationRole;
      roleId?: string;
    }[]
  ) => raiseEvent('New member invited', { inviteList }),
  playedVideo: (title?: string) =>
    raiseEvent(
      'Played video in the cluster connection screen',
      title
        ? {
            'Video title': title,
          }
        : undefined
    ),
  registerClusterDialogInProgressClosed: (clusterProvider: string) =>
    raiseEvent('User click "back" on cluster connection loading', {
      'Cluster provider': clusterProvider,
    }),
  registerClusterDialogSucceeded: (clusterProvider: string) =>
    raiseEvent('Successful cluster connection', {
      'Cluster provider': clusterProvider,
    }),
  initiateTourHasBeenOpened: () => raiseEvent('Click on pulsing tour icon'),
  tourHasBeenStarted: () => raiseEvent('Tour started'),
  searchHasBeenUsed: ({
    analyticsId,
    key,
    value,
  }: {
    analyticsId: string;
    key: string;
    value: any;
  }) => raiseEvent(`Search has been used in ${analyticsId}.`, { key, value }),
  showedInterestIn: ({
    feature,
    context,
    url,
  }: {
    feature: string;
    context?: string;
    url?: string;
  }) =>
    raiseEvent(`Showed interest in ${feature}`, {
      feature,
      context,
      url,
    }),
  ...clusterEvents,
};

export const notificationsEvents = {
  visitedNotifications: () => raiseEvent('Visited Notifications Page'),
  resolvedNotification: ({
    name,
    severity,
  }: {
    name?: string;
    severity?: string;
  }) =>
    raiseEvent('Resolved notifications', {
      'Notification name': name,
      'Notification severity': severity,
    }),
  viewedCriticalNotifications: (organizationName: string) =>
    raiseEvent('User viewed critical notifications', { organizationName }),
  clickedCriticalNotification: (
    organizationName: string,
    notificationName: string
  ) =>
    raiseEvent('User clicked on critical notification', {
      organizationName,
      notificationName,
    }),
  dismissedCriticalNotification: (
    organizationName: string,
    notificationName: string
  ) =>
    raiseEvent('User dismissed a critical notification', {
      organizationName,
      notificationName,
    }),
};

export const webhooksEvents = {
  visitedSetupPage: () => raiseEvent('Visited Webhook setup page'),
  addedWebhook: (url: string) =>
    raiseEvent('Completed notification webhook setup', {
      'callback Url': url,
    }),
};

export const scheduledRebalancingEvents = {
  visitedOrgLevelScheduledRebalancing: (organizationId: string) =>
    raiseEvent('Visited org level scheduled rebalancing', { organizationId }),
  createdSchedule: ({
    place,
    schedule,
    cluster,
    error,
  }: {
    place: string;
    schedule?: RebalancingSchedule;
    cluster?: Cluster;
    error?: unknown;
  }) =>
    raiseEvent('Created schedule', {
      place,
      schedule,
      cluster,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  editedSchedule: ({
    place,
    schedule,
    error,
  }: {
    place: string;
    schedule?: RebalancingSchedule;
    error?: unknown;
  }) =>
    raiseEvent('Saved schedule', {
      place,
      schedule,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  unassignedSchedule: ({
    place,
    organizationId,
    cluster,
    schedule,
    error,
  }: {
    place: string;
    organizationId?: string;
    cluster?: Cluster;
    schedule?: RebalancingSchedule;
    error?: unknown;
  }) =>
    raiseEvent('Unassigned schedule', {
      place,
      organizationId,
      cluster,
      schedule,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  deletedSchedule: ({
    place,
    organizationId,
    schedule,
    error,
  }: {
    place: string;
    organizationId?: string;
    schedule?: RebalancingSchedule;
    error?: unknown;
  }) =>
    raiseEvent('Deleted schedule', {
      place,
      organizationId,
      schedule,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
};

export const orgManagementEvents = {
  visitedBillingReport: (organizationId: string, fromSidebar: boolean) =>
    raiseEvent('Visited billing report', {
      organizationId,
      fromSidebar,
    }),
  viewedBillingMovedAlert: () => raiseEvent('User saw billing has moved alert'),
  dismissBillingMovedAlert: () =>
    raiseEvent('User has dismissed billing moved alert'),
  ctaClickedBillingMovedAlert: () =>
    raiseEvent('User has clicked CTA on billing moved alert'),
  viewedCastwareSurveyAlert: (
    organizationId: string,
    organizationName: string
  ) =>
    raiseEvent('User saw CASTware survey alert', {
      organizationId,
      organizationName,
    }),
  dismissCastwareSurveyAlert: (
    organizationId: string,
    organizationName: string
  ) =>
    raiseEvent('User has dismissed CASTware survey alert', {
      organizationId,
      organizationName,
    }),
  ctaClickedCastwareSurveyAlert: (
    organizationId: string,
    organizationName: string
  ) =>
    raiseEvent('User has opened the CASTware survey', {
      organizationId,
      organizationName,
    }),
  switchedOrganizations: (organizationFrom: string, organizationTo: string) =>
    raiseEvent('User has switched from one organization to another', {
      organizationFrom,
      organizationTo,
    }),
  createNewOrganisation: ({
    currentOrganisationName,
    newOrganisationId,
    newOrganizationName,
    userId,
  }: {
    currentOrganisationName?: string;
    currentOrganisationId?: string;
    newOrganisationId: string;
    newOrganizationName: string;
    userId: string;
  }) => {
    raiseEvent('New organisation is created', {
      currentOrganisationName,
      newOrganisationId,
      newOrganizationName,
      userId,
    });
  },
};

export const karpenterEvents = {
  beginImportClicked: ({
    cluster,
    organizationId,
    karpenterVersion,
    page,
  }: KarpenterEventOptions) =>
    raiseEvent(`Clicked Begin import in ${page}`, {
      cluster,
      organizationId,
      'Karpenter version': karpenterVersion,
    }),

  visitedImportSuccessPage: ({
    cluster,
    organizationId,
    karpenterVersion,
  }: KarpenterEventOptions) =>
    raiseEvent('Viewed Imported successfully screen', {
      cluster,
      organizationId,
      'Karpenter version': karpenterVersion,
    }),
  visitedPartialImportPage: ({
    cluster,
    organizationId,
    karpenterVersion,
  }: KarpenterEventOptions) =>
    raiseEvent('Viewed Import partially completed screen', {
      cluster,
      organizationId,
      'Karpenter version': karpenterVersion,
    }),
  visitedImportFailedPage: ({
    cluster,
    organizationId,
    karpenterVersion,
  }: KarpenterEventOptions) =>
    raiseEvent('Viewed Import failed to import screen', {
      cluster,
      organizationId,
      'Karpenter version': karpenterVersion,
    }),
  startImportClicked: ({
    cluster,
    organizationId,
    karpenterVersion,
  }: KarpenterEventOptions) =>
    raiseEvent('Clicked Import in Import Karpenter objects screen', {
      cluster,
      organizationId,
      'Karpenter version': karpenterVersion,
    }),
  closeImportClicked: ({
    cluster,
    organizationId,
    karpenterVersion,
  }: KarpenterEventOptions) =>
    raiseEvent('Clicked Close in Import Karpenter objects screen', {
      cluster,
      organizationId,
      'Karpenter version': karpenterVersion,
    }),
};

export const orgCostMonitoringEvents = {
  visitedClustersReport: () => {
    raiseEvent('Visited organization clusters compute cost report');
  },
  createdAllocationGroup: () => {
    raiseEvent('User created organisation allocation group (compute cost)');
  },
  visitedAllocationGroupComputeCost: () => {
    raiseEvent('User visits organization allocations report (compute cost)');
  },
  openedAllocationGroupWorkloads: () => {
    raiseEvent('User opened organization allocation group workloads');
  },
};

export const orgSecurityEvents = {
  complianceVisited: () => raiseEvent('Visited org security compliance page'),
  complianceTextFilterUsed: ({ freeText, chips }: SearchEventArgs) =>
    raiseEvent('Org security compliance page text filter used', {
      freeText,
      chips,
    }),
  complianceFiltersUsed: (name: string) =>
    raiseEvent('Org security compliance page filters used', { name }),
  complianceCheckDetailsDrawerOpened: (ruleId: string) =>
    raiseEvent('Org security compliance page check details drawer opened', {
      ruleId,
    }),
  complianceCheckExceptionDrawerOpened: (ruleId: string) =>
    raiseEvent('Org security compliance page check exception drawer opened', {
      ruleId,
    }),
  complianceCheckExceptionApplied: (ruleId: string) =>
    raiseEvent('Org security compliance page check exception applied', {
      ruleId,
    }),
  complianceCsvExportUsed: () =>
    raiseEvent('Org security compliance page CSV export used'),
  vulnerabilityManagementVisited: () =>
    raiseEvent('Visited org security vulnerability management page'),
  vulnerabilityManagementTextFilterUsed: ({
    freeText,
    chips,
  }: SearchEventArgs) =>
    raiseEvent('Org security vulnerability management text filter used', {
      freeText,
      chips,
    }),
  vulnerabilityManagementFiltersUsed: (name: string) =>
    raiseEvent('Org security vulnerability management filters used', { name }),
  vulnerabilityManagementRepositoryTabChanged: (tabName: string) =>
    raiseEvent('Org security vulnerability management repository tab changed', {
      tabName,
    }),
  vulnerabilityManagementRepositoryExceptionCreated: () =>
    raiseEvent(
      'Org security vulnerability management repository exception created'
    ),
  vulnerabilityManagementDetailsPageVisited: (tagId: string) =>
    raiseEvent('Org security vulnerability management details page visited', {
      tagId,
    }),
  vulnerabilityManagementDetailsVulnerabilitiesTabTextFilterUsed: ({
    freeText,
    chips,
  }: SearchEventArgs) =>
    raiseEvent(
      'Org security vulnerability management details vulnerabilities tab text filter used',
      {
        freeText,
        chips,
      }
    ),
  vulnerabilityManagementDetailsVulnerabilitiesTabFiltersUsed: (name: string) =>
    raiseEvent(
      'Org security vulnerability management details vulnerabilities tab filters used',
      { name }
    ),
  vulnerabilityManagementDetailsPackagesTabTextFilterUsed: ({
    freeText,
    chips,
  }: SearchEventArgs) =>
    raiseEvent(
      'Org security vulnerability management details packages tab text filter used',
      {
        freeText,
        chips,
      }
    ),
  vulnerabilityManagementDetailsPackagesTabFiltersUsed: (name: string) =>
    raiseEvent(
      'Org security vulnerability management details packages tab filters used',
      { name }
    ),
  vulnerabilityManagementDetailsDigestChanged: (digest: string) =>
    raiseEvent('Org security vulnerability management details digest changed', {
      digest,
    }),
  vulnerabilityManagementDetailsVulnerabilitiesTabClicked: (tagId: string) =>
    raiseEvent(
      'Org security vulnerability management details vulnerabilities tab clicked',
      { tagId }
    ),
  vulnerabilityManagementDetailsPackagesTabClicked: () =>
    raiseEvent(
      'Org security vulnerability management details packages tab clicked'
    ),
  vulnerabilityManagementDetailsBaseImageRecommendationTabClicked: () =>
    raiseEvent(
      'Org security vulnerability management details base image recommendation tab clicked'
    ),
  vulnerabilityManagementDetailsAffectedResourcesTabClicked: (tagId: string) =>
    raiseEvent(
      'Org security vulnerability management details affected resources tab clicked',
      { tagId }
    ),
  vulnerabilityManagementDetailsAffectedResourcesTabTextFilterUsed: ({
    freeText,
    chips,
  }: SearchEventArgs) =>
    raiseEvent(
      'Org security vulnerability management details affected resources tab text filter used',
      {
        freeText,
        chips,
      }
    ),
  vulnerabilityManagementDetailsAffectedResourcesTabFiltersUsed: (
    name: string
  ) =>
    raiseEvent(
      'Org security vulnerability management details affected resources tab filters used',
      { name }
    ),
  vulnerabilityManagementDetailsLayerSelected: () =>
    raiseEvent('Org security vulnerability management details layer selected'),
  vulnerabilityManagementDetailsImageHierarchyImageSelected: () =>
    raiseEvent(
      'Org security vulnerability management details image hierarchy image selected'
    ),
  vulnerabilityManagementVulnerabilitiesExceptionsCreated: () =>
    raiseEvent(
      'Org security vulnerability management vulnerabilities exception created'
    ),
  vulnerabilityManagementVulnerabilitiesExceptionsCancelled: () =>
    raiseEvent(
      'Org security vulnerability management vulnerabilities exception cancelled'
    ),
  vulnerabilityManagementVulnerabilityDetailsDrawerOpened: () =>
    raiseEvent(
      'Org security vulnerability management vulnerability details drawer opened'
    ),
  vulnerabilityManagementPackagesExceptionsCreated: () =>
    raiseEvent(
      'Org security vulnerability management packages exception created'
    ),
  vulnerabilityManagementPackagesExceptionsCancelled: () =>
    raiseEvent(
      'Org security vulnerability management packages exception cancelled'
    ),
  vulnerabilityManagementPackageDetailsDrawerOpened: () =>
    raiseEvent(
      'Org security vulnerability management package details drawer opened'
    ),
  enableCastAiSecurityHeaderClicked: () =>
    raiseEvent('Org security enable Cast AI security header clicked'),

  settingsPageVisited: () => raiseEvent('Visited org security settings page'),
  settingsControlsClickedEnable: () =>
    raiseEvent('Org security settings agent & controls clicked Enable'),
  settingsControlsClickedUpdate: () =>
    raiseEvent('Org security settings agent & controls clicked Update'),
  settingsControlsOpenedControlsDrawer: () =>
    raiseEvent('Org security settings agent & controls opened controls drawer'),
  settingsControlsToggledFeatures: (features: Record<string, boolean>) =>
    raiseEvent('Org security settings agent & controls toggled features', {
      features,
    }),
  settingsIntegrationsOpenedManageDialog: (mode: 'create' | 'edit') =>
    raiseEvent('Org security settings integrations opened manage dialog', {
      mode,
    }),
  settingsIntegrationsCreateJira: () =>
    raiseEvent('Org security settings integrations create Jira'),
  settingsIntegrationsUpdateJira: () =>
    raiseEvent('Org security settings integrations update Jira'),
  settingsIntegrationsOpenedDeleteDialog: () =>
    raiseEvent('Org security settings integrations opened delete dialog'),
  settingsIntegrationsDeleteJira: () =>
    raiseEvent('Org security settings integrations delete Jira'),

  dashboardPageVisited: () => raiseEvent('Visited org security dashboard page'),
  dashboardReportOpened: (reportName: string) =>
    raiseEvent('Opened org security dashboard report', { reportName }),
  dashboardImageRepositoryClicked: () =>
    raiseEvent('Org security dashboard image repository clicked'),

  nodeUpdatesPageVisited: () => raiseEvent('Visited node updates page'),
  nodeUpdatesSchedulesDrawerOpened: () =>
    raiseEvent('Node updates schedules drawer opened'),
  createNodeUpdateScheduleDrawerOpened: () =>
    raiseEvent('Create node update schedule drawer opened'),

  workloadCompliancePageVisited: () =>
    raiseEvent('Org security workload compliance page page visited'),
  workloadVulnerabilityManagementPageVisited: () =>
    raiseEvent('Org security workload vulnerability management page visited'),
  workloadRuntimePageVisited: () =>
    raiseEvent('Org security workload runtime page visited'),
  workloadAttackPathPageVisited: () =>
    raiseEvent('Org security workload attack path page visited'),

  attackPathsPageVisited: () => raiseEvent('Visited org security attack paths'),
  attackPathDetailsPageVisited: () =>
    raiseEvent('Visited org security attack path details'),
  attackPathDetailsClickedOnResource: (resourceType: string) =>
    raiseEvent('Clicked on resource in attack path details', {
      'Resource type': resourceType,
    }),
  attackPathDetailsOpenedCheckDetailsSubDrawer: () =>
    raiseEvent(
      'Opened check details sub-drawer from resource drawer in attack path details'
    ),
  attackPathDetailsOpenedVulnerabilityDetailsSubDrawer: () =>
    raiseEvent(
      'Opened image details sub-drawer from resource drawer in attack path details'
    ),

  runtimeAnomaliesTabVisited: () =>
    raiseEvent('Visited org security runtime anomalies tab'),
  runtimeAnomaliesAcknowledgedAnomalies: (count: number) =>
    raiseEvent('Acknowledged org security runtime anomalies', { Count: count }),
  runtimeAnomaliesClosedAnomalies: (count: number) =>
    raiseEvent('Closed org security runtime anomalies', { Count: count }),
  runtimeAnomalyDetailsPageVisited: () =>
    raiseEvent('Visited org security runtime anomaly details page'),
  runtimeAnomalyDetailsEventDrawerOpened: () =>
    raiseEvent('Opened event drawer in org security runtime anomaly details'),
  runtimeAnomalyDetailsRuleDrawerOpened: () =>
    raiseEvent('Opened rule drawer in org security runtime anomaly details'),
  runtimeRulesTabVisited: () =>
    raiseEvent('Visited org security runtime rules tab'),
  runtimeRulesToggledRules: (count: number, enabled: boolean) =>
    raiseEvent('Toggled org security runtime rules', {
      Count: count,
      Enabled: enabled,
    }),
  runtimeRulesOpenedRuleDetailsDrawer: () =>
    raiseEvent('Opened rule details drawer in org security runtime rules'),
  runtimeRulesOpenedAnomaliesInRuleDrawer: () =>
    raiseEvent(
      'Opened anomalies in rule details drawer in org security runtime rules'
    ),
  runtimeEventsTabVisited: () =>
    raiseEvent('Visited org security runtime events tab'),
  runtimeOpenedCreateRuleDrawer: () =>
    raiseEvent('Opened create rule drawer in org security runtime'),
  runtimeCreatedRule: () => raiseEvent('Created runtime security rule'),
  runtimeUpdatedRule: () => raiseEvent('Updated runtime security rule'),
  runtimeDeletedRule: () => raiseEvent('Deleted runtime security rule'),

  openedWelcomeDialogDemoVideo: () =>
    raiseEvent('Org security opened welcome dialog demo video'),
  jiraTicketCreated: (itemType: string) =>
    raiseEvent('Org security Jira ticket created', { 'Item type': itemType }),
};

export const llmOptimizationEvents = {
  visitedGenerativeSavingsReport: ({
    potentialSavings,
    potentialSavingsPercentage,
    achievedSavings,
    achievedSavingsPercentage,
    totalCost,
    pricePerMilTokens,
    orgName,
  }: {
    potentialSavings: number;
    potentialSavingsPercentage: number;
    achievedSavings: number;
    achievedSavingsPercentage: number;
    totalCost: number;
    pricePerMilTokens: number;
    orgName: string;
  }) =>
    raiseEvent('Visited generative savings report', {
      potentialSavings,
      potentialSavingsPercentage,
      achievedSavings,
      achievedSavingsPercentage,
      totalCost,
      pricePerMilTokens,
      orgName,
    }),
  openedGenerativeSavingsPerCategoryReport: () =>
    raiseEvent('Opened generative savings per category report'),
  visitedGenerativeSavingsPerApiKeyReport: () =>
    raiseEvent('Visited generative savings per API key report'),
  joinedGenerativeSavingsWaitingList: () =>
    raiseEvent('Joined generative savings waiting list'),
  runGenerativeSavingsTestRun: () =>
    raiseEvent('Run generative savings test run'),
  runGenerativeSavingsTestRunSucceeded: () =>
    raiseEvent('Run generative savings test run succeeded'),
  runGenerativeSavingsTestRunFailed: (error: string) =>
    raiseEvent('Run generative savings test run failed', { error }),
  visitedGenerativeWelcomeScreen: () =>
    raiseEvent('Visited generative welcome screen'),
  clickedGenerativeWelcomeScreenSetup: () =>
    raiseEvent('Clicked generative welcome screen setup'),
  visitedGenerativeEmptyState: () =>
    raiseEvent('Visited generative empty state'),
  addNewApiKey: () => raiseEvent('Add new API key'),
  addExistingApiKey: () => raiseEvent('Add existing API key'),

  visitedProvidersPage: () => raiseEvent('Visited providers page'),
  registeredProviderButtonClicked: (inPage: string) =>
    raiseEvent('Registered provider button clicked', { inPage }),
  registeredProviderSuccess: ({
    providerName,
    models,
    freeCredits,
  }: {
    providerName: string;
    models: string[];
    freeCredits: number;
  }) =>
    raiseEvent('Registered provider successfully', {
      providerName,
      models,
      freeCredits,
    }),
  registerAnotherProviderButtonClicked: () =>
    raiseEvent('Register another provider button clicked'),
  viewedSettingsOnOnboarding: () => raiseEvent('Viewed settings on onboarding'),
  savedSettingsOnOnboarding: ({
    promptSharingEnabled,
    routingEnabled,
  }: {
    promptSharingEnabled: boolean;
    routingEnabled: boolean;
  }) =>
    raiseEvent('Saved onboarding settings', {
      promptSharingEnabled,
      routingEnabled,
    }),

  visitedPlaygroundPage: () => raiseEvent('Visited playground page'),
  playgroundToggleSystemPrompt: () =>
    raiseEvent('Playground toggle system prompt'),
  playgroundConfigurationsDrawerOpened: () =>
    raiseEvent('Playground configurations drawer opened'),
  playgroundConfigurationsChanged: (configuration: Record<string, any>) =>
    raiseEvent('Playground configurations changed', { configuration }),
  playgroundPromptSent: ({
    routerProviders,
    proxyProvider,
    systemPrompt,
    prompt,
  }: {
    routerProviders: Array<{
      provider: string;
      model: string;
    }>;
    proxyProvider?: {
      provider: string;
      model: string;
    };
    systemPrompt?: string;
    prompt?: string;
  }) =>
    raiseEvent('Playground prompt sent', {
      routerProviders,
      proxyProvider,
      systemPrompt,
      prompt,
    }),
  playgroundCastRouterAPIsLinkClicked: () =>
    raiseEvent('Playground CAST Router APIs link clicked'),
  playgroundCastRouterDocumentationLinkClicked: () =>
    raiseEvent('Playground CAST Router documentation link clicked'),
  playgroundModelPricingLinkClicked: ({
    provider,
    model,
  }: {
    provider: string;
    model: string;
  }) =>
    raiseEvent('Playground model pricing link clicked', { provider, model }),
  playgroundModelWebsiteLinkClicked: ({
    provider,
    model,
  }: {
    provider: string;
    model: string;
  }) =>
    raiseEvent('Playground model website link clicked', { provider, model }),

  visitedModelDeploymentsPage: () =>
    raiseEvent('Visited model deployments page'),
  deployModelDrawerOpened: () => raiseEvent('Deploy model drawer opened'),
  deployedModel: (modelData: {
    provider: string;
    model: string;
    nodeTemplateName?: string;
  }) => raiseEvent('Deployed model', { modelData }),
  stoppedModel: (model: string) => raiseEvent('Stopped model', { model }),
  startedModel: (model: string) => raiseEvent('Started model', { model }),
  deletedModel: (model: string) => raiseEvent('Deleted model', { model }),
};

export const drawerEvents = {
  drawerOpened: (args: {
    title: string;
    openedFrom?: string;
    details?: any;
  }) => {
    raiseEvent('Drawer opened', args);
  },
};

export const autoscalerEvents = {
  togglePartialTemplateMatching: (
    checked: boolean,
    karpenterVersion = 'none'
  ) =>
    raiseEvent(
      `${checked ? 'Enabled' : 'Disabled'} partial node template matching`,
      { 'Karpenter version': karpenterVersion }
    ),
  togglePodPinner: (checked: boolean) =>
    raiseEvent(`${checked ? 'Enabled' : 'Disabled'} pod pinner setting`),
  policyUpdated: ({
    place,
    cluster,
    error,
    updatedPolicy,
  }: {
    place: string;
    cluster: Cluster;
    error?: unknown;
    updatedPolicy?: PoliciesConfig;
  }) => {
    raiseEvent('Autoscaler settings edited', {
      place,
      cluster,
      updatedPolicy,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    });
  },
  nodeSelectionTemplateUpdated: ({
    place,
    organizationId,
    cluster,
    updatedTemplate,
    error,
  }: {
    place: string;
    organizationId?: string;
    cluster: Cluster;
    updatedTemplate?: NodeSelectionTemplate;
    error?: unknown;
  }) => {
    raiseEvent('Node selection template updated', {
      place,
      updatedTemplate,
      cluster,
      organizationId,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    });
  },
  nodeSelectionTemplateCreated: ({
    place,
    organizationId,
    cluster,
    updatedTemplate,
    error,
  }: {
    place: string;
    organizationId?: string;
    cluster: Cluster;
    updatedTemplate?: NodeSelectionTemplate;
    error?: unknown;
  }) => {
    raiseEvent('Node selection template created', {
      place,
      updatedTemplate,
      cluster,
      organizationId,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    });
  },
};

export const ssoConnectionEvents = {
  createSsoConnectionSucceeded: (connection: SSOConnection) =>
    raiseEvent('Create SSO connection succeeded', {
      connection: checkForChanges({}, connection),
    }),
  createSsoConnectionFailed: (error: string) =>
    raiseEvent('Create SSO connection failed', {
      error,
    }),
  updateSsoConnectionSucceeded: ({
    connection,
    newConnection,
  }: {
    connection: SSOConnection;
    newConnection: SSOConnection;
  }) =>
    raiseEvent('Update SSO connection succeeded', {
      connection: checkForChanges(connection, newConnection),
    }),
  updateSsoConnectionFailed: (error: string) =>
    raiseEvent('Update SSO connection failed', {
      error,
    }),
};

export const discountEvents = {
  discountsEnginePageVisited: () =>
    raiseEvent('Discounts engine: page visited'),
  discountCreationDrawerOpened: () =>
    raiseEvent('Discounts engine: creation drawer opened'),
  discountEditingDrawerOpened: ({ discountId }: { discountId: string }) =>
    raiseEvent('Discounts engine: editing drawer opened', { discountId }),
  discountCreated: ({
    discount,
    error,
  }: {
    discount?: Discount;
    error?: DefaultError | null;
  }) =>
    raiseEvent('Discounts engine: discount created', {
      discount,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  discountUpdated: ({
    discount,
    error,
  }: {
    discount?: Discount;
    error?: DefaultError | null;
  }) =>
    raiseEvent('Discounts engine: discount updated', {
      discount,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  discountToggled: ({
    discountId,
    enabled,
    error,
  }: {
    discountId: string;
    enabled: boolean;
    error?: DefaultError;
  }) =>
    raiseEvent('Discounts engine: discount toggled', {
      discountId,
      enabled,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
  discountDeleted: ({
    discountId,
    error,
  }: {
    discountId: string;
    error?: DefaultError;
  }) =>
    raiseEvent('Discounts engine: discount deleted', {
      discountId,
      error: error ? parseApiError(error) : undefined,
      success: !error,
    }),
};

export const auditLogEvents = {
  searchApplied: ({
    labels,
    fromDate,
    toDate,
    initiatedByEmail,
    initiatedById,
  }: AuditLogFilter) =>
    raiseEvent('Search applied', {
      labels:
        labels && Object.keys(labels).length > 0
          ? Object.keys(labels).join(',')
          : undefined,
      fromDate,
      toDate,
      initiatedBy: initiatedByEmail || initiatedById,
    }),
  rowExpanded: () => raiseEvent('Expanded event log'),
  eventsDetailsJsonView: () => raiseEvent('Clicked on event details (JSON)'),
  eventsPanelOpened: ({ triggeredBy }: { triggeredBy: string }) =>
    raiseEvent('Opened event details pane', {
      triggeredBy,
    }),
};
