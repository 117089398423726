/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiNotificationsV1beta1AckNotificationsRequest } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1AckNotificationsResponse } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1AddWebhookConfig } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1ListNotificationsResponse } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1ListWebhookCategoriesResponse } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1ListWebhookConfigsResponse } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1Notification } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1UpdateWebhookConfig } from '../models';
// @ts-ignore
import type { CastaiNotificationsV1beta1WebhookConfig } from '../models';
/**
 * NotificationAPIApi - axios parameter creator
 * @export
 */
export const NotificationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary AckNotifications acknowledges multiple notifications for the given organization
         * @param {CastaiNotificationsV1beta1AckNotificationsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIAckNotifications: async (body: CastaiNotificationsV1beta1AckNotificationsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('notificationAPIAckNotifications', 'body', body)
            const localVarPath = `/v1/notifications/ack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary CreateWebhookConfig creates a new webhook configuration for the given organization
         * @param {CastaiNotificationsV1beta1AddWebhookConfig} config The Webhook configuration data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPICreateWebhookConfig: async (config: CastaiNotificationsV1beta1AddWebhookConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'config' is not null or undefined
            assertParamExists('notificationAPICreateWebhookConfig', 'config', config)
            const localVarPath = `/v1/notifications/webhook-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary DeleteWebhookConfig an existing webhook configuration
         * @param {string} id The UUID of the Webhook configuration being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIDeleteWebhookConfig: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationAPIDeleteWebhookConfig', 'id', id)
            const localVarPath = `/v1/notifications/webhook-configurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetNotification returns an existing notification for the given organization
         * @param {string} id The UUID of the Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIGetNotification: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationAPIGetNotification', 'id', id)
            const localVarPath = `/v1/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetWebhookConfig an existing webhook configuration for the given organization
         * @param {string} id The UUID of the Webhook configuration being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIGetWebhookConfig: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationAPIGetWebhookConfig', 'id', id)
            const localVarPath = `/v1/notifications/webhook-configurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListNotifications returns notification entries for given organization.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<NotificationAPIListNotificationsFilterSeveritiesEnum>} [filterSeverities] The severities you want to filter
         * @param {boolean} [filterIsAcked] Filters to return acknowledged or not acknowledged notifications.
         * @param {string} [filterNotificationId] The id of the Notification
         * @param {string} [filterNotificationName] The name of the Notification
         * @param {string} [filterClusterId] The id of the Cluster included in the ClusterMetadata
         * @param {string} [filterClusterName] The name of the Cluster included in the ClusterMetadata
         * @param {string} [filterOperationId] The id of the Operation included in the OperationMetadata
         * @param {string} [filterOperationType] The type of the Operation included in the OperationMetadata
         * @param {string} [filterProject] The project the cluster belongs in the ClusterMetadata
         * @param {boolean} [filterIsExpired] Filters to return expired or not expired notifications.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NotificationAPIListNotificationsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIListNotifications: async (pageLimit?: string, pageCursor?: string, filterSeverities?: Array<NotificationAPIListNotificationsFilterSeveritiesEnum>, filterIsAcked?: boolean, filterNotificationId?: string, filterNotificationName?: string, filterClusterId?: string, filterClusterName?: string, filterOperationId?: string, filterOperationType?: string, filterProject?: string, filterIsExpired?: boolean, sortField?: string, sortOrder?: NotificationAPIListNotificationsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (filterSeverities) {
                localVarQueryParameter['filter.severities'] = filterSeverities;
            }

            if (filterIsAcked !== undefined) {
                localVarQueryParameter['filter.isAcked'] = filterIsAcked;
            }

            if (filterNotificationId !== undefined) {
                localVarQueryParameter['filter.notificationId'] = filterNotificationId;
            }

            if (filterNotificationName !== undefined) {
                localVarQueryParameter['filter.notificationName'] = filterNotificationName;
            }

            if (filterClusterId !== undefined) {
                localVarQueryParameter['filter.clusterId'] = filterClusterId;
            }

            if (filterClusterName !== undefined) {
                localVarQueryParameter['filter.clusterName'] = filterClusterName;
            }

            if (filterOperationId !== undefined) {
                localVarQueryParameter['filter.operationId'] = filterOperationId;
            }

            if (filterOperationType !== undefined) {
                localVarQueryParameter['filter.operationType'] = filterOperationType;
            }

            if (filterProject !== undefined) {
                localVarQueryParameter['filter.project'] = filterProject;
            }

            if (filterIsExpired !== undefined) {
                localVarQueryParameter['filter.isExpired'] = filterIsExpired;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListWebhookConfig returns all available webhook categories and subcategories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIListWebhookCategories: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/webhook-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ListWebhookConfig returns all existing webhook configurations for the given organization
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<NotificationAPIListWebhookConfigsFilterSeveritiesEnum>} [filterSeverities] The severities to be applied for filtering
         * @param {string} [filterStatus] The status to be applied for filtering
         * @param {Array<string>} [filterCategories] The categories to be applied for filtering. For all leave it (as well as subcategories) empty.
         * @param {Array<string>} [filterSubcategories] The subcategories to be applied for filtering.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NotificationAPIListWebhookConfigsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIListWebhookConfigs: async (pageLimit?: string, pageCursor?: string, filterSeverities?: Array<NotificationAPIListWebhookConfigsFilterSeveritiesEnum>, filterStatus?: string, filterCategories?: Array<string>, filterSubcategories?: Array<string>, sortField?: string, sortOrder?: NotificationAPIListWebhookConfigsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notifications/webhook-configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (filterSeverities) {
                localVarQueryParameter['filter.severities'] = filterSeverities;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['filter.status'] = filterStatus;
            }

            if (filterCategories) {
                localVarQueryParameter['filter.categories'] = filterCategories;
            }

            if (filterSubcategories) {
                localVarQueryParameter['filter.subcategories'] = filterSubcategories;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary UpdateWebhookConfig an existing webhook configuration for the given organization
         * @param {string} id The UUID of the Webhook configuration being deleted
         * @param {CastaiNotificationsV1beta1UpdateWebhookConfig} config The Webhook configuration data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIUpdateWebhookConfig: async (id: string, config: CastaiNotificationsV1beta1UpdateWebhookConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('notificationAPIUpdateWebhookConfig', 'id', id)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('notificationAPIUpdateWebhookConfig', 'config', config)
            const localVarPath = `/v1/notifications/webhook-configurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationAPIApi - functional programming interface
 * @export
 */
export const NotificationAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary AckNotifications acknowledges multiple notifications for the given organization
         * @param {CastaiNotificationsV1beta1AckNotificationsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIAckNotifications(body: CastaiNotificationsV1beta1AckNotificationsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1AckNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIAckNotifications(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIAckNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary CreateWebhookConfig creates a new webhook configuration for the given organization
         * @param {CastaiNotificationsV1beta1AddWebhookConfig} config The Webhook configuration data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPICreateWebhookConfig(config: CastaiNotificationsV1beta1AddWebhookConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1WebhookConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPICreateWebhookConfig(config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPICreateWebhookConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary DeleteWebhookConfig an existing webhook configuration
         * @param {string} id The UUID of the Webhook configuration being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIDeleteWebhookConfig(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIDeleteWebhookConfig(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIDeleteWebhookConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetNotification returns an existing notification for the given organization
         * @param {string} id The UUID of the Notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIGetNotification(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIGetNotification(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIGetNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary GetWebhookConfig an existing webhook configuration for the given organization
         * @param {string} id The UUID of the Webhook configuration being deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIGetWebhookConfig(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1WebhookConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIGetWebhookConfig(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIGetWebhookConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListNotifications returns notification entries for given organization.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<NotificationAPIListNotificationsFilterSeveritiesEnum>} [filterSeverities] The severities you want to filter
         * @param {boolean} [filterIsAcked] Filters to return acknowledged or not acknowledged notifications.
         * @param {string} [filterNotificationId] The id of the Notification
         * @param {string} [filterNotificationName] The name of the Notification
         * @param {string} [filterClusterId] The id of the Cluster included in the ClusterMetadata
         * @param {string} [filterClusterName] The name of the Cluster included in the ClusterMetadata
         * @param {string} [filterOperationId] The id of the Operation included in the OperationMetadata
         * @param {string} [filterOperationType] The type of the Operation included in the OperationMetadata
         * @param {string} [filterProject] The project the cluster belongs in the ClusterMetadata
         * @param {boolean} [filterIsExpired] Filters to return expired or not expired notifications.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NotificationAPIListNotificationsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIListNotifications(pageLimit?: string, pageCursor?: string, filterSeverities?: Array<NotificationAPIListNotificationsFilterSeveritiesEnum>, filterIsAcked?: boolean, filterNotificationId?: string, filterNotificationName?: string, filterClusterId?: string, filterClusterName?: string, filterOperationId?: string, filterOperationType?: string, filterProject?: string, filterIsExpired?: boolean, sortField?: string, sortOrder?: NotificationAPIListNotificationsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1ListNotificationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIListNotifications(pageLimit, pageCursor, filterSeverities, filterIsAcked, filterNotificationId, filterNotificationName, filterClusterId, filterClusterName, filterOperationId, filterOperationType, filterProject, filterIsExpired, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIListNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListWebhookConfig returns all available webhook categories and subcategories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIListWebhookCategories(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1ListWebhookCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIListWebhookCategories(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIListWebhookCategories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary ListWebhookConfig returns all existing webhook configurations for the given organization
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {Array<NotificationAPIListWebhookConfigsFilterSeveritiesEnum>} [filterSeverities] The severities to be applied for filtering
         * @param {string} [filterStatus] The status to be applied for filtering
         * @param {Array<string>} [filterCategories] The categories to be applied for filtering. For all leave it (as well as subcategories) empty.
         * @param {Array<string>} [filterSubcategories] The subcategories to be applied for filtering.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NotificationAPIListWebhookConfigsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIListWebhookConfigs(pageLimit?: string, pageCursor?: string, filterSeverities?: Array<NotificationAPIListWebhookConfigsFilterSeveritiesEnum>, filterStatus?: string, filterCategories?: Array<string>, filterSubcategories?: Array<string>, sortField?: string, sortOrder?: NotificationAPIListWebhookConfigsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1ListWebhookConfigsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIListWebhookConfigs(pageLimit, pageCursor, filterSeverities, filterStatus, filterCategories, filterSubcategories, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIListWebhookConfigs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary UpdateWebhookConfig an existing webhook configuration for the given organization
         * @param {string} id The UUID of the Webhook configuration being deleted
         * @param {CastaiNotificationsV1beta1UpdateWebhookConfig} config The Webhook configuration data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationAPIUpdateWebhookConfig(id: string, config: CastaiNotificationsV1beta1UpdateWebhookConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiNotificationsV1beta1WebhookConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationAPIUpdateWebhookConfig(id, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NotificationAPIApi.notificationAPIUpdateWebhookConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NotificationAPIApi - factory interface
 * @export
 */
export const NotificationAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary AckNotifications acknowledges multiple notifications for the given organization
         * @param {NotificationAPIApiNotificationAPIAckNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIAckNotifications(requestParameters: NotificationAPIApiNotificationAPIAckNotificationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1AckNotificationsResponse> {
            return localVarFp.notificationAPIAckNotifications(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary CreateWebhookConfig creates a new webhook configuration for the given organization
         * @param {NotificationAPIApiNotificationAPICreateWebhookConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPICreateWebhookConfig(requestParameters: NotificationAPIApiNotificationAPICreateWebhookConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1WebhookConfig> {
            return localVarFp.notificationAPICreateWebhookConfig(requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary DeleteWebhookConfig an existing webhook configuration
         * @param {NotificationAPIApiNotificationAPIDeleteWebhookConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIDeleteWebhookConfig(requestParameters: NotificationAPIApiNotificationAPIDeleteWebhookConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.notificationAPIDeleteWebhookConfig(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetNotification returns an existing notification for the given organization
         * @param {NotificationAPIApiNotificationAPIGetNotificationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIGetNotification(requestParameters: NotificationAPIApiNotificationAPIGetNotificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1Notification> {
            return localVarFp.notificationAPIGetNotification(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetWebhookConfig an existing webhook configuration for the given organization
         * @param {NotificationAPIApiNotificationAPIGetWebhookConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIGetWebhookConfig(requestParameters: NotificationAPIApiNotificationAPIGetWebhookConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1WebhookConfig> {
            return localVarFp.notificationAPIGetWebhookConfig(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListNotifications returns notification entries for given organization.
         * @param {NotificationAPIApiNotificationAPIListNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIListNotifications(requestParameters: NotificationAPIApiNotificationAPIListNotificationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1ListNotificationsResponse> {
            return localVarFp.notificationAPIListNotifications(requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.filterSeverities, requestParameters.filterIsAcked, requestParameters.filterNotificationId, requestParameters.filterNotificationName, requestParameters.filterClusterId, requestParameters.filterClusterName, requestParameters.filterOperationId, requestParameters.filterOperationType, requestParameters.filterProject, requestParameters.filterIsExpired, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListWebhookConfig returns all available webhook categories and subcategories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIListWebhookCategories(options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1ListWebhookCategoriesResponse> {
            return localVarFp.notificationAPIListWebhookCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ListWebhookConfig returns all existing webhook configurations for the given organization
         * @param {NotificationAPIApiNotificationAPIListWebhookConfigsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIListWebhookConfigs(requestParameters: NotificationAPIApiNotificationAPIListWebhookConfigsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1ListWebhookConfigsResponse> {
            return localVarFp.notificationAPIListWebhookConfigs(requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.filterSeverities, requestParameters.filterStatus, requestParameters.filterCategories, requestParameters.filterSubcategories, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary UpdateWebhookConfig an existing webhook configuration for the given organization
         * @param {NotificationAPIApiNotificationAPIUpdateWebhookConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationAPIUpdateWebhookConfig(requestParameters: NotificationAPIApiNotificationAPIUpdateWebhookConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiNotificationsV1beta1WebhookConfig> {
            return localVarFp.notificationAPIUpdateWebhookConfig(requestParameters.id, requestParameters.config, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for notificationAPIAckNotifications operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIAckNotificationsRequest
 */
export interface NotificationAPIApiNotificationAPIAckNotificationsRequest {
    /**
     * 
     * @type {CastaiNotificationsV1beta1AckNotificationsRequest}
     * @memberof NotificationAPIApiNotificationAPIAckNotifications
     */
    readonly body: CastaiNotificationsV1beta1AckNotificationsRequest
}

/**
 * Request parameters for notificationAPICreateWebhookConfig operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPICreateWebhookConfigRequest
 */
export interface NotificationAPIApiNotificationAPICreateWebhookConfigRequest {
    /**
     * The Webhook configuration data
     * @type {CastaiNotificationsV1beta1AddWebhookConfig}
     * @memberof NotificationAPIApiNotificationAPICreateWebhookConfig
     */
    readonly config: CastaiNotificationsV1beta1AddWebhookConfig
}

/**
 * Request parameters for notificationAPIDeleteWebhookConfig operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIDeleteWebhookConfigRequest
 */
export interface NotificationAPIApiNotificationAPIDeleteWebhookConfigRequest {
    /**
     * The UUID of the Webhook configuration being deleted
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIDeleteWebhookConfig
     */
    readonly id: string
}

/**
 * Request parameters for notificationAPIGetNotification operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIGetNotificationRequest
 */
export interface NotificationAPIApiNotificationAPIGetNotificationRequest {
    /**
     * The UUID of the Notification
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIGetNotification
     */
    readonly id: string
}

/**
 * Request parameters for notificationAPIGetWebhookConfig operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIGetWebhookConfigRequest
 */
export interface NotificationAPIApiNotificationAPIGetWebhookConfigRequest {
    /**
     * The UUID of the Webhook configuration being deleted
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIGetWebhookConfig
     */
    readonly id: string
}

/**
 * Request parameters for notificationAPIListNotifications operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIListNotificationsRequest
 */
export interface NotificationAPIApiNotificationAPIListNotificationsRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly pageCursor?: string

    /**
     * The severities you want to filter
     * @type {Array<'UNSPECIFIED' | 'CRITICAL' | 'ERROR' | 'WARNING' | 'INFO' | 'SUCCESS'>}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterSeverities?: Array<NotificationAPIListNotificationsFilterSeveritiesEnum>

    /**
     * Filters to return acknowledged or not acknowledged notifications.
     * @type {boolean}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterIsAcked?: boolean

    /**
     * The id of the Notification
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterNotificationId?: string

    /**
     * The name of the Notification
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterNotificationName?: string

    /**
     * The id of the Cluster included in the ClusterMetadata
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterClusterId?: string

    /**
     * The name of the Cluster included in the ClusterMetadata
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterClusterName?: string

    /**
     * The id of the Operation included in the OperationMetadata
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterOperationId?: string

    /**
     * The type of the Operation included in the OperationMetadata
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterOperationType?: string

    /**
     * The project the cluster belongs in the ClusterMetadata
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterProject?: string

    /**
     * Filters to return expired or not expired notifications.
     * @type {boolean}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly filterIsExpired?: boolean

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof NotificationAPIApiNotificationAPIListNotifications
     */
    readonly sortOrder?: NotificationAPIListNotificationsSortOrderEnum
}

/**
 * Request parameters for notificationAPIListWebhookConfigs operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIListWebhookConfigsRequest
 */
export interface NotificationAPIApiNotificationAPIListWebhookConfigsRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly pageCursor?: string

    /**
     * The severities to be applied for filtering
     * @type {Array<'UNSPECIFIED' | 'CRITICAL' | 'ERROR' | 'WARNING' | 'INFO' | 'SUCCESS'>}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly filterSeverities?: Array<NotificationAPIListWebhookConfigsFilterSeveritiesEnum>

    /**
     * The status to be applied for filtering
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly filterStatus?: string

    /**
     * The categories to be applied for filtering. For all leave it (as well as subcategories) empty.
     * @type {Array<string>}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly filterCategories?: Array<string>

    /**
     * The subcategories to be applied for filtering.
     * @type {Array<string>}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly filterSubcategories?: Array<string>

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof NotificationAPIApiNotificationAPIListWebhookConfigs
     */
    readonly sortOrder?: NotificationAPIListWebhookConfigsSortOrderEnum
}

/**
 * Request parameters for notificationAPIUpdateWebhookConfig operation in NotificationAPIApi.
 * @export
 * @interface NotificationAPIApiNotificationAPIUpdateWebhookConfigRequest
 */
export interface NotificationAPIApiNotificationAPIUpdateWebhookConfigRequest {
    /**
     * The UUID of the Webhook configuration being deleted
     * @type {string}
     * @memberof NotificationAPIApiNotificationAPIUpdateWebhookConfig
     */
    readonly id: string

    /**
     * The Webhook configuration data
     * @type {CastaiNotificationsV1beta1UpdateWebhookConfig}
     * @memberof NotificationAPIApiNotificationAPIUpdateWebhookConfig
     */
    readonly config: CastaiNotificationsV1beta1UpdateWebhookConfig
}

/**
 * NotificationAPIApi - object-oriented interface
 * @export
 * @class NotificationAPIApi
 * @extends {BaseAPI}
 */
export class NotificationAPIApi extends BaseAPI {
    /**
     * 
     * @summary AckNotifications acknowledges multiple notifications for the given organization
     * @param {NotificationAPIApiNotificationAPIAckNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIAckNotifications(requestParameters: NotificationAPIApiNotificationAPIAckNotificationsRequest, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIAckNotifications(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary CreateWebhookConfig creates a new webhook configuration for the given organization
     * @param {NotificationAPIApiNotificationAPICreateWebhookConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPICreateWebhookConfig(requestParameters: NotificationAPIApiNotificationAPICreateWebhookConfigRequest, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPICreateWebhookConfig(requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary DeleteWebhookConfig an existing webhook configuration
     * @param {NotificationAPIApiNotificationAPIDeleteWebhookConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIDeleteWebhookConfig(requestParameters: NotificationAPIApiNotificationAPIDeleteWebhookConfigRequest, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIDeleteWebhookConfig(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetNotification returns an existing notification for the given organization
     * @param {NotificationAPIApiNotificationAPIGetNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIGetNotification(requestParameters: NotificationAPIApiNotificationAPIGetNotificationRequest, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIGetNotification(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetWebhookConfig an existing webhook configuration for the given organization
     * @param {NotificationAPIApiNotificationAPIGetWebhookConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIGetWebhookConfig(requestParameters: NotificationAPIApiNotificationAPIGetWebhookConfigRequest, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIGetWebhookConfig(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListNotifications returns notification entries for given organization.
     * @param {NotificationAPIApiNotificationAPIListNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIListNotifications(requestParameters: NotificationAPIApiNotificationAPIListNotificationsRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIListNotifications(requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.filterSeverities, requestParameters.filterIsAcked, requestParameters.filterNotificationId, requestParameters.filterNotificationName, requestParameters.filterClusterId, requestParameters.filterClusterName, requestParameters.filterOperationId, requestParameters.filterOperationType, requestParameters.filterProject, requestParameters.filterIsExpired, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListWebhookConfig returns all available webhook categories and subcategories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIListWebhookCategories(options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIListWebhookCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ListWebhookConfig returns all existing webhook configurations for the given organization
     * @param {NotificationAPIApiNotificationAPIListWebhookConfigsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIListWebhookConfigs(requestParameters: NotificationAPIApiNotificationAPIListWebhookConfigsRequest = {}, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIListWebhookConfigs(requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.filterSeverities, requestParameters.filterStatus, requestParameters.filterCategories, requestParameters.filterSubcategories, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary UpdateWebhookConfig an existing webhook configuration for the given organization
     * @param {NotificationAPIApiNotificationAPIUpdateWebhookConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationAPIApi
     */
    public notificationAPIUpdateWebhookConfig(requestParameters: NotificationAPIApiNotificationAPIUpdateWebhookConfigRequest, options?: RawAxiosRequestConfig) {
        return NotificationAPIApiFp(this.configuration).notificationAPIUpdateWebhookConfig(requestParameters.id, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const NotificationAPIListNotificationsFilterSeveritiesEnum = {
    UNSPECIFIED: 'UNSPECIFIED',
    CRITICAL: 'CRITICAL',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS'
} as const;
export type NotificationAPIListNotificationsFilterSeveritiesEnum = typeof NotificationAPIListNotificationsFilterSeveritiesEnum[keyof typeof NotificationAPIListNotificationsFilterSeveritiesEnum];
/**
 * @export
 */
export const NotificationAPIListNotificationsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type NotificationAPIListNotificationsSortOrderEnum = typeof NotificationAPIListNotificationsSortOrderEnum[keyof typeof NotificationAPIListNotificationsSortOrderEnum];
/**
 * @export
 */
export const NotificationAPIListWebhookConfigsFilterSeveritiesEnum = {
    UNSPECIFIED: 'UNSPECIFIED',
    CRITICAL: 'CRITICAL',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS'
} as const;
export type NotificationAPIListWebhookConfigsFilterSeveritiesEnum = typeof NotificationAPIListWebhookConfigsFilterSeveritiesEnum[keyof typeof NotificationAPIListWebhookConfigsFilterSeveritiesEnum];
/**
 * @export
 */
export const NotificationAPIListWebhookConfigsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type NotificationAPIListWebhookConfigsSortOrderEnum = typeof NotificationAPIListWebhookConfigsSortOrderEnum[keyof typeof NotificationAPIListWebhookConfigsSortOrderEnum];
