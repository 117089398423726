/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { UserSession } from '../models';
/**
 * AuthAPIApi - axios parameter creator
 * @export
 */
export const AuthAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * As JS has no access to JWT token, this provides a subset of information about user session.
         * @summary Current login session info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Redirect browser to AUTH0 login flow, where user authenticates, browser receives authentication cookie, and then user\'s browser gets redirected to provided `returnTo` URL. From that point on, all API calls don\'t require additional headers as HTTP-only cookie will be attached to each request.
         * @summary Login
         * @param {string} [returnTo] URL to redirect browser to after operation completes
         * @param {string} [auth0returnTo] optional override for URL to redirect browser from auth0 back to backend, for situations where backend is being reached via proxy
         * @param {string} [screenHint] optional hint for which screen to use when redirecting to authentication pages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (returnTo?: string, auth0returnTo?: string, screenHint?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (returnTo !== undefined) {
                localVarQueryParameter['returnTo'] = returnTo;
            }

            if (auth0returnTo !== undefined) {
                localVarQueryParameter['auth0returnTo'] = auth0returnTo;
            }

            if (screenHint !== undefined) {
                localVarQueryParameter['screenHint'] = screenHint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Callback endpoint where login flow returns after authentication succeeds on auth0. Not to be called directly.
         * @summary Login callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCallback: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Close login session, remove relevant cookies and redirect browser to provided URL.
         * @summary Logout
         * @param {string} [returnTo] URL to redirect browser to after operation completes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (returnTo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (returnTo !== undefined) {
                localVarQueryParameter['returnTo'] = returnTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SAML flow callback invoked after the flow is completed.
         * @summary SAML flow callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        samlAcs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/saml/acs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthAPIApi - functional programming interface
 * @export
 */
export const AuthAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * As JS has no access to JWT token, this provides a subset of information about user session.
         * @summary Current login session info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthAPIApi.getSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Redirect browser to AUTH0 login flow, where user authenticates, browser receives authentication cookie, and then user\'s browser gets redirected to provided `returnTo` URL. From that point on, all API calls don\'t require additional headers as HTTP-only cookie will be attached to each request.
         * @summary Login
         * @param {string} [returnTo] URL to redirect browser to after operation completes
         * @param {string} [auth0returnTo] optional override for URL to redirect browser from auth0 back to backend, for situations where backend is being reached via proxy
         * @param {string} [screenHint] optional hint for which screen to use when redirecting to authentication pages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(returnTo?: string, auth0returnTo?: string, screenHint?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(returnTo, auth0returnTo, screenHint, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthAPIApi.login']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Callback endpoint where login flow returns after authentication succeeds on auth0. Not to be called directly.
         * @summary Login callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginCallback(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginCallback(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthAPIApi.loginCallback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Close login session, remove relevant cookies and redirect browser to provided URL.
         * @summary Logout
         * @param {string} [returnTo] URL to redirect browser to after operation completes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(returnTo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(returnTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthAPIApi.logout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * SAML flow callback invoked after the flow is completed.
         * @summary SAML flow callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async samlAcs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.samlAcs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthAPIApi.samlAcs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthAPIApi - factory interface
 * @export
 */
export const AuthAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthAPIApiFp(configuration)
    return {
        /**
         * As JS has no access to JWT token, this provides a subset of information about user session.
         * @summary Current login session info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options?: RawAxiosRequestConfig): AxiosPromise<UserSession> {
            return localVarFp.getSession(options).then((request) => request(axios, basePath));
        },
        /**
         * Redirect browser to AUTH0 login flow, where user authenticates, browser receives authentication cookie, and then user\'s browser gets redirected to provided `returnTo` URL. From that point on, all API calls don\'t require additional headers as HTTP-only cookie will be attached to each request.
         * @summary Login
         * @param {AuthAPIApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: AuthAPIApiLoginRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.login(requestParameters.returnTo, requestParameters.auth0returnTo, requestParameters.screenHint, options).then((request) => request(axios, basePath));
        },
        /**
         * Callback endpoint where login flow returns after authentication succeeds on auth0. Not to be called directly.
         * @summary Login callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginCallback(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.loginCallback(options).then((request) => request(axios, basePath));
        },
        /**
         * Close login session, remove relevant cookies and redirect browser to provided URL.
         * @summary Logout
         * @param {AuthAPIApiLogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(requestParameters: AuthAPIApiLogoutRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.logout(requestParameters.returnTo, options).then((request) => request(axios, basePath));
        },
        /**
         * SAML flow callback invoked after the flow is completed.
         * @summary SAML flow callback
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        samlAcs(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.samlAcs(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for login operation in AuthAPIApi.
 * @export
 * @interface AuthAPIApiLoginRequest
 */
export interface AuthAPIApiLoginRequest {
    /**
     * URL to redirect browser to after operation completes
     * @type {string}
     * @memberof AuthAPIApiLogin
     */
    readonly returnTo?: string

    /**
     * optional override for URL to redirect browser from auth0 back to backend, for situations where backend is being reached via proxy
     * @type {string}
     * @memberof AuthAPIApiLogin
     */
    readonly auth0returnTo?: string

    /**
     * optional hint for which screen to use when redirecting to authentication pages.
     * @type {string}
     * @memberof AuthAPIApiLogin
     */
    readonly screenHint?: string
}

/**
 * Request parameters for logout operation in AuthAPIApi.
 * @export
 * @interface AuthAPIApiLogoutRequest
 */
export interface AuthAPIApiLogoutRequest {
    /**
     * URL to redirect browser to after operation completes
     * @type {string}
     * @memberof AuthAPIApiLogout
     */
    readonly returnTo?: string
}

/**
 * AuthAPIApi - object-oriented interface
 * @export
 * @class AuthAPIApi
 * @extends {BaseAPI}
 */
export class AuthAPIApi extends BaseAPI {
    /**
     * As JS has no access to JWT token, this provides a subset of information about user session.
     * @summary Current login session info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public getSession(options?: RawAxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).getSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Redirect browser to AUTH0 login flow, where user authenticates, browser receives authentication cookie, and then user\'s browser gets redirected to provided `returnTo` URL. From that point on, all API calls don\'t require additional headers as HTTP-only cookie will be attached to each request.
     * @summary Login
     * @param {AuthAPIApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public login(requestParameters: AuthAPIApiLoginRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).login(requestParameters.returnTo, requestParameters.auth0returnTo, requestParameters.screenHint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Callback endpoint where login flow returns after authentication succeeds on auth0. Not to be called directly.
     * @summary Login callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public loginCallback(options?: RawAxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).loginCallback(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Close login session, remove relevant cookies and redirect browser to provided URL.
     * @summary Logout
     * @param {AuthAPIApiLogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public logout(requestParameters: AuthAPIApiLogoutRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).logout(requestParameters.returnTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SAML flow callback invoked after the flow is completed.
     * @summary SAML flow callback
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthAPIApi
     */
    public samlAcs(options?: RawAxiosRequestConfig) {
        return AuthAPIApiFp(this.configuration).samlAcs(options).then((request) => request(this.axios, this.basePath));
    }
}

