/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ListHostedModelSpecsResponse } from '../models';
// @ts-ignore
import type { Status } from '../models';
/**
 * HostedModelSpecsAPIApi - axios parameter creator
 * @export
 */
export const HostedModelSpecsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the list of hosted model specs.
         * @summary List hosted model specs.
         * @param {HostedModelSpecsAPIListHostedModelSpecsCloudEnum} [cloud] The cloud provider.
         * @param {string} [region] The region of cloud provider. We use the region to find the price of the instance type for running the hosted model.  If not specified, the cheapest instance type\&#39;s price will be used from any region.
         * @param {string} [pageLimit] Defines the number of items that should be returned
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelSpecsAPIListHostedModelSpecs: async (cloud?: HostedModelSpecsAPIListHostedModelSpecsCloudEnum, region?: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ai-optimizer/v1beta/hosted-model-specs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cloud !== undefined) {
                localVarQueryParameter['cloud'] = cloud;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HostedModelSpecsAPIApi - functional programming interface
 * @export
 */
export const HostedModelSpecsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HostedModelSpecsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the list of hosted model specs.
         * @summary List hosted model specs.
         * @param {HostedModelSpecsAPIListHostedModelSpecsCloudEnum} [cloud] The cloud provider.
         * @param {string} [region] The region of cloud provider. We use the region to find the price of the instance type for running the hosted model.  If not specified, the cheapest instance type\&#39;s price will be used from any region.
         * @param {string} [pageLimit] Defines the number of items that should be returned
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hostedModelSpecsAPIListHostedModelSpecs(cloud?: HostedModelSpecsAPIListHostedModelSpecsCloudEnum, region?: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListHostedModelSpecsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hostedModelSpecsAPIListHostedModelSpecs(cloud, region, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HostedModelSpecsAPIApi.hostedModelSpecsAPIListHostedModelSpecs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HostedModelSpecsAPIApi - factory interface
 * @export
 */
export const HostedModelSpecsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HostedModelSpecsAPIApiFp(configuration)
    return {
        /**
         * Gets the list of hosted model specs.
         * @summary List hosted model specs.
         * @param {HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelSpecsAPIListHostedModelSpecs(requestParameters: HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ListHostedModelSpecsResponse> {
            return localVarFp.hostedModelSpecsAPIListHostedModelSpecs(requestParameters.cloud, requestParameters.region, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hostedModelSpecsAPIListHostedModelSpecs operation in HostedModelSpecsAPIApi.
 * @export
 * @interface HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecsRequest
 */
export interface HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecsRequest {
    /**
     * The cloud provider.
     * @type {'CLOUD_UNSPECIFIED' | 'AWS' | 'GCP' | 'AZURE'}
     * @memberof HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecs
     */
    readonly cloud?: HostedModelSpecsAPIListHostedModelSpecsCloudEnum

    /**
     * The region of cloud provider. We use the region to find the price of the instance type for running the hosted model.  If not specified, the cheapest instance type\&#39;s price will be used from any region.
     * @type {string}
     * @memberof HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecs
     */
    readonly region?: string

    /**
     * Defines the number of items that should be returned
     * @type {string}
     * @memberof HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecs
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecs
     */
    readonly pageCursor?: string
}

/**
 * HostedModelSpecsAPIApi - object-oriented interface
 * @export
 * @class HostedModelSpecsAPIApi
 * @extends {BaseAPI}
 */
export class HostedModelSpecsAPIApi extends BaseAPI {
    /**
     * Gets the list of hosted model specs.
     * @summary List hosted model specs.
     * @param {HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostedModelSpecsAPIApi
     */
    public hostedModelSpecsAPIListHostedModelSpecs(requestParameters: HostedModelSpecsAPIApiHostedModelSpecsAPIListHostedModelSpecsRequest = {}, options?: RawAxiosRequestConfig) {
        return HostedModelSpecsAPIApiFp(this.configuration).hostedModelSpecsAPIListHostedModelSpecs(requestParameters.cloud, requestParameters.region, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const HostedModelSpecsAPIListHostedModelSpecsCloudEnum = {
    CLOUD_UNSPECIFIED: 'CLOUD_UNSPECIFIED',
    AWS: 'AWS',
    GCP: 'GCP',
    AZURE: 'AZURE'
} as const;
export type HostedModelSpecsAPIListHostedModelSpecsCloudEnum = typeof HostedModelSpecsAPIListHostedModelSpecsCloudEnum[keyof typeof HostedModelSpecsAPIListHostedModelSpecsCloudEnum];
