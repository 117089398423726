import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Stack, styled, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { LinkProps } from 'react-router-dom';

import { DATE_TIME } from '@cast/constants';
import {
  Chip,
  Column,
  Icons,
  Paginator,
  TableActionButton,
  TableBulkActions,
  TableHeader,
  TableRecordsCount,
  Tooltip,
} from '@cast/design-system';
import { Notification } from '@cast/types';

import { tableCacheKeys } from 'common/tableCacheKeys';
import { FailedToLoad } from 'components/messages';
import { Link } from 'components/router';
import {
  NoResults,
  SearchableTable,
  useSearchContext,
} from 'components/search';
import { EllipsisWithTooltip } from 'components/tooltip';
import { useAbility } from 'core/ability';
import { useAckNotificationsMutation } from 'hooks/mutations/notifications';

import { NoNotifications, SeverityChip } from '../../_components';
import { useNotificationDrawer } from '../../hooks/useNotificationDrawer';

const StyledLink = styled(Link)<PropsWithChildren<LinkProps>>(({ theme }) => ({
  ...theme.typography.P12R,
  color: theme.palette.blue[500],
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

export type NotificationsTableProps = {
  pageSize?: number;
  currentPage?: number;
  count?: number;
};

export const NotificationsTable = ({
  currentPage,
  count,
  pageSize,
}: NotificationsTableProps) => {
  const { openNotificationDrawer } = useNotificationDrawer();
  const [selected, setSelected] = useState<string[]>([]);
  const searchContext = useSearchContext();
  const ackNotificationsMutation = useAckNotificationsMutation(() =>
    setSelected([])
  );
  const canEdit = useAbility().can('update', 'OrganizationNotifications');
  const allIds = useMemo(
    () => searchContext?.data?.map((item) => item.id + '') || [],
    [searchContext?.data]
  );

  const handleSelectedRows = useCallback(
    (keys: string[]) => {
      if (!keys.length) {
        if (selected.length) {
          setSelected([]);
        } else {
          if (allIds?.length) {
            setSelected(allIds);
          }
        }
      } else {
        setSelected(keys);
      }
    },
    [allIds, selected.length]
  );

  const paginatorVisible =
    !searchContext?.isLoading &&
    currentPage !== undefined &&
    pageSize !== undefined &&
    count !== undefined;

  return (
    <SearchableTable
      cacheKey={tableCacheKeys.NOTIFICATIONS}
      testId="notifications-table"
      urlKey="notifications"
      maxHeight="calc(100vh - 240px)"
      selectedRows={canEdit ? selected : undefined}
      onRowsSelected={handleSelectedRows}
      components={{
        noData: <NoNotifications />,
        noResults: (
          <NoResults icon={<Icons.BellSimpleRinging />} entity="notification" />
        ),
        failed: <FailedToLoad title="Failed to load notifications" />,
      }}
      getRowClass={(row: Notification) =>
        row.ackAt ? 'resolved-notification' : undefined
      }
      onRowClick={(_, { row }) => {
        if (!searchContext?.isLoading) {
          openNotificationDrawer({ notification: row, openedFrom: 'table' });
        }
      }}
      rowKey={(row: Notification) => row.id}
      outerHeader={
        !!searchContext?.data?.length && (
          <TableHeader
            recordsCount={
              <TableRecordsCount
                count={selected.length}
                outOf={count}
                title="notification"
              />
            }
            bulkActions={
              <TableBulkActions visible={!!selected.length}>
                <TableActionButton
                  onClick={() => {
                    if (ackNotificationsMutation.isPending) {
                      return;
                    }
                    ackNotificationsMutation.mutate(selected);
                  }}
                  startIcon={
                    selected?.length === allIds.length ? (
                      <Icons.Checks />
                    ) : (
                      <Icons.CheckSquare />
                    )
                  }
                  disabled={!canEdit}
                  testId="resolve-selected-button"
                >
                  {selected?.length === allIds.length
                    ? 'Resolve all'
                    : 'Resolve selected'}
                </TableActionButton>
              </TableBulkActions>
            }
            paginator={
              paginatorVisible && (
                <Paginator
                  recordsCount={count!}
                  pageSize={pageSize!}
                  currentPage={currentPage! + 1}
                  onCurrentPageChange={(page) => {
                    if (page > currentPage!) {
                      searchContext?.fetchNextPage?.();
                    }
                    if (page <= currentPage!) {
                      searchContext?.fetchPreviousPage?.();
                    }
                  }}
                />
              )
            }
          />
        )
      }
      sx={(theme) => ({
        width: '100%',

        '& .DS-Table-BodyRow': {
          cursor: 'pointer',
        },

        '& .resolved-notification': {
          background: theme.palette.grey[50],
        },

        '& .resolved-notification .DS-Table-BodyRow:hover': {
          background: theme.palette.grey[100],
        },
      })}
    >
      <Column
        id="name"
        header="NAME"
        minWidth={256}
        renderCell={({ name, ackAt }: Notification) => (
          <EllipsisWithTooltip
            color="blue.500"
            TypographyProps={{ variant: ackAt ? 'P12R' : 'P12B' }}
            fontWeight={!ackAt ? 700 : undefined}
          >
            {name}
          </EllipsisWithTooltip>
        )}
        sortable
        resizable
      />
      <Column
        id="clusterName"
        header="CLUSTER"
        minWidth={240}
        renderCell={({ clusterMetadata }) => {
          if (!clusterMetadata) {
            return null;
          }
          return (
            <Stack>
              <StyledLink
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                to={`/external-clusters/${clusterMetadata.id}`}
              >
                <Typography variant="P12R" noWrap>
                  {clusterMetadata.name}
                </Typography>
              </StyledLink>
              {clusterMetadata.project && (
                <Typography variant="P10R" color="grey.400" noWrap mt={-4}>
                  {clusterMetadata.project}
                </Typography>
              )}
            </Stack>
          );
        }}
        sortable
      />
      <Column
        id="operationType"
        header="OPERATION"
        renderCell={({ operationMetadata }) => (
          <Typography variant="P12R">{operationMetadata?.type}</Typography>
        )}
        minWidth={200}
        sortable
      />
      <Column
        id="timestamp"
        header="TIMESTAMP"
        renderCell={({ timestamp, isExpired }) => (
          <Stack direction="row" alignItems="center" gap={8}>
            <Typography variant="P12R">
              {dayjs(timestamp).format(DATE_TIME)}
            </Typography>
            {isExpired && (
              <Tooltip title="Obsolete notification" placement="bottom" arrow>
                <Chip
                  iconChip
                  startIcon={<Icons.Clock size={12} />}
                  size="small"
                  sx={{
                    color: 'grey.500',
                    backgroundColor: 'grey.200',
                  }}
                />
              </Tooltip>
            )}
          </Stack>
        )}
        minWidth={240}
        sortable
        defaultSort="desc"
      />
      <Column
        id="severity"
        header="SEVERITY"
        minWidth={160}
        renderCell={({ severity }) => (
          <SeverityChip severity={severity} size="small" />
        )}
        sortable
      />
    </SearchableTable>
  );
};
