import { forwardRef } from 'react';

import { styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

import { Icons, Tooltip } from '@cast/design-system';
import { NodeResourceOffering } from '@cast/types';

import { NodeType } from 'types/nodes';

type NodeResourceOfferingIconProps = Icons.IconProps & {
  // TODO: Leave only one node type
  resourceOffering: NodeResourceOffering | NodeType;
  problematic?: boolean;
  sx?: SxProps;
};

const StyledNodeIcon = styled(Icons.NodeIcon)``;

export const NodeResourceOfferingIcon = forwardRef<
  SVGSVGElement,
  NodeResourceOfferingIconProps
>(({ resourceOffering, problematic, ...props }, ref) => {
  const { palette } = useTheme();
  let color = 'none';
  let tooltipText = '';

  switch (resourceOffering) {
    case NodeResourceOffering.ON_DEMAND:
    case NodeType.ON_DEMAND:
      color = palette.blue[300]!;
      tooltipText = 'On-demand instance';
      break;
    case NodeResourceOffering.FALLBACK:
    case NodeType.FALLBACK:
      color = palette.yellow[400]!;
      tooltipText = 'Fallback instance';
      break;
    case NodeResourceOffering.SPOT:
    case NodeType.SPOT:
      color = palette.indigo[800]!;
      tooltipText = 'Spot instance';
      break;
  }

  return (
    <Tooltip title={tooltipText} placement="top">
      <StyledNodeIcon
        ref={ref}
        color={color}
        borderColor={problematic ? palette.red[400] : undefined}
        weight="fill"
        {...props}
      />
    </Tooltip>
  );
});

NodeResourceOfferingIcon.displayName = 'NodeResourceOfferingIcon';
