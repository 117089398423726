/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { NodeconfigV1GetSuggestedConfigurationResponse } from '../models';
// @ts-ignore
import type { NodeconfigV1ListConfigurationsResponse } from '../models';
// @ts-ignore
import type { NodeconfigV1ListMaxPodsPresetsResponse } from '../models';
// @ts-ignore
import type { NodeconfigV1NewNodeConfiguration } from '../models';
// @ts-ignore
import type { NodeconfigV1NodeConfiguration } from '../models';
// @ts-ignore
import type { NodeconfigV1NodeConfigurationUpdate } from '../models';
/**
 * NodeConfigurationAPIApi - axios parameter creator
 * @export
 */
export const NodeConfigurationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates new node configuration for a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {NodeconfigV1NewNodeConfiguration} config New node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPICreateConfiguration: async (clusterId: string, config: NodeconfigV1NewNodeConfiguration, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPICreateConfiguration', 'clusterId', clusterId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('nodeConfigurationAPICreateConfiguration', 'config', config)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes node configuration of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIDeleteConfiguration: async (clusterId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPIDeleteConfiguration', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeConfigurationAPIDeleteConfiguration', 'id', id)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cluster\'s node configuration.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIGetConfiguration: async (clusterId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPIGetConfiguration', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeConfigurationAPIGetConfiguration', 'id', id)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns suggested configuration for the cluster
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIGetSuggestedConfiguration: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPIGetSuggestedConfiguration', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations/suggestions`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists cluster\'s node configurations.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIListConfigurations: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPIListConfigurations', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of presets available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIListMaxPodsPresets: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/kubernetes/maxpods-formula-presets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks node configuration as default.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPISetDefault: async (clusterId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPISetDefault', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeConfigurationAPISetDefault', 'id', id)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations/{id}/default`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates configuration of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {NodeconfigV1NodeConfigurationUpdate} config Node configuration update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIUpdateConfiguration: async (clusterId: string, id: string, config: NodeconfigV1NodeConfigurationUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeConfigurationAPIUpdateConfiguration', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('nodeConfigurationAPIUpdateConfiguration', 'id', id)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('nodeConfigurationAPIUpdateConfiguration', 'config', config)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-configurations/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeConfigurationAPIApi - functional programming interface
 * @export
 */
export const NodeConfigurationAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeConfigurationAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates new node configuration for a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {NodeconfigV1NewNodeConfiguration} config New node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPICreateConfiguration(clusterId: string, config: NodeconfigV1NewNodeConfiguration, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1NodeConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPICreateConfiguration(clusterId, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPICreateConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes node configuration of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPIDeleteConfiguration(clusterId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPIDeleteConfiguration(clusterId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPIDeleteConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get cluster\'s node configuration.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPIGetConfiguration(clusterId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1NodeConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPIGetConfiguration(clusterId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPIGetConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns suggested configuration for the cluster
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPIGetSuggestedConfiguration(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1GetSuggestedConfigurationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPIGetSuggestedConfiguration(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPIGetSuggestedConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists cluster\'s node configurations.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPIListConfigurations(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1ListConfigurationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPIListConfigurations(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPIListConfigurations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of presets available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPIListMaxPodsPresets(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1ListMaxPodsPresetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPIListMaxPodsPresets(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPIListMaxPodsPresets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Marks node configuration as default.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPISetDefault(clusterId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1NodeConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPISetDefault(clusterId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPISetDefault']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates configuration of a specific cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {string} id The ID of the node configuration.
         * @param {NodeconfigV1NodeConfigurationUpdate} config Node configuration update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeConfigurationAPIUpdateConfiguration(clusterId: string, id: string, config: NodeconfigV1NodeConfigurationUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeconfigV1NodeConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeConfigurationAPIUpdateConfiguration(clusterId, id, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeConfigurationAPIApi.nodeConfigurationAPIUpdateConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NodeConfigurationAPIApi - factory interface
 * @export
 */
export const NodeConfigurationAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeConfigurationAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates new node configuration for a specific cluster.
         * @param {NodeConfigurationAPIApiNodeConfigurationAPICreateConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPICreateConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPICreateConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1NodeConfiguration> {
            return localVarFp.nodeConfigurationAPICreateConfiguration(requestParameters.clusterId, requestParameters.config, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes node configuration of a specific cluster.
         * @param {NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIDeleteConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.nodeConfigurationAPIDeleteConfiguration(requestParameters.clusterId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cluster\'s node configuration.
         * @param {NodeConfigurationAPIApiNodeConfigurationAPIGetConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIGetConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIGetConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1NodeConfiguration> {
            return localVarFp.nodeConfigurationAPIGetConfiguration(requestParameters.clusterId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns suggested configuration for the cluster
         * @param {NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIGetSuggestedConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1GetSuggestedConfigurationResponse> {
            return localVarFp.nodeConfigurationAPIGetSuggestedConfiguration(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists cluster\'s node configurations.
         * @param {NodeConfigurationAPIApiNodeConfigurationAPIListConfigurationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIListConfigurations(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIListConfigurationsRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1ListConfigurationsResponse> {
            return localVarFp.nodeConfigurationAPIListConfigurations(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of presets available.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIListMaxPodsPresets(options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1ListMaxPodsPresetsResponse> {
            return localVarFp.nodeConfigurationAPIListMaxPodsPresets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks node configuration as default.
         * @param {NodeConfigurationAPIApiNodeConfigurationAPISetDefaultRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPISetDefault(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPISetDefaultRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1NodeConfiguration> {
            return localVarFp.nodeConfigurationAPISetDefault(requestParameters.clusterId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates configuration of a specific cluster.
         * @param {NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfigurationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeConfigurationAPIUpdateConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfigurationRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodeconfigV1NodeConfiguration> {
            return localVarFp.nodeConfigurationAPIUpdateConfiguration(requestParameters.clusterId, requestParameters.id, requestParameters.config, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for nodeConfigurationAPICreateConfiguration operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPICreateConfigurationRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPICreateConfigurationRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPICreateConfiguration
     */
    readonly clusterId: string

    /**
     * New node configuration.
     * @type {NodeconfigV1NewNodeConfiguration}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPICreateConfiguration
     */
    readonly config: NodeconfigV1NewNodeConfiguration
}

/**
 * Request parameters for nodeConfigurationAPIDeleteConfiguration operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfigurationRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfigurationRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfiguration
     */
    readonly clusterId: string

    /**
     * The ID of the node configuration.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfiguration
     */
    readonly id: string
}

/**
 * Request parameters for nodeConfigurationAPIGetConfiguration operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPIGetConfigurationRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPIGetConfigurationRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIGetConfiguration
     */
    readonly clusterId: string

    /**
     * The ID of the node configuration.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIGetConfiguration
     */
    readonly id: string
}

/**
 * Request parameters for nodeConfigurationAPIGetSuggestedConfiguration operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfigurationRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfiguration
     */
    readonly clusterId: string
}

/**
 * Request parameters for nodeConfigurationAPIListConfigurations operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPIListConfigurationsRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPIListConfigurationsRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIListConfigurations
     */
    readonly clusterId: string
}

/**
 * Request parameters for nodeConfigurationAPISetDefault operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPISetDefaultRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPISetDefaultRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPISetDefault
     */
    readonly clusterId: string

    /**
     * The ID of the node configuration.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPISetDefault
     */
    readonly id: string
}

/**
 * Request parameters for nodeConfigurationAPIUpdateConfiguration operation in NodeConfigurationAPIApi.
 * @export
 * @interface NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfigurationRequest
 */
export interface NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfigurationRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfiguration
     */
    readonly clusterId: string

    /**
     * The ID of the node configuration.
     * @type {string}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfiguration
     */
    readonly id: string

    /**
     * Node configuration update.
     * @type {NodeconfigV1NodeConfigurationUpdate}
     * @memberof NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfiguration
     */
    readonly config: NodeconfigV1NodeConfigurationUpdate
}

/**
 * NodeConfigurationAPIApi - object-oriented interface
 * @export
 * @class NodeConfigurationAPIApi
 * @extends {BaseAPI}
 */
export class NodeConfigurationAPIApi extends BaseAPI {
    /**
     * 
     * @summary Creates new node configuration for a specific cluster.
     * @param {NodeConfigurationAPIApiNodeConfigurationAPICreateConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPICreateConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPICreateConfigurationRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPICreateConfiguration(requestParameters.clusterId, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes node configuration of a specific cluster.
     * @param {NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPIDeleteConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIDeleteConfigurationRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPIDeleteConfiguration(requestParameters.clusterId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cluster\'s node configuration.
     * @param {NodeConfigurationAPIApiNodeConfigurationAPIGetConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPIGetConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIGetConfigurationRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPIGetConfiguration(requestParameters.clusterId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns suggested configuration for the cluster
     * @param {NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPIGetSuggestedConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIGetSuggestedConfigurationRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPIGetSuggestedConfiguration(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists cluster\'s node configurations.
     * @param {NodeConfigurationAPIApiNodeConfigurationAPIListConfigurationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPIListConfigurations(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIListConfigurationsRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPIListConfigurations(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of presets available.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPIListMaxPodsPresets(options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPIListMaxPodsPresets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks node configuration as default.
     * @param {NodeConfigurationAPIApiNodeConfigurationAPISetDefaultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPISetDefault(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPISetDefaultRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPISetDefault(requestParameters.clusterId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates configuration of a specific cluster.
     * @param {NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfigurationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeConfigurationAPIApi
     */
    public nodeConfigurationAPIUpdateConfiguration(requestParameters: NodeConfigurationAPIApiNodeConfigurationAPIUpdateConfigurationRequest, options?: RawAxiosRequestConfig) {
        return NodeConfigurationAPIApiFp(this.configuration).nodeConfigurationAPIUpdateConfiguration(requestParameters.clusterId, requestParameters.id, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }
}

