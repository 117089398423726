/** A module. Its name is module:cast/nodes.
 * @module cast/nodes
 */

import type {
  CastaiAutoscalerV1beta1GetProblematicNodesResponseNode as ProblematicNode,
  ExternalclusterV1Node as _NodeResponse,
} from '@cast/openapi';
import { ExternalclusterV1NodeType as NodeRoles } from '@cast/openapi';

export enum NodeStatus {
  unknown = 'unknown',
  pending = 'pending',
  creating = 'creating',
  ready = 'ready',
  notReady = 'notReady',
  draining = 'draining',
  deleting = 'deleting',
  deleted = 'deleted',
  interrupted = 'interrupted',
}

export enum ComputedNodeStatus {
  CORDONED = 'cordoned',
}

export enum NodeResourceOffering {
  ON_DEMAND = 'on-demand',
  FALLBACK = 'fallback',
  SPOT = 'spot',
}

export type Node = Omit<NodeResponse, 'state'> & {
  state: {
    phase?: NodeStatus | ComputedNodeStatus;
  };
  resourceOffering: NodeResourceOffering;
  unremovable: boolean;
  castNodeId?: string;
  isFailedToDrain: boolean;
};

export type NodeResponse = Omit<_NodeResponse, 'instancePrice'> & {
  /** @deprecated Use `pricingInfo.totalPrice` instead */
  instancePrice: _NodeResponse['instancePrice'];
};

export type { ExternalclusterV1Taint as NodeTaint } from '@cast/openapi';
export { NodeRoles, ProblematicNode };
