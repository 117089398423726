import { useInfiniteQuery } from '@tanstack/react-query';
import flatMap from 'lodash/flatMap';

import { RebalancingPlan, RebalancingPlanStatusEnum } from '@cast/types';

import { apiClient } from 'core/api-client';
import { QueryKeys } from 'core/react-query';

type UseRebalancePlansQuery = {
  isLoading: boolean;
  rebalancePlans?: RebalancingPlan[];
  activeRebalancingPlanId?: string;
  error?: unknown;
  refetch?: () => void;
  fetchNextPage?: () => void;
  isFetching?: boolean;
  hasNextPage?: boolean;
};

type UseRebalancePlansQueryProps = {
  clusterId?: string;
  disabled?: boolean;
  limit?: number;
  includeStatuses?: RebalancingPlanStatusEnum[];
  includeOperations?: boolean;
  includeConfigurations?: boolean;
  includeNegative?: boolean;
};

export const useRebalancePlansQuery = ({
  clusterId,
  disabled,
  limit = 20,
  includeStatuses,
  includeOperations = false,
  includeConfigurations = false,
  includeNegative = true,
}: UseRebalancePlansQueryProps): UseRebalancePlansQuery => {
  const {
    isLoading,
    data,
    error,
    refetch,
    fetchNextPage,
    isFetching,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      QueryKeys.EXTERNAL_CLUSTER_REBALANCE_PLAN,
      clusterId,
      limit,
      includeStatuses,
      includeOperations,
      includeConfigurations,
      includeNegative,
    ],
    queryFn: async ({ pageParam }) => {
      const { data } = await apiClient.autoscaler.listRebalancingPlans({
        clusterId: clusterId!,
        limit: String(limit),
        cursor: pageParam,
        filterStatuses: includeStatuses,
        includeOperations,
        includeConfigurations,
        includeNegative,
      });
      return data;
    },
    enabled: !!clusterId && !disabled,
    refetchInterval: 20000,
    initialPageParam: '',
    getNextPageParam: (lastPage) => {
      return lastPage.nextCursor || undefined;
    },
  });

  const pages = data?.pages;
  const rebalancePlans = pages
    ? flatMap(pages, ({ items }) => items as RebalancingPlan[])
    : [];

  return {
    isLoading,
    rebalancePlans,
    error,
    refetch,
    fetchNextPage,
    isFetching,
    hasNextPage,
  };
};
