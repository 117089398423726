/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { NodetemplatesV1FilterInstanceTypesResponse } from '../models';
// @ts-ignore
import type { NodetemplatesV1GenerateNodeTemplatesResponse } from '../models';
// @ts-ignore
import type { NodetemplatesV1ListNodeTemplatesResponse } from '../models';
// @ts-ignore
import type { NodetemplatesV1NewNodeTemplate } from '../models';
// @ts-ignore
import type { NodetemplatesV1NodeTemplate } from '../models';
// @ts-ignore
import type { NodetemplatesV1UpdateNodeTemplate } from '../models';
/**
 * NodeTemplatesAPIApi - axios parameter creator
 * @export
 */
export const NodeTemplatesAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a node template to be used in autoscaling.
         * @summary Create node template
         * @param {string} clusterId ID of the cluster that the template is for
         * @param {NodetemplatesV1NewNodeTemplate} nodeTemplate NodeTemplate to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPICreateNodeTemplate: async (clusterId: string, nodeTemplate: NodetemplatesV1NewNodeTemplate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeTemplatesAPICreateNodeTemplate', 'clusterId', clusterId)
            // verify required parameter 'nodeTemplate' is not null or undefined
            assertParamExists('nodeTemplatesAPICreateNodeTemplate', 'nodeTemplate', nodeTemplate)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-templates`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a node template.
         * @summary Delete node template
         * @param {string} clusterId ID of the cluster that the template is for
         * @param {string} nodeTemplateName The name of the template to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIDeleteNodeTemplate: async (clusterId: string, nodeTemplateName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeTemplatesAPIDeleteNodeTemplate', 'clusterId', clusterId)
            // verify required parameter 'nodeTemplateName' is not null or undefined
            assertParamExists('nodeTemplatesAPIDeleteNodeTemplate', 'nodeTemplateName', nodeTemplateName)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-templates/{nodeTemplateName}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeTemplateName"}}`, encodeURIComponent(String(nodeTemplateName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists instance types based on the provided policy.
         * @summary Filter instance types
         * @param {string} clusterId ID of the cluster
         * @param {NodetemplatesV1NodeTemplate} filter Filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIFilterInstanceTypes: async (clusterId: string, filter: NodetemplatesV1NodeTemplate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeTemplatesAPIFilterInstanceTypes', 'clusterId', clusterId)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('nodeTemplatesAPIFilterInstanceTypes', 'filter', filter)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/filter-instance-types`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates node templates from the latest snapshot
         * @summary Generate node templates
         * @param {string} clusterId ID of the cluster to generate templates for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIGenerateNodeTemplates: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeTemplatesAPIGenerateNodeTemplates', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/generate-node-templates`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists active node templates.
         * @summary List node templates
         * @param {string} clusterId ID of the cluster to list templates for
         * @param {boolean} [includeDefault] Flag whether to include the default template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIListNodeTemplates: async (clusterId: string, includeDefault?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeTemplatesAPIListNodeTemplates', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-templates`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeDefault !== undefined) {
                localVarQueryParameter['includeDefault'] = includeDefault;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a node template.
         * @summary Update node template
         * @param {string} clusterId The ID of the cluster that the template is for
         * @param {string} nodeTemplateName The name of the node template to update
         * @param {NodetemplatesV1UpdateNodeTemplate} nodeTemplate The node template to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIUpdateNodeTemplate: async (clusterId: string, nodeTemplateName: string, nodeTemplate: NodetemplatesV1UpdateNodeTemplate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('nodeTemplatesAPIUpdateNodeTemplate', 'clusterId', clusterId)
            // verify required parameter 'nodeTemplateName' is not null or undefined
            assertParamExists('nodeTemplatesAPIUpdateNodeTemplate', 'nodeTemplateName', nodeTemplateName)
            // verify required parameter 'nodeTemplate' is not null or undefined
            assertParamExists('nodeTemplatesAPIUpdateNodeTemplate', 'nodeTemplate', nodeTemplate)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-templates/{nodeTemplateName}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeTemplateName"}}`, encodeURIComponent(String(nodeTemplateName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeTemplatesAPIApi - functional programming interface
 * @export
 */
export const NodeTemplatesAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeTemplatesAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a node template to be used in autoscaling.
         * @summary Create node template
         * @param {string} clusterId ID of the cluster that the template is for
         * @param {NodetemplatesV1NewNodeTemplate} nodeTemplate NodeTemplate to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTemplatesAPICreateNodeTemplate(clusterId: string, nodeTemplate: NodetemplatesV1NewNodeTemplate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodetemplatesV1NodeTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeTemplatesAPICreateNodeTemplate(clusterId, nodeTemplate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeTemplatesAPIApi.nodeTemplatesAPICreateNodeTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a node template.
         * @summary Delete node template
         * @param {string} clusterId ID of the cluster that the template is for
         * @param {string} nodeTemplateName The name of the template to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTemplatesAPIDeleteNodeTemplate(clusterId: string, nodeTemplateName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeTemplatesAPIDeleteNodeTemplate(clusterId, nodeTemplateName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeTemplatesAPIApi.nodeTemplatesAPIDeleteNodeTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists instance types based on the provided policy.
         * @summary Filter instance types
         * @param {string} clusterId ID of the cluster
         * @param {NodetemplatesV1NodeTemplate} filter Filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTemplatesAPIFilterInstanceTypes(clusterId: string, filter: NodetemplatesV1NodeTemplate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodetemplatesV1FilterInstanceTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeTemplatesAPIFilterInstanceTypes(clusterId, filter, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeTemplatesAPIApi.nodeTemplatesAPIFilterInstanceTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generates node templates from the latest snapshot
         * @summary Generate node templates
         * @param {string} clusterId ID of the cluster to generate templates for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTemplatesAPIGenerateNodeTemplates(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodetemplatesV1GenerateNodeTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeTemplatesAPIGenerateNodeTemplates(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeTemplatesAPIApi.nodeTemplatesAPIGenerateNodeTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists active node templates.
         * @summary List node templates
         * @param {string} clusterId ID of the cluster to list templates for
         * @param {boolean} [includeDefault] Flag whether to include the default template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTemplatesAPIListNodeTemplates(clusterId: string, includeDefault?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodetemplatesV1ListNodeTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeTemplatesAPIListNodeTemplates(clusterId, includeDefault, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeTemplatesAPIApi.nodeTemplatesAPIListNodeTemplates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates a node template.
         * @summary Update node template
         * @param {string} clusterId The ID of the cluster that the template is for
         * @param {string} nodeTemplateName The name of the node template to update
         * @param {NodetemplatesV1UpdateNodeTemplate} nodeTemplate The node template to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodeTemplatesAPIUpdateNodeTemplate(clusterId: string, nodeTemplateName: string, nodeTemplate: NodetemplatesV1UpdateNodeTemplate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodetemplatesV1NodeTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodeTemplatesAPIUpdateNodeTemplate(clusterId, nodeTemplateName, nodeTemplate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NodeTemplatesAPIApi.nodeTemplatesAPIUpdateNodeTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NodeTemplatesAPIApi - factory interface
 * @export
 */
export const NodeTemplatesAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeTemplatesAPIApiFp(configuration)
    return {
        /**
         * Creates a node template to be used in autoscaling.
         * @summary Create node template
         * @param {NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPICreateNodeTemplate(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodetemplatesV1NodeTemplate> {
            return localVarFp.nodeTemplatesAPICreateNodeTemplate(requestParameters.clusterId, requestParameters.nodeTemplate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a node template.
         * @summary Delete node template
         * @param {NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIDeleteNodeTemplate(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.nodeTemplatesAPIDeleteNodeTemplate(requestParameters.clusterId, requestParameters.nodeTemplateName, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists instance types based on the provided policy.
         * @summary Filter instance types
         * @param {NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIFilterInstanceTypes(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypesRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodetemplatesV1FilterInstanceTypesResponse> {
            return localVarFp.nodeTemplatesAPIFilterInstanceTypes(requestParameters.clusterId, requestParameters.filter, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates node templates from the latest snapshot
         * @summary Generate node templates
         * @param {NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIGenerateNodeTemplates(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodetemplatesV1GenerateNodeTemplatesResponse> {
            return localVarFp.nodeTemplatesAPIGenerateNodeTemplates(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists active node templates.
         * @summary List node templates
         * @param {NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIListNodeTemplates(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodetemplatesV1ListNodeTemplatesResponse> {
            return localVarFp.nodeTemplatesAPIListNodeTemplates(requestParameters.clusterId, requestParameters.includeDefault, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a node template.
         * @summary Update node template
         * @param {NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodeTemplatesAPIUpdateNodeTemplate(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplateRequest, options?: RawAxiosRequestConfig): AxiosPromise<NodetemplatesV1NodeTemplate> {
            return localVarFp.nodeTemplatesAPIUpdateNodeTemplate(requestParameters.clusterId, requestParameters.nodeTemplateName, requestParameters.nodeTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for nodeTemplatesAPICreateNodeTemplate operation in NodeTemplatesAPIApi.
 * @export
 * @interface NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplateRequest
 */
export interface NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplateRequest {
    /**
     * ID of the cluster that the template is for
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplate
     */
    readonly clusterId: string

    /**
     * NodeTemplate to create
     * @type {NodetemplatesV1NewNodeTemplate}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplate
     */
    readonly nodeTemplate: NodetemplatesV1NewNodeTemplate
}

/**
 * Request parameters for nodeTemplatesAPIDeleteNodeTemplate operation in NodeTemplatesAPIApi.
 * @export
 * @interface NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplateRequest
 */
export interface NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplateRequest {
    /**
     * ID of the cluster that the template is for
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplate
     */
    readonly clusterId: string

    /**
     * The name of the template to delete
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplate
     */
    readonly nodeTemplateName: string
}

/**
 * Request parameters for nodeTemplatesAPIFilterInstanceTypes operation in NodeTemplatesAPIApi.
 * @export
 * @interface NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypesRequest
 */
export interface NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypesRequest {
    /**
     * ID of the cluster
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypes
     */
    readonly clusterId: string

    /**
     * Filter
     * @type {NodetemplatesV1NodeTemplate}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypes
     */
    readonly filter: NodetemplatesV1NodeTemplate
}

/**
 * Request parameters for nodeTemplatesAPIGenerateNodeTemplates operation in NodeTemplatesAPIApi.
 * @export
 * @interface NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplatesRequest
 */
export interface NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplatesRequest {
    /**
     * ID of the cluster to generate templates for
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplates
     */
    readonly clusterId: string
}

/**
 * Request parameters for nodeTemplatesAPIListNodeTemplates operation in NodeTemplatesAPIApi.
 * @export
 * @interface NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplatesRequest
 */
export interface NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplatesRequest {
    /**
     * ID of the cluster to list templates for
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplates
     */
    readonly clusterId: string

    /**
     * Flag whether to include the default template
     * @type {boolean}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplates
     */
    readonly includeDefault?: boolean
}

/**
 * Request parameters for nodeTemplatesAPIUpdateNodeTemplate operation in NodeTemplatesAPIApi.
 * @export
 * @interface NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplateRequest
 */
export interface NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplateRequest {
    /**
     * The ID of the cluster that the template is for
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplate
     */
    readonly clusterId: string

    /**
     * The name of the node template to update
     * @type {string}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplate
     */
    readonly nodeTemplateName: string

    /**
     * The node template to update
     * @type {NodetemplatesV1UpdateNodeTemplate}
     * @memberof NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplate
     */
    readonly nodeTemplate: NodetemplatesV1UpdateNodeTemplate
}

/**
 * NodeTemplatesAPIApi - object-oriented interface
 * @export
 * @class NodeTemplatesAPIApi
 * @extends {BaseAPI}
 */
export class NodeTemplatesAPIApi extends BaseAPI {
    /**
     * Creates a node template to be used in autoscaling.
     * @summary Create node template
     * @param {NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTemplatesAPIApi
     */
    public nodeTemplatesAPICreateNodeTemplate(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPICreateNodeTemplateRequest, options?: RawAxiosRequestConfig) {
        return NodeTemplatesAPIApiFp(this.configuration).nodeTemplatesAPICreateNodeTemplate(requestParameters.clusterId, requestParameters.nodeTemplate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a node template.
     * @summary Delete node template
     * @param {NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTemplatesAPIApi
     */
    public nodeTemplatesAPIDeleteNodeTemplate(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIDeleteNodeTemplateRequest, options?: RawAxiosRequestConfig) {
        return NodeTemplatesAPIApiFp(this.configuration).nodeTemplatesAPIDeleteNodeTemplate(requestParameters.clusterId, requestParameters.nodeTemplateName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists instance types based on the provided policy.
     * @summary Filter instance types
     * @param {NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTemplatesAPIApi
     */
    public nodeTemplatesAPIFilterInstanceTypes(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIFilterInstanceTypesRequest, options?: RawAxiosRequestConfig) {
        return NodeTemplatesAPIApiFp(this.configuration).nodeTemplatesAPIFilterInstanceTypes(requestParameters.clusterId, requestParameters.filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates node templates from the latest snapshot
     * @summary Generate node templates
     * @param {NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTemplatesAPIApi
     */
    public nodeTemplatesAPIGenerateNodeTemplates(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIGenerateNodeTemplatesRequest, options?: RawAxiosRequestConfig) {
        return NodeTemplatesAPIApiFp(this.configuration).nodeTemplatesAPIGenerateNodeTemplates(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists active node templates.
     * @summary List node templates
     * @param {NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTemplatesAPIApi
     */
    public nodeTemplatesAPIListNodeTemplates(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIListNodeTemplatesRequest, options?: RawAxiosRequestConfig) {
        return NodeTemplatesAPIApiFp(this.configuration).nodeTemplatesAPIListNodeTemplates(requestParameters.clusterId, requestParameters.includeDefault, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a node template.
     * @summary Update node template
     * @param {NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTemplatesAPIApi
     */
    public nodeTemplatesAPIUpdateNodeTemplate(requestParameters: NodeTemplatesAPIApiNodeTemplatesAPIUpdateNodeTemplateRequest, options?: RawAxiosRequestConfig) {
        return NodeTemplatesAPIApiFp(this.configuration).nodeTemplatesAPIUpdateNodeTemplate(requestParameters.clusterId, requestParameters.nodeTemplateName, requestParameters.nodeTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}

