/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CostreportV1beta1GetEgressdScriptResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetGpuMetricsExporterScriptResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetGroupingConfigResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetReportingCapabilitiesResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetWorkloadReportingCapabilitiesResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GroupingConfig } from '../models';
// @ts-ignore
import type { CostreportV1beta1UpsertGroupingConfigResponse } from '../models';
/**
 * ReportConfigurationAPIApi - axios parameter creator
 * @export
 */
export const ReportConfigurationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns single command for external cluster with egressd installation script.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetEgressdScript: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('reportConfigurationAPIGetEgressdScript', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/egressd-script`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns egressd installation script template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetEgressdScriptTemplate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/scripts/egressd/install.sh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns single command for external cluster with gpu-metrics-exporter installation script.
         * @param {string} clusterId The ID of the cluster that will install gpu-metrics-exporter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetGpuMetricsExporterScript: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('reportConfigurationAPIGetGpuMetricsExporterScript', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/gpu-metrics-exporter-script`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns gpu-metrics-exporter installation script template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetGpuMetricsExporterScriptTemplate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/scripts/gpu-metrics-exporter/install.sh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workload grouping config.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetGroupingConfig: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('reportConfigurationAPIGetGroupingConfig', 'clusterId', clusterId)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/grouping-config`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns information about the report capabilities for a specific cluster, e.g. if GPU is collected
         * @param {string} clusterId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetReportingCapabilities: async (clusterId: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('reportConfigurationAPIGetReportingCapabilities', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('reportConfigurationAPIGetReportingCapabilities', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('reportConfigurationAPIGetReportingCapabilities', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/reporting-capabilities`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns information about the report capabilities for a specific workload, e.g. if GPU is collected
         * @param {string} clusterId Workload cluster ID
         * @param {string} namespace Namespace the workload is in.
         * @param {string} workloadType Type of the workload.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetWorkloadReportingCapabilities: async (clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('reportConfigurationAPIGetWorkloadReportingCapabilities', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('reportConfigurationAPIGetWorkloadReportingCapabilities', 'namespace', namespace)
            // verify required parameter 'workloadType' is not null or undefined
            assertParamExists('reportConfigurationAPIGetWorkloadReportingCapabilities', 'workloadType', workloadType)
            // verify required parameter 'workloadName' is not null or undefined
            assertParamExists('reportConfigurationAPIGetWorkloadReportingCapabilities', 'workloadName', workloadName)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('reportConfigurationAPIGetWorkloadReportingCapabilities', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('reportConfigurationAPIGetWorkloadReportingCapabilities', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}/{workloadType}/{workloadName}/reporting-capabilities`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)))
                .replace(`{${"workloadType"}}`, encodeURIComponent(String(workloadType)))
                .replace(`{${"workloadName"}}`, encodeURIComponent(String(workloadName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upserts workload grouping config.
         * @param {string} clusterId ID of the cluster.
         * @param {CostreportV1beta1GroupingConfig} config Grouping config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIUpsertGroupingConfig: async (clusterId: string, config: CostreportV1beta1GroupingConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('reportConfigurationAPIUpsertGroupingConfig', 'clusterId', clusterId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('reportConfigurationAPIUpsertGroupingConfig', 'config', config)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/grouping-config`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportConfigurationAPIApi - functional programming interface
 * @export
 */
export const ReportConfigurationAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportConfigurationAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns single command for external cluster with egressd installation script.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetEgressdScript(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetEgressdScriptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetEgressdScript(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetEgressdScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns egressd installation script template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetEgressdScriptTemplate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetEgressdScriptTemplate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetEgressdScriptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns single command for external cluster with gpu-metrics-exporter installation script.
         * @param {string} clusterId The ID of the cluster that will install gpu-metrics-exporter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetGpuMetricsExporterScript(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetGpuMetricsExporterScriptResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetGpuMetricsExporterScript(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetGpuMetricsExporterScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns gpu-metrics-exporter installation script template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetGpuMetricsExporterScriptTemplate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetGpuMetricsExporterScriptTemplate(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetGpuMetricsExporterScriptTemplate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workload grouping config.
         * @param {string} clusterId ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetGroupingConfig(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetGroupingConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetGroupingConfig(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetGroupingConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns information about the report capabilities for a specific cluster, e.g. if GPU is collected
         * @param {string} clusterId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetReportingCapabilities(clusterId: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetReportingCapabilitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetReportingCapabilities(clusterId, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetReportingCapabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns information about the report capabilities for a specific workload, e.g. if GPU is collected
         * @param {string} clusterId Workload cluster ID
         * @param {string} namespace Namespace the workload is in.
         * @param {string} workloadType Type of the workload.
         * @param {string} workloadName Name of the workload.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIGetWorkloadReportingCapabilities(clusterId: string, namespace: string, workloadType: string, workloadName: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetWorkloadReportingCapabilitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIGetWorkloadReportingCapabilities(clusterId, namespace, workloadType, workloadName, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIGetWorkloadReportingCapabilities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upserts workload grouping config.
         * @param {string} clusterId ID of the cluster.
         * @param {CostreportV1beta1GroupingConfig} config Grouping config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportConfigurationAPIUpsertGroupingConfig(clusterId: string, config: CostreportV1beta1GroupingConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1UpsertGroupingConfigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportConfigurationAPIUpsertGroupingConfig(clusterId, config, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReportConfigurationAPIApi.reportConfigurationAPIUpsertGroupingConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReportConfigurationAPIApi - factory interface
 * @export
 */
export const ReportConfigurationAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportConfigurationAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns single command for external cluster with egressd installation script.
         * @param {ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetEgressdScript(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScriptRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetEgressdScriptResponse> {
            return localVarFp.reportConfigurationAPIGetEgressdScript(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns egressd installation script template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetEgressdScriptTemplate(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.reportConfigurationAPIGetEgressdScriptTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns single command for external cluster with gpu-metrics-exporter installation script.
         * @param {ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScriptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetGpuMetricsExporterScript(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScriptRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetGpuMetricsExporterScriptResponse> {
            return localVarFp.reportConfigurationAPIGetGpuMetricsExporterScript(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns gpu-metrics-exporter installation script template.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetGpuMetricsExporterScriptTemplate(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.reportConfigurationAPIGetGpuMetricsExporterScriptTemplate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workload grouping config.
         * @param {ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetGroupingConfig(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetGroupingConfigResponse> {
            return localVarFp.reportConfigurationAPIGetGroupingConfig(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns information about the report capabilities for a specific cluster, e.g. if GPU is collected
         * @param {ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetReportingCapabilities(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetReportingCapabilitiesResponse> {
            return localVarFp.reportConfigurationAPIGetReportingCapabilities(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns information about the report capabilities for a specific workload, e.g. if GPU is collected
         * @param {ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIGetWorkloadReportingCapabilities(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetWorkloadReportingCapabilitiesResponse> {
            return localVarFp.reportConfigurationAPIGetWorkloadReportingCapabilities(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upserts workload grouping config.
         * @param {ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfigRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportConfigurationAPIUpsertGroupingConfig(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfigRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1UpsertGroupingConfigResponse> {
            return localVarFp.reportConfigurationAPIUpsertGroupingConfig(requestParameters.clusterId, requestParameters.config, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for reportConfigurationAPIGetEgressdScript operation in ReportConfigurationAPIApi.
 * @export
 * @interface ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScriptRequest
 */
export interface ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScriptRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScript
     */
    readonly clusterId: string
}

/**
 * Request parameters for reportConfigurationAPIGetGpuMetricsExporterScript operation in ReportConfigurationAPIApi.
 * @export
 * @interface ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScriptRequest
 */
export interface ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScriptRequest {
    /**
     * The ID of the cluster that will install gpu-metrics-exporter.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScript
     */
    readonly clusterId: string
}

/**
 * Request parameters for reportConfigurationAPIGetGroupingConfig operation in ReportConfigurationAPIApi.
 * @export
 * @interface ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfigRequest
 */
export interface ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfigRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfig
     */
    readonly clusterId: string
}

/**
 * Request parameters for reportConfigurationAPIGetReportingCapabilities operation in ReportConfigurationAPIApi.
 * @export
 * @interface ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilitiesRequest
 */
export interface ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilities
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilities
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilities
     */
    readonly endTime: string
}

/**
 * Request parameters for reportConfigurationAPIGetWorkloadReportingCapabilities operation in ReportConfigurationAPIApi.
 * @export
 * @interface ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilitiesRequest
 */
export interface ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilitiesRequest {
    /**
     * Workload cluster ID
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilities
     */
    readonly clusterId: string

    /**
     * Namespace the workload is in.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilities
     */
    readonly namespace: string

    /**
     * Type of the workload.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilities
     */
    readonly workloadType: string

    /**
     * Name of the workload.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilities
     */
    readonly workloadName: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilities
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilities
     */
    readonly endTime: string
}

/**
 * Request parameters for reportConfigurationAPIUpsertGroupingConfig operation in ReportConfigurationAPIApi.
 * @export
 * @interface ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfigRequest
 */
export interface ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfigRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfig
     */
    readonly clusterId: string

    /**
     * Grouping config.
     * @type {CostreportV1beta1GroupingConfig}
     * @memberof ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfig
     */
    readonly config: CostreportV1beta1GroupingConfig
}

/**
 * ReportConfigurationAPIApi - object-oriented interface
 * @export
 * @class ReportConfigurationAPIApi
 * @extends {BaseAPI}
 */
export class ReportConfigurationAPIApi extends BaseAPI {
    /**
     * 
     * @summary Returns single command for external cluster with egressd installation script.
     * @param {ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetEgressdScript(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetEgressdScriptRequest, options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetEgressdScript(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns egressd installation script template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetEgressdScriptTemplate(options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetEgressdScriptTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns single command for external cluster with gpu-metrics-exporter installation script.
     * @param {ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScriptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetGpuMetricsExporterScript(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetGpuMetricsExporterScriptRequest, options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetGpuMetricsExporterScript(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns gpu-metrics-exporter installation script template.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetGpuMetricsExporterScriptTemplate(options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetGpuMetricsExporterScriptTemplate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workload grouping config.
     * @param {ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetGroupingConfig(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetGroupingConfigRequest, options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetGroupingConfig(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns information about the report capabilities for a specific cluster, e.g. if GPU is collected
     * @param {ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetReportingCapabilities(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetReportingCapabilitiesRequest, options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetReportingCapabilities(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns information about the report capabilities for a specific workload, e.g. if GPU is collected
     * @param {ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIGetWorkloadReportingCapabilities(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIGetWorkloadReportingCapabilitiesRequest, options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIGetWorkloadReportingCapabilities(requestParameters.clusterId, requestParameters.namespace, requestParameters.workloadType, requestParameters.workloadName, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upserts workload grouping config.
     * @param {ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportConfigurationAPIApi
     */
    public reportConfigurationAPIUpsertGroupingConfig(requestParameters: ReportConfigurationAPIApiReportConfigurationAPIUpsertGroupingConfigRequest, options?: RawAxiosRequestConfig) {
        return ReportConfigurationAPIApiFp(this.configuration).reportConfigurationAPIUpsertGroupingConfig(requestParameters.clusterId, requestParameters.config, options).then((request) => request(this.axios, this.basePath));
    }
}

