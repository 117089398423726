import { AxiosInstance } from 'axios';

import { DboAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const DboApiAdapter = (client: AxiosInstance) => {
  const generatedApi = DboAPIApiFactory(AXIOS_CONFIGURATION, '', client);

  return {
    getDbInstances: generatedApi.dboAPIListDatabaseInstances,
    getDbInstance: generatedApi.dboAPIGetDatabaseInstance,
    getCacheConfigurations: generatedApi.dboAPIListCacheConfigurations,
    getCachingSummary: generatedApi.dboAPIGetCacheSummary,
    getCachingRules: generatedApi.dboAPIListCacheTTLs,
    createCachingRule: generatedApi.dboAPICreateCacheTTL,
    updateCachingRule: generatedApi.dboAPIUpdateCacheTTL,
    deleteCachingRule: generatedApi.dboAPIDeleteCacheTTL,
    getCachingQueries: generatedApi.dboAPIGetCacheQueries,
    createRegistration: generatedApi.dboAPICreateRegistration,
    getInstanceRegistrationStatus: generatedApi.dboAPIGetRegistrationStatus,
    updateCacheConfiguration: generatedApi.dboAPIUpdateCacheConfiguration,
    getAccounts: generatedApi.dboAPIListAccounts,
  };
};
