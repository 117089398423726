/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { HostedModel } from '../models';
// @ts-ignore
import type { ListHostedModelsResponse } from '../models';
// @ts-ignore
import type { ScaleHostedModelRequest } from '../models';
// @ts-ignore
import type { ScaleHostedModelResponse } from '../models';
// @ts-ignore
import type { Status } from '../models';
/**
 * HostedModelsAPIApi - axios parameter creator
 * @export
 */
export const HostedModelsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deploys a model on a CAST AI managed cluster.
         * @summary Create hosted model.
         * @param {string} organizationId The ID of the Organization.
         * @param {string} hostedModelClusterId 
         * @param {HostedModel} hostedModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPICreateHostedModel: async (organizationId: string, hostedModelClusterId: string, hostedModel: HostedModel, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('hostedModelsAPICreateHostedModel', 'organizationId', organizationId)
            // verify required parameter 'hostedModelClusterId' is not null or undefined
            assertParamExists('hostedModelsAPICreateHostedModel', 'hostedModelClusterId', hostedModelClusterId)
            // verify required parameter 'hostedModel' is not null or undefined
            assertParamExists('hostedModelsAPICreateHostedModel', 'hostedModel', hostedModel)
            const localVarPath = `/ai-optimizer/v1beta/organizations/{organizationId}/clusters/{hosted_model.cluster_id}/hosted-models`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"hosted_model.cluster_id"}}`, encodeURIComponent(String(hostedModelClusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(hostedModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a hosted model deployment.
         * @summary Delete hosted model deployment.
         * @param {string} organizationId The ID of the Organization.
         * @param {string} clusterId The ID of the Cluster.
         * @param {string} id The ID of the hosted model deployment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPIDeleteHostedModel: async (organizationId: string, clusterId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('hostedModelsAPIDeleteHostedModel', 'organizationId', organizationId)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('hostedModelsAPIDeleteHostedModel', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hostedModelsAPIDeleteHostedModel', 'id', id)
            const localVarPath = `/ai-optimizer/v1beta/organizations/{organizationId}/clusters/{clusterId}/hosted-models/{id}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the list of hosted models for given organization.  Supported sort fields are: model, createTime.  Max page limit is 1000.
         * @summary List hosted models.
         * @param {string} organizationId The ID of the organization.
         * @param {string} clusterId The ID of the cluster the model is deployed on. Use \&quot;-\&quot; placeholder to list all models across all clusters.
         * @param {string} [pageLimit] Defines the number of items that should be returned
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] The field name to order by. Nested fields can be specified by using a dot (e.g. user.name).
         * @param {HostedModelsAPIListHostedModelsSortOrderEnum} [sortOrder] The order of returned items. Default is ASC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPIListHostedModels: async (organizationId: string, clusterId: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: HostedModelsAPIListHostedModelsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('hostedModelsAPIListHostedModels', 'organizationId', organizationId)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('hostedModelsAPIListHostedModels', 'clusterId', clusterId)
            const localVarPath = `/ai-optimizer/v1beta/organizations/{organizationId}/clusters/{clusterId}/hosted-models`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Scales the hosted model deployment.
         * @summary Scale the hosted model deployment.
         * @param {string} organizationId The ID of the Organization.
         * @param {string} clusterId The ID of the Cluster.
         * @param {string} id The ID of the hosted model deployment.
         * @param {ScaleHostedModelRequest} scaleHostedModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPIScaleHostedModel: async (organizationId: string, clusterId: string, id: string, scaleHostedModelRequest: ScaleHostedModelRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('hostedModelsAPIScaleHostedModel', 'organizationId', organizationId)
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('hostedModelsAPIScaleHostedModel', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hostedModelsAPIScaleHostedModel', 'id', id)
            // verify required parameter 'scaleHostedModelRequest' is not null or undefined
            assertParamExists('hostedModelsAPIScaleHostedModel', 'scaleHostedModelRequest', scaleHostedModelRequest)
            const localVarPath = `/ai-optimizer/v1beta/organizations/{organizationId}/clusters/{clusterId}/hosted-models/{id}:scale`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scaleHostedModelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HostedModelsAPIApi - functional programming interface
 * @export
 */
export const HostedModelsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HostedModelsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Deploys a model on a CAST AI managed cluster.
         * @summary Create hosted model.
         * @param {string} organizationId The ID of the Organization.
         * @param {string} hostedModelClusterId 
         * @param {HostedModel} hostedModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hostedModelsAPICreateHostedModel(organizationId: string, hostedModelClusterId: string, hostedModel: HostedModel, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HostedModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hostedModelsAPICreateHostedModel(organizationId, hostedModelClusterId, hostedModel, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HostedModelsAPIApi.hostedModelsAPICreateHostedModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a hosted model deployment.
         * @summary Delete hosted model deployment.
         * @param {string} organizationId The ID of the Organization.
         * @param {string} clusterId The ID of the Cluster.
         * @param {string} id The ID of the hosted model deployment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hostedModelsAPIDeleteHostedModel(organizationId: string, clusterId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hostedModelsAPIDeleteHostedModel(organizationId, clusterId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HostedModelsAPIApi.hostedModelsAPIDeleteHostedModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets the list of hosted models for given organization.  Supported sort fields are: model, createTime.  Max page limit is 1000.
         * @summary List hosted models.
         * @param {string} organizationId The ID of the organization.
         * @param {string} clusterId The ID of the cluster the model is deployed on. Use \&quot;-\&quot; placeholder to list all models across all clusters.
         * @param {string} [pageLimit] Defines the number of items that should be returned
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] The field name to order by. Nested fields can be specified by using a dot (e.g. user.name).
         * @param {HostedModelsAPIListHostedModelsSortOrderEnum} [sortOrder] The order of returned items. Default is ASC.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hostedModelsAPIListHostedModels(organizationId: string, clusterId: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: HostedModelsAPIListHostedModelsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListHostedModelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hostedModelsAPIListHostedModels(organizationId, clusterId, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HostedModelsAPIApi.hostedModelsAPIListHostedModels']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Scales the hosted model deployment.
         * @summary Scale the hosted model deployment.
         * @param {string} organizationId The ID of the Organization.
         * @param {string} clusterId The ID of the Cluster.
         * @param {string} id The ID of the hosted model deployment.
         * @param {ScaleHostedModelRequest} scaleHostedModelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hostedModelsAPIScaleHostedModel(organizationId: string, clusterId: string, id: string, scaleHostedModelRequest: ScaleHostedModelRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScaleHostedModelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hostedModelsAPIScaleHostedModel(organizationId, clusterId, id, scaleHostedModelRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HostedModelsAPIApi.hostedModelsAPIScaleHostedModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HostedModelsAPIApi - factory interface
 * @export
 */
export const HostedModelsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HostedModelsAPIApiFp(configuration)
    return {
        /**
         * Deploys a model on a CAST AI managed cluster.
         * @summary Create hosted model.
         * @param {HostedModelsAPIApiHostedModelsAPICreateHostedModelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPICreateHostedModel(requestParameters: HostedModelsAPIApiHostedModelsAPICreateHostedModelRequest, options?: RawAxiosRequestConfig): AxiosPromise<HostedModel> {
            return localVarFp.hostedModelsAPICreateHostedModel(requestParameters.organizationId, requestParameters.hostedModelClusterId, requestParameters.hostedModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a hosted model deployment.
         * @summary Delete hosted model deployment.
         * @param {HostedModelsAPIApiHostedModelsAPIDeleteHostedModelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPIDeleteHostedModel(requestParameters: HostedModelsAPIApiHostedModelsAPIDeleteHostedModelRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.hostedModelsAPIDeleteHostedModel(requestParameters.organizationId, requestParameters.clusterId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the list of hosted models for given organization.  Supported sort fields are: model, createTime.  Max page limit is 1000.
         * @summary List hosted models.
         * @param {HostedModelsAPIApiHostedModelsAPIListHostedModelsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPIListHostedModels(requestParameters: HostedModelsAPIApiHostedModelsAPIListHostedModelsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ListHostedModelsResponse> {
            return localVarFp.hostedModelsAPIListHostedModels(requestParameters.organizationId, requestParameters.clusterId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * Scales the hosted model deployment.
         * @summary Scale the hosted model deployment.
         * @param {HostedModelsAPIApiHostedModelsAPIScaleHostedModelRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hostedModelsAPIScaleHostedModel(requestParameters: HostedModelsAPIApiHostedModelsAPIScaleHostedModelRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScaleHostedModelResponse> {
            return localVarFp.hostedModelsAPIScaleHostedModel(requestParameters.organizationId, requestParameters.clusterId, requestParameters.id, requestParameters.scaleHostedModelRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for hostedModelsAPICreateHostedModel operation in HostedModelsAPIApi.
 * @export
 * @interface HostedModelsAPIApiHostedModelsAPICreateHostedModelRequest
 */
export interface HostedModelsAPIApiHostedModelsAPICreateHostedModelRequest {
    /**
     * The ID of the Organization.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPICreateHostedModel
     */
    readonly organizationId: string

    /**
     * 
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPICreateHostedModel
     */
    readonly hostedModelClusterId: string

    /**
     * 
     * @type {HostedModel}
     * @memberof HostedModelsAPIApiHostedModelsAPICreateHostedModel
     */
    readonly hostedModel: HostedModel
}

/**
 * Request parameters for hostedModelsAPIDeleteHostedModel operation in HostedModelsAPIApi.
 * @export
 * @interface HostedModelsAPIApiHostedModelsAPIDeleteHostedModelRequest
 */
export interface HostedModelsAPIApiHostedModelsAPIDeleteHostedModelRequest {
    /**
     * The ID of the Organization.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIDeleteHostedModel
     */
    readonly organizationId: string

    /**
     * The ID of the Cluster.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIDeleteHostedModel
     */
    readonly clusterId: string

    /**
     * The ID of the hosted model deployment.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIDeleteHostedModel
     */
    readonly id: string
}

/**
 * Request parameters for hostedModelsAPIListHostedModels operation in HostedModelsAPIApi.
 * @export
 * @interface HostedModelsAPIApiHostedModelsAPIListHostedModelsRequest
 */
export interface HostedModelsAPIApiHostedModelsAPIListHostedModelsRequest {
    /**
     * The ID of the organization.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIListHostedModels
     */
    readonly organizationId: string

    /**
     * The ID of the cluster the model is deployed on. Use \&quot;-\&quot; placeholder to list all models across all clusters.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIListHostedModels
     */
    readonly clusterId: string

    /**
     * Defines the number of items that should be returned
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIListHostedModels
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page.  Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIListHostedModels
     */
    readonly pageCursor?: string

    /**
     * The field name to order by. Nested fields can be specified by using a dot (e.g. user.name).
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIListHostedModels
     */
    readonly sortField?: string

    /**
     * The order of returned items. Default is ASC.
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof HostedModelsAPIApiHostedModelsAPIListHostedModels
     */
    readonly sortOrder?: HostedModelsAPIListHostedModelsSortOrderEnum
}

/**
 * Request parameters for hostedModelsAPIScaleHostedModel operation in HostedModelsAPIApi.
 * @export
 * @interface HostedModelsAPIApiHostedModelsAPIScaleHostedModelRequest
 */
export interface HostedModelsAPIApiHostedModelsAPIScaleHostedModelRequest {
    /**
     * The ID of the Organization.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIScaleHostedModel
     */
    readonly organizationId: string

    /**
     * The ID of the Cluster.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIScaleHostedModel
     */
    readonly clusterId: string

    /**
     * The ID of the hosted model deployment.
     * @type {string}
     * @memberof HostedModelsAPIApiHostedModelsAPIScaleHostedModel
     */
    readonly id: string

    /**
     * 
     * @type {ScaleHostedModelRequest}
     * @memberof HostedModelsAPIApiHostedModelsAPIScaleHostedModel
     */
    readonly scaleHostedModelRequest: ScaleHostedModelRequest
}

/**
 * HostedModelsAPIApi - object-oriented interface
 * @export
 * @class HostedModelsAPIApi
 * @extends {BaseAPI}
 */
export class HostedModelsAPIApi extends BaseAPI {
    /**
     * Deploys a model on a CAST AI managed cluster.
     * @summary Create hosted model.
     * @param {HostedModelsAPIApiHostedModelsAPICreateHostedModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostedModelsAPIApi
     */
    public hostedModelsAPICreateHostedModel(requestParameters: HostedModelsAPIApiHostedModelsAPICreateHostedModelRequest, options?: RawAxiosRequestConfig) {
        return HostedModelsAPIApiFp(this.configuration).hostedModelsAPICreateHostedModel(requestParameters.organizationId, requestParameters.hostedModelClusterId, requestParameters.hostedModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a hosted model deployment.
     * @summary Delete hosted model deployment.
     * @param {HostedModelsAPIApiHostedModelsAPIDeleteHostedModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostedModelsAPIApi
     */
    public hostedModelsAPIDeleteHostedModel(requestParameters: HostedModelsAPIApiHostedModelsAPIDeleteHostedModelRequest, options?: RawAxiosRequestConfig) {
        return HostedModelsAPIApiFp(this.configuration).hostedModelsAPIDeleteHostedModel(requestParameters.organizationId, requestParameters.clusterId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the list of hosted models for given organization.  Supported sort fields are: model, createTime.  Max page limit is 1000.
     * @summary List hosted models.
     * @param {HostedModelsAPIApiHostedModelsAPIListHostedModelsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostedModelsAPIApi
     */
    public hostedModelsAPIListHostedModels(requestParameters: HostedModelsAPIApiHostedModelsAPIListHostedModelsRequest, options?: RawAxiosRequestConfig) {
        return HostedModelsAPIApiFp(this.configuration).hostedModelsAPIListHostedModels(requestParameters.organizationId, requestParameters.clusterId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Scales the hosted model deployment.
     * @summary Scale the hosted model deployment.
     * @param {HostedModelsAPIApiHostedModelsAPIScaleHostedModelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HostedModelsAPIApi
     */
    public hostedModelsAPIScaleHostedModel(requestParameters: HostedModelsAPIApiHostedModelsAPIScaleHostedModelRequest, options?: RawAxiosRequestConfig) {
        return HostedModelsAPIApiFp(this.configuration).hostedModelsAPIScaleHostedModel(requestParameters.organizationId, requestParameters.clusterId, requestParameters.id, requestParameters.scaleHostedModelRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const HostedModelsAPIListHostedModelsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type HostedModelsAPIListHostedModelsSortOrderEnum = typeof HostedModelsAPIListHostedModelsSortOrderEnum[keyof typeof HostedModelsAPIListHostedModelsSortOrderEnum];
