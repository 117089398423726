import { SxProps, Theme, Box } from '@mui/material';

import { mergeSx } from '@cast/design-system';

import { useFlagsQuery } from 'hooks/queries/useFlagsQuery';
import { ContentContainer } from 'main-layout/ContentContainer';

type AccountOnHoldHeaderProps = {
  sx?: SxProps<Theme>;
};

export const AccountOnHoldHeader = ({ sx }: AccountOnHoldHeaderProps) => {
  const { data } = useFlagsQuery({
    flagName: 'wire-readonly-access-enabled',
    enabled: true,
  });

  const isEnabled = data?.[0]?.boolean;

  if (!isEnabled) {
    return null;
  }

  return (
    <Box
      sx={mergeSx(
        {
          height: 48,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: 'red.500',
          color: 'white',
        },
        sx
      )}
    >
      <ContentContainer>
        Your account has been put on hold and you cannot make anymore changes.
        Please contact our Customer Success team to resume your account.
      </ContentContainer>
    </Box>
  );
};
