/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { ScheduledrebalancingV1ListAvailableRebalancingTZResponse } from '../models';
// @ts-ignore
import type { ScheduledrebalancingV1ListRebalancingJobsResponse } from '../models';
// @ts-ignore
import type { ScheduledrebalancingV1ListRebalancingSchedulesResponse } from '../models';
// @ts-ignore
import type { ScheduledrebalancingV1PreviewRebalancingScheduleResponse } from '../models';
// @ts-ignore
import type { ScheduledrebalancingV1RebalancingJob } from '../models';
// @ts-ignore
import type { ScheduledrebalancingV1RebalancingSchedule } from '../models';
// @ts-ignore
import type { ScheduledrebalancingV1RebalancingScheduleUpdate } from '../models';
/**
 * ScheduledRebalancingAPIApi - axios parameter creator
 * @export
 */
export const ScheduledRebalancingAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates rebalancing job, associating a cluster and a rebalancing schedule.
         * @summary Create rebalancing job
         * @param {string} clusterId 
         * @param {ScheduledrebalancingV1RebalancingJob} job RebalancingJob to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPICreateRebalancingJob: async (clusterId: string, job: ScheduledrebalancingV1RebalancingJob, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('scheduledRebalancingAPICreateRebalancingJob', 'clusterId', clusterId)
            // verify required parameter 'job' is not null or undefined
            assertParamExists('scheduledRebalancingAPICreateRebalancingJob', 'job', job)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-jobs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(job, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an organization-wide rebalancing schedule that can be used on multiple rebalancing jobs.
         * @summary Create rebalancing schedule
         * @param {ScheduledrebalancingV1RebalancingSchedule} schedule RebalancingSchedule to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPICreateRebalancingSchedule: async (schedule: ScheduledrebalancingV1RebalancingSchedule, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedule' is not null or undefined
            assertParamExists('scheduledRebalancingAPICreateRebalancingSchedule', 'schedule', schedule)
            const localVarPath = `/v1/rebalancing-schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a single rebalancing job.
         * @summary Delete rebalancing job
         * @param {string} clusterId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIDeleteRebalancingJob: async (clusterId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('scheduledRebalancingAPIDeleteRebalancingJob', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledRebalancingAPIDeleteRebalancingJob', 'id', id)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-jobs/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a rebalancing schedule.
         * @summary Delete rebalancing schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIDeleteRebalancingSchedule: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledRebalancingAPIDeleteRebalancingSchedule', 'id', id)
            const localVarPath = `/v1/rebalancing-schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a rebalancing job by it\'s ID
         * @summary Get rebalancing job
         * @param {string} clusterId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIGetRebalancingJob: async (clusterId: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('scheduledRebalancingAPIGetRebalancingJob', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledRebalancingAPIGetRebalancingJob', 'id', id)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-jobs/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a rebalancing schedule by it\'s ID
         * @summary Get rebalancing schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIGetRebalancingSchedule: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledRebalancingAPIGetRebalancingSchedule', 'id', id)
            const localVarPath = `/v1/rebalancing-schedules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available time zones for rebalancing schedules.
         * @summary List available rebalancing Time Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIListAvailableRebalancingTZ: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/time-zones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists rebalancing jobs per cluster.
         * @summary List rebalancing jobs
         * @param {string} clusterId 
         * @param {string} [rebalancingScheduleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIListRebalancingJobs: async (clusterId: string, rebalancingScheduleId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('scheduledRebalancingAPIListRebalancingJobs', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-jobs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rebalancingScheduleId !== undefined) {
                localVarQueryParameter['rebalancingScheduleId'] = rebalancingScheduleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists rebalancing schedules for the organization.
         * @summary List rebalancing schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIListRebalancingSchedules: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/rebalancing-schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Preview which nodes will be affected given the provided rebalancing schedule.
         * @summary Preview rebalancing schedule
         * @param {string} clusterId 
         * @param {ScheduledrebalancingV1RebalancingScheduleUpdate} schedule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIPreviewRebalancingSchedule: async (clusterId: string, schedule: ScheduledrebalancingV1RebalancingScheduleUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('scheduledRebalancingAPIPreviewRebalancingSchedule', 'clusterId', clusterId)
            // verify required parameter 'schedule' is not null or undefined
            assertParamExists('scheduledRebalancingAPIPreviewRebalancingSchedule', 'schedule', schedule)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-schedule-preview`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates rebalancing job setup.
         * @summary Update rebalancing job
         * @param {string} clusterId 
         * @param {string} id 
         * @param {ScheduledrebalancingV1RebalancingJob} job 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIUpdateRebalancingJob: async (clusterId: string, id: string, job: ScheduledrebalancingV1RebalancingJob, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('scheduledRebalancingAPIUpdateRebalancingJob', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scheduledRebalancingAPIUpdateRebalancingJob', 'id', id)
            // verify required parameter 'job' is not null or undefined
            assertParamExists('scheduledRebalancingAPIUpdateRebalancingJob', 'job', job)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/rebalancing-jobs/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(job, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates configuration of rebalancing schedule.
         * @summary Edit rebalancing schedule
         * @param {ScheduledrebalancingV1RebalancingScheduleUpdate} schedule 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIUpdateRebalancingSchedule: async (schedule: ScheduledrebalancingV1RebalancingScheduleUpdate, id?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'schedule' is not null or undefined
            assertParamExists('scheduledRebalancingAPIUpdateRebalancingSchedule', 'schedule', schedule)
            const localVarPath = `/v1/rebalancing-schedules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScheduledRebalancingAPIApi - functional programming interface
 * @export
 */
export const ScheduledRebalancingAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScheduledRebalancingAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates rebalancing job, associating a cluster and a rebalancing schedule.
         * @summary Create rebalancing job
         * @param {string} clusterId 
         * @param {ScheduledrebalancingV1RebalancingJob} job RebalancingJob to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPICreateRebalancingJob(clusterId: string, job: ScheduledrebalancingV1RebalancingJob, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1RebalancingJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPICreateRebalancingJob(clusterId, job, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPICreateRebalancingJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates an organization-wide rebalancing schedule that can be used on multiple rebalancing jobs.
         * @summary Create rebalancing schedule
         * @param {ScheduledrebalancingV1RebalancingSchedule} schedule RebalancingSchedule to create
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPICreateRebalancingSchedule(schedule: ScheduledrebalancingV1RebalancingSchedule, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1RebalancingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPICreateRebalancingSchedule(schedule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPICreateRebalancingSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a single rebalancing job.
         * @summary Delete rebalancing job
         * @param {string} clusterId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIDeleteRebalancingJob(clusterId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIDeleteRebalancingJob(clusterId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIDeleteRebalancingJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Deletes a rebalancing schedule.
         * @summary Delete rebalancing schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIDeleteRebalancingSchedule(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIDeleteRebalancingSchedule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIDeleteRebalancingSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a rebalancing job by it\'s ID
         * @summary Get rebalancing job
         * @param {string} clusterId 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIGetRebalancingJob(clusterId: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1RebalancingJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIGetRebalancingJob(clusterId, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIGetRebalancingJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Gets a rebalancing schedule by it\'s ID
         * @summary Get rebalancing schedule
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIGetRebalancingSchedule(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1RebalancingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIGetRebalancingSchedule(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIGetRebalancingSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List available time zones for rebalancing schedules.
         * @summary List available rebalancing Time Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIListAvailableRebalancingTZ(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1ListAvailableRebalancingTZResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIListAvailableRebalancingTZ(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIListAvailableRebalancingTZ']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists rebalancing jobs per cluster.
         * @summary List rebalancing jobs
         * @param {string} clusterId 
         * @param {string} [rebalancingScheduleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIListRebalancingJobs(clusterId: string, rebalancingScheduleId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1ListRebalancingJobsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIListRebalancingJobs(clusterId, rebalancingScheduleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIListRebalancingJobs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Lists rebalancing schedules for the organization.
         * @summary List rebalancing schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIListRebalancingSchedules(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1ListRebalancingSchedulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIListRebalancingSchedules(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIListRebalancingSchedules']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Preview which nodes will be affected given the provided rebalancing schedule.
         * @summary Preview rebalancing schedule
         * @param {string} clusterId 
         * @param {ScheduledrebalancingV1RebalancingScheduleUpdate} schedule 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIPreviewRebalancingSchedule(clusterId: string, schedule: ScheduledrebalancingV1RebalancingScheduleUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1PreviewRebalancingScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIPreviewRebalancingSchedule(clusterId, schedule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIPreviewRebalancingSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates rebalancing job setup.
         * @summary Update rebalancing job
         * @param {string} clusterId 
         * @param {string} id 
         * @param {ScheduledrebalancingV1RebalancingJob} job 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIUpdateRebalancingJob(clusterId: string, id: string, job: ScheduledrebalancingV1RebalancingJob, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1RebalancingJob>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIUpdateRebalancingJob(clusterId, id, job, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIUpdateRebalancingJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Updates configuration of rebalancing schedule.
         * @summary Edit rebalancing schedule
         * @param {ScheduledrebalancingV1RebalancingScheduleUpdate} schedule 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scheduledRebalancingAPIUpdateRebalancingSchedule(schedule: ScheduledrebalancingV1RebalancingScheduleUpdate, id?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledrebalancingV1RebalancingSchedule>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scheduledRebalancingAPIUpdateRebalancingSchedule(schedule, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScheduledRebalancingAPIApi.scheduledRebalancingAPIUpdateRebalancingSchedule']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ScheduledRebalancingAPIApi - factory interface
 * @export
 */
export const ScheduledRebalancingAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScheduledRebalancingAPIApiFp(configuration)
    return {
        /**
         * Creates rebalancing job, associating a cluster and a rebalancing schedule.
         * @summary Create rebalancing job
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJobRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPICreateRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1RebalancingJob> {
            return localVarFp.scheduledRebalancingAPICreateRebalancingJob(requestParameters.clusterId, requestParameters.job, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an organization-wide rebalancing schedule that can be used on multiple rebalancing jobs.
         * @summary Create rebalancing schedule
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingScheduleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPICreateRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingScheduleRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1RebalancingSchedule> {
            return localVarFp.scheduledRebalancingAPICreateRebalancingSchedule(requestParameters.schedule, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a single rebalancing job.
         * @summary Delete rebalancing job
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJobRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIDeleteRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.scheduledRebalancingAPIDeleteRebalancingJob(requestParameters.clusterId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a rebalancing schedule.
         * @summary Delete rebalancing schedule
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingScheduleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIDeleteRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingScheduleRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.scheduledRebalancingAPIDeleteRebalancingSchedule(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a rebalancing job by it\'s ID
         * @summary Get rebalancing job
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJobRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIGetRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1RebalancingJob> {
            return localVarFp.scheduledRebalancingAPIGetRebalancingJob(requestParameters.clusterId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a rebalancing schedule by it\'s ID
         * @summary Get rebalancing schedule
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingScheduleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIGetRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingScheduleRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1RebalancingSchedule> {
            return localVarFp.scheduledRebalancingAPIGetRebalancingSchedule(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * List available time zones for rebalancing schedules.
         * @summary List available rebalancing Time Zones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIListAvailableRebalancingTZ(options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1ListAvailableRebalancingTZResponse> {
            return localVarFp.scheduledRebalancingAPIListAvailableRebalancingTZ(options).then((request) => request(axios, basePath));
        },
        /**
         * Lists rebalancing jobs per cluster.
         * @summary List rebalancing jobs
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIListRebalancingJobs(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobsRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1ListRebalancingJobsResponse> {
            return localVarFp.scheduledRebalancingAPIListRebalancingJobs(requestParameters.clusterId, requestParameters.rebalancingScheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists rebalancing schedules for the organization.
         * @summary List rebalancing schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIListRebalancingSchedules(options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1ListRebalancingSchedulesResponse> {
            return localVarFp.scheduledRebalancingAPIListRebalancingSchedules(options).then((request) => request(axios, basePath));
        },
        /**
         * Preview which nodes will be affected given the provided rebalancing schedule.
         * @summary Preview rebalancing schedule
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingScheduleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIPreviewRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingScheduleRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1PreviewRebalancingScheduleResponse> {
            return localVarFp.scheduledRebalancingAPIPreviewRebalancingSchedule(requestParameters.clusterId, requestParameters.schedule, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates rebalancing job setup.
         * @summary Update rebalancing job
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJobRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIUpdateRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJobRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1RebalancingJob> {
            return localVarFp.scheduledRebalancingAPIUpdateRebalancingJob(requestParameters.clusterId, requestParameters.id, requestParameters.job, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates configuration of rebalancing schedule.
         * @summary Edit rebalancing schedule
         * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingScheduleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduledRebalancingAPIUpdateRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingScheduleRequest, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledrebalancingV1RebalancingSchedule> {
            return localVarFp.scheduledRebalancingAPIUpdateRebalancingSchedule(requestParameters.schedule, requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for scheduledRebalancingAPICreateRebalancingJob operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJobRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJobRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJob
     */
    readonly clusterId: string

    /**
     * RebalancingJob to create
     * @type {ScheduledrebalancingV1RebalancingJob}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJob
     */
    readonly job: ScheduledrebalancingV1RebalancingJob
}

/**
 * Request parameters for scheduledRebalancingAPICreateRebalancingSchedule operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingScheduleRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingScheduleRequest {
    /**
     * RebalancingSchedule to create
     * @type {ScheduledrebalancingV1RebalancingSchedule}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingSchedule
     */
    readonly schedule: ScheduledrebalancingV1RebalancingSchedule
}

/**
 * Request parameters for scheduledRebalancingAPIDeleteRebalancingJob operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJobRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJobRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJob
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJob
     */
    readonly id: string
}

/**
 * Request parameters for scheduledRebalancingAPIDeleteRebalancingSchedule operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingScheduleRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingScheduleRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingSchedule
     */
    readonly id: string
}

/**
 * Request parameters for scheduledRebalancingAPIGetRebalancingJob operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJobRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJobRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJob
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJob
     */
    readonly id: string
}

/**
 * Request parameters for scheduledRebalancingAPIGetRebalancingSchedule operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingScheduleRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingScheduleRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingSchedule
     */
    readonly id: string
}

/**
 * Request parameters for scheduledRebalancingAPIListRebalancingJobs operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobsRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobsRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobs
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobs
     */
    readonly rebalancingScheduleId?: string
}

/**
 * Request parameters for scheduledRebalancingAPIPreviewRebalancingSchedule operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingScheduleRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingScheduleRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingSchedule
     */
    readonly clusterId: string

    /**
     * 
     * @type {ScheduledrebalancingV1RebalancingScheduleUpdate}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingSchedule
     */
    readonly schedule: ScheduledrebalancingV1RebalancingScheduleUpdate
}

/**
 * Request parameters for scheduledRebalancingAPIUpdateRebalancingJob operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJobRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJobRequest {
    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJob
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJob
     */
    readonly id: string

    /**
     * 
     * @type {ScheduledrebalancingV1RebalancingJob}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJob
     */
    readonly job: ScheduledrebalancingV1RebalancingJob
}

/**
 * Request parameters for scheduledRebalancingAPIUpdateRebalancingSchedule operation in ScheduledRebalancingAPIApi.
 * @export
 * @interface ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingScheduleRequest
 */
export interface ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingScheduleRequest {
    /**
     * 
     * @type {ScheduledrebalancingV1RebalancingScheduleUpdate}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingSchedule
     */
    readonly schedule: ScheduledrebalancingV1RebalancingScheduleUpdate

    /**
     * 
     * @type {string}
     * @memberof ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingSchedule
     */
    readonly id?: string
}

/**
 * ScheduledRebalancingAPIApi - object-oriented interface
 * @export
 * @class ScheduledRebalancingAPIApi
 * @extends {BaseAPI}
 */
export class ScheduledRebalancingAPIApi extends BaseAPI {
    /**
     * Creates rebalancing job, associating a cluster and a rebalancing schedule.
     * @summary Create rebalancing job
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPICreateRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingJobRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPICreateRebalancingJob(requestParameters.clusterId, requestParameters.job, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an organization-wide rebalancing schedule that can be used on multiple rebalancing jobs.
     * @summary Create rebalancing schedule
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPICreateRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPICreateRebalancingScheduleRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPICreateRebalancingSchedule(requestParameters.schedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a single rebalancing job.
     * @summary Delete rebalancing job
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIDeleteRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingJobRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIDeleteRebalancingJob(requestParameters.clusterId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a rebalancing schedule.
     * @summary Delete rebalancing schedule
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIDeleteRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIDeleteRebalancingScheduleRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIDeleteRebalancingSchedule(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a rebalancing job by it\'s ID
     * @summary Get rebalancing job
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIGetRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingJobRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIGetRebalancingJob(requestParameters.clusterId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a rebalancing schedule by it\'s ID
     * @summary Get rebalancing schedule
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIGetRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIGetRebalancingScheduleRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIGetRebalancingSchedule(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available time zones for rebalancing schedules.
     * @summary List available rebalancing Time Zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIListAvailableRebalancingTZ(options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIListAvailableRebalancingTZ(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists rebalancing jobs per cluster.
     * @summary List rebalancing jobs
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIListRebalancingJobs(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIListRebalancingJobsRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIListRebalancingJobs(requestParameters.clusterId, requestParameters.rebalancingScheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists rebalancing schedules for the organization.
     * @summary List rebalancing schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIListRebalancingSchedules(options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIListRebalancingSchedules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Preview which nodes will be affected given the provided rebalancing schedule.
     * @summary Preview rebalancing schedule
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIPreviewRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIPreviewRebalancingScheduleRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIPreviewRebalancingSchedule(requestParameters.clusterId, requestParameters.schedule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates rebalancing job setup.
     * @summary Update rebalancing job
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIUpdateRebalancingJob(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingJobRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIUpdateRebalancingJob(requestParameters.clusterId, requestParameters.id, requestParameters.job, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates configuration of rebalancing schedule.
     * @summary Edit rebalancing schedule
     * @param {ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingScheduleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScheduledRebalancingAPIApi
     */
    public scheduledRebalancingAPIUpdateRebalancingSchedule(requestParameters: ScheduledRebalancingAPIApiScheduledRebalancingAPIUpdateRebalancingScheduleRequest, options?: RawAxiosRequestConfig) {
        return ScheduledRebalancingAPIApiFp(this.configuration).scheduledRebalancingAPIUpdateRebalancingSchedule(requestParameters.schedule, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

