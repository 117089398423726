/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CostreportV1beta1GetClusterNamespaceCostReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterNamespaceCostReportSummariesResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterNamespaceDataTransferCostResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetClusterNamespaceDataTransferTimedTotalCostResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetSingleNamespaceCostReportResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1NamespaceFilter } from '../models';
/**
 * NamespaceReportAPIApi - axios parameter creator
 * @export
 */
export const NamespaceReportAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets cluster namespaces cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1NamespaceFilter} filter Filtering options.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceCostReport: async (clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1NamespaceFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReport', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReport', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReport', 'filter', filter)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespace-totalcost`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (stepSeconds !== undefined) {
                localVarQueryParameter['stepSeconds'] = stepSeconds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster namespaces cost report summaries.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1NamespaceFilter} filter Filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceCostReportSummaries: async (clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1NamespaceFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReportSummaries', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReportSummaries', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReportSummaries', 'endTime', endTime)
            // verify required parameter 'filter' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceCostReportSummaries', 'filter', filter)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespace-cost-summaries`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filter, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets cluster namespaces data transfer report
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {string} [filterNamespace] Filter items by namespace. Acts like a case-insensitive substring search.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceDataTransferCost: async (clusterId: string, startTime: string, endTime: string, filterNamespace?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceDataTransferCost', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceDataTransferCost', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceDataTransferCost', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespace-datatransfer-costs`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (filterNamespace !== undefined) {
                localVarQueryParameter['filter.namespace'] = filterNamespace;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a timed breakdown of the data transfer costs for the top K namespaces in the cluster (by default breakdown is by day).
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost: async (clusterId: string, startTime: string, endTime: string, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost', 'clusterId', clusterId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespace-datatransfer-totalcost`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a single namespace cost report with a daily breakdown.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Which namespace to return data for.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetSingleNamespaceCostReport: async (clusterId: string, namespace: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('namespaceReportAPIGetSingleNamespaceCostReport', 'clusterId', clusterId)
            // verify required parameter 'namespace' is not null or undefined
            assertParamExists('namespaceReportAPIGetSingleNamespaceCostReport', 'namespace', namespace)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetSingleNamespaceCostReport', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('namespaceReportAPIGetSingleNamespaceCostReport', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/clusters/{clusterId}/namespaces/{namespace}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"namespace"}}`, encodeURIComponent(String(namespace)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NamespaceReportAPIApi - functional programming interface
 * @export
 */
export const NamespaceReportAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NamespaceReportAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets cluster namespaces cost report.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1NamespaceFilter} filter Filtering options.
         * @param {number} [stepSeconds] Aggregate items in specified interval steps.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async namespaceReportAPIGetClusterNamespaceCostReport(clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1NamespaceFilter, stepSeconds?: number, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterNamespaceCostReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.namespaceReportAPIGetClusterNamespaceCostReport(clusterId, startTime, endTime, filter, stepSeconds, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NamespaceReportAPIApi.namespaceReportAPIGetClusterNamespaceCostReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster namespaces cost report summaries.
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {CostreportV1beta1NamespaceFilter} filter Filtering options.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async namespaceReportAPIGetClusterNamespaceCostReportSummaries(clusterId: string, startTime: string, endTime: string, filter: CostreportV1beta1NamespaceFilter, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterNamespaceCostReportSummariesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.namespaceReportAPIGetClusterNamespaceCostReportSummaries(clusterId, startTime, endTime, filter, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NamespaceReportAPIApi.namespaceReportAPIGetClusterNamespaceCostReportSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets cluster namespaces data transfer report
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {string} [filterNamespace] Filter items by namespace. Acts like a case-insensitive substring search.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async namespaceReportAPIGetClusterNamespaceDataTransferCost(clusterId: string, startTime: string, endTime: string, filterNamespace?: string, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterNamespaceDataTransferCostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.namespaceReportAPIGetClusterNamespaceDataTransferCost(clusterId, startTime, endTime, filterNamespace, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NamespaceReportAPIApi.namespaceReportAPIGetClusterNamespaceDataTransferCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a timed breakdown of the data transfer costs for the top K namespaces in the cluster (by default breakdown is by day).
         * @param {string} clusterId ID of the cluster.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost(clusterId: string, startTime: string, endTime: string, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetClusterNamespaceDataTransferTimedTotalCostResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost(clusterId, startTime, endTime, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NamespaceReportAPIApi.namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets a single namespace cost report with a daily breakdown.
         * @param {string} clusterId ID of the cluster.
         * @param {string} namespace Which namespace to return data for.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async namespaceReportAPIGetSingleNamespaceCostReport(clusterId: string, namespace: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetSingleNamespaceCostReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.namespaceReportAPIGetSingleNamespaceCostReport(clusterId, namespace, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NamespaceReportAPIApi.namespaceReportAPIGetSingleNamespaceCostReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NamespaceReportAPIApi - factory interface
 * @export
 */
export const NamespaceReportAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NamespaceReportAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets cluster namespaces cost report.
         * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceCostReport(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterNamespaceCostReportResponse> {
            return localVarFp.namespaceReportAPIGetClusterNamespaceCostReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster namespaces cost report summaries.
         * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummariesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceCostReportSummaries(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummariesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterNamespaceCostReportSummariesResponse> {
            return localVarFp.namespaceReportAPIGetClusterNamespaceCostReportSummaries(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets cluster namespaces data transfer report
         * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceDataTransferCost(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterNamespaceDataTransferCostResponse> {
            return localVarFp.namespaceReportAPIGetClusterNamespaceDataTransferCost(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filterNamespace, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a timed breakdown of the data transfer costs for the top K namespaces in the cluster (by default breakdown is by day).
         * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetClusterNamespaceDataTransferTimedTotalCostResponse> {
            return localVarFp.namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a single namespace cost report with a daily breakdown.
         * @param {NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        namespaceReportAPIGetSingleNamespaceCostReport(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReportRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetSingleNamespaceCostReportResponse> {
            return localVarFp.namespaceReportAPIGetSingleNamespaceCostReport(requestParameters.clusterId, requestParameters.namespace, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for namespaceReportAPIGetClusterNamespaceCostReport operation in NamespaceReportAPIApi.
 * @export
 * @interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportRequest
 */
export interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly endTime: string

    /**
     * Filtering options.
     * @type {CostreportV1beta1NamespaceFilter}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly filter: CostreportV1beta1NamespaceFilter

    /**
     * Aggregate items in specified interval steps.
     * @type {number}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly stepSeconds?: number

    /**
     * 
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReport
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for namespaceReportAPIGetClusterNamespaceCostReportSummaries operation in NamespaceReportAPIApi.
 * @export
 * @interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummariesRequest
 */
export interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummariesRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly endTime: string

    /**
     * Filtering options.
     * @type {CostreportV1beta1NamespaceFilter}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly filter: CostreportV1beta1NamespaceFilter

    /**
     * 
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummaries
     */
    readonly sortOrder?: NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum
}

/**
 * Request parameters for namespaceReportAPIGetClusterNamespaceDataTransferCost operation in NamespaceReportAPIApi.
 * @export
 * @interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCostRequest
 */
export interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCostRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly endTime: string

    /**
     * Filter items by namespace. Acts like a case-insensitive substring search.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly filterNamespace?: string

    /**
     * 
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCost
     */
    readonly sortOrder?: NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum
}

/**
 * Request parameters for namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost operation in NamespaceReportAPIApi.
 * @export
 * @interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCostRequest
 */
export interface NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCostRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost
     */
    readonly clusterId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost
     */
    readonly endTime: string

    /**
     * 
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for namespaceReportAPIGetSingleNamespaceCostReport operation in NamespaceReportAPIApi.
 * @export
 * @interface NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReportRequest
 */
export interface NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReportRequest {
    /**
     * ID of the cluster.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReport
     */
    readonly clusterId: string

    /**
     * Which namespace to return data for.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReport
     */
    readonly namespace: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReport
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReport
     */
    readonly endTime: string
}

/**
 * NamespaceReportAPIApi - object-oriented interface
 * @export
 * @class NamespaceReportAPIApi
 * @extends {BaseAPI}
 */
export class NamespaceReportAPIApi extends BaseAPI {
    /**
     * 
     * @summary Gets cluster namespaces cost report.
     * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceReportAPIApi
     */
    public namespaceReportAPIGetClusterNamespaceCostReport(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportRequest, options?: RawAxiosRequestConfig) {
        return NamespaceReportAPIApiFp(this.configuration).namespaceReportAPIGetClusterNamespaceCostReport(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.stepSeconds, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster namespaces cost report summaries.
     * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummariesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceReportAPIApi
     */
    public namespaceReportAPIGetClusterNamespaceCostReportSummaries(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceCostReportSummariesRequest, options?: RawAxiosRequestConfig) {
        return NamespaceReportAPIApiFp(this.configuration).namespaceReportAPIGetClusterNamespaceCostReportSummaries(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filter, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets cluster namespaces data transfer report
     * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceReportAPIApi
     */
    public namespaceReportAPIGetClusterNamespaceDataTransferCost(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferCostRequest, options?: RawAxiosRequestConfig) {
        return NamespaceReportAPIApiFp(this.configuration).namespaceReportAPIGetClusterNamespaceDataTransferCost(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.filterNamespace, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a timed breakdown of the data transfer costs for the top K namespaces in the cluster (by default breakdown is by day).
     * @param {NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceReportAPIApi
     */
    public namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCostRequest, options?: RawAxiosRequestConfig) {
        return NamespaceReportAPIApiFp(this.configuration).namespaceReportAPIGetClusterNamespaceDataTransferTimedTotalCost(requestParameters.clusterId, requestParameters.startTime, requestParameters.endTime, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a single namespace cost report with a daily breakdown.
     * @param {NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamespaceReportAPIApi
     */
    public namespaceReportAPIGetSingleNamespaceCostReport(requestParameters: NamespaceReportAPIApiNamespaceReportAPIGetSingleNamespaceCostReportRequest, options?: RawAxiosRequestConfig) {
        return NamespaceReportAPIApiFp(this.configuration).namespaceReportAPIGetSingleNamespaceCostReport(requestParameters.clusterId, requestParameters.namespace, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum = typeof NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum[keyof typeof NamespaceReportAPIGetClusterNamespaceCostReportSummariesSortOrderEnum];
/**
 * @export
 */
export const NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum = typeof NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum[keyof typeof NamespaceReportAPIGetClusterNamespaceDataTransferCostSortOrderEnum];
