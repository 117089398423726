import { cloneElement, ReactNode } from 'react';

import { Icons, mergeSx } from '@cast/design-system';

import { HexIcon, HexIconProps } from 'components/hex-icons';
import { StatusMessage, StatusMessageProps } from 'components/messages';

import { useSearchContext } from './hooks';

type NoResultsProps = Omit<StatusMessageProps, 'title'> & {
  title?: ReactNode;
  entity?: string;
  hexProps?: HexIconProps;
};

export const NoResults = ({
  icon = <Icons.MagnifyingGlass />,
  entity = 'entry',
  sx,
  hexProps = { iconColor: 'grey.500', hexColor: 'grey.300' },
  ...rest
}: NoResultsProps) => {
  const searchContext = useSearchContext();

  return (
    <StatusMessage
      icon={
        <HexIcon
          size={64}
          icon={cloneElement(icon, {
            weight: 'fill',
          })}
          {...hexProps}
        />
      }
      title="No results found"
      titleProps={{ sx: { margin: 0 } }}
      body={
        searchContext?.freeText && !!entity
          ? `Unfortunately, we did not find any ${entity} matching “${searchContext?.freeText}”.`
          : 'Please adjust your filters and try again.'
      }
      sx={mergeSx(
        {
          marginTop: '32px',
        },
        sx
      )}
      testId="no-results-found"
      {...rest}
    />
  );
};
