import { Suspense } from 'react';

import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom';

import { lazyRetry } from '@cast/utils';

import { IS_DEV } from 'common/constants';
import { ContactsFormDialog, MultiStepContactsForm } from 'components/dialogs';
import { SuspenseLoader } from 'components/loaders';
import { ProtectedRoute } from 'components/router';
import { AbilityGuard } from 'core/ability';
import { Experiment, Variant } from 'core/experiments';
import { TourProvider } from 'core/tour';
import { CriticalNotificationList } from 'features/notifications/CriticalNotificationList';
import { ForbiddenPage } from 'pages/error/Forbidden';

import { LandingRedirect } from './LandingRedirect';
import { MainLayout } from './main-layout';

dayjs.extend(isBetween);

dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(Duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(timezone);

const DashboardController = lazyRetry(
  () => import('pages/dashboard/DashboardController')
);

const ClusterController = lazyRetry(
  () => import('pages/cluster/ClusterController')
);

const OrganizationController = lazyRetry(
  () => import('pages/organization/OrganizationController')
);

const ApiAccessKeysController = lazyRetry(
  () => import('pages/api-access-keys/ApiAccessKeysController')
);

const SettingsController = lazyRetry(
  () => import('pages/settings/SettingsController')
);

const NotificationsController = lazyRetry(
  () => import('pages/notifications/NotificationsController')
);

const YamlPreviewPage = lazyRetry(
  () => import('pages/yaml-preview/YamlPreviewPage')
);

const CommitmentsController = lazyRetry(
  () => import('pages/commitments/CommitmentsController')
);

const OptimizationController = lazyRetry(
  () => import('pages/optimization/OptimizationController')
);

const LLMController = lazyRetry(
  () => import('pages/llm-optimization/LLMController')
);

const DBOptimizationController = lazyRetry(
  () => import('pages/db-optimization/DbOptimizationController')
);

const JoinRedirect = () => {
  const { invitationId } = useParams();
  return (
    <Navigate to={`/organization/management/join/${invitationId}`} replace />
  );
};

export const App = () => {
  return (
    <TourProvider>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <AbilityGuard subject="OrganizationNotifications" action="view">
                <CriticalNotificationList />
              </AbilityGuard>
              <Suspense fallback={<SuspenseLoader />}>
                <Outlet />

                {!IS_DEV && (
                  <Experiment name="signup-form">
                    <Variant id="0">
                      <ContactsFormDialog />
                    </Variant>
                    <Variant id="1">
                      <MultiStepContactsForm />
                    </Variant>
                  </Experiment>
                )}
              </Suspense>
            </MainLayout>
          }
        >
          <Route index Component={LandingRedirect} />

          <Route path="/dashboard/*" element={<DashboardController />} />

          <Route
            element={
              <ProtectedRoute subject="OrganizationRebalanceSchedules" />
            }
          >
            <Route path="/settings/*" element={<SettingsController />} />
          </Route>

          <Route
            element={<ProtectedRoute subject="OrganizationNotifications" />}
          >
            <Route
              path="/notifications/*"
              element={<NotificationsController />}
            />
          </Route>

          <Route
            path="/organization/join/:invitationId"
            element={<JoinRedirect />}
          />
          <Route path="/organization/*" element={<OrganizationController />} />

          <Route element={<ProtectedRoute subject="OrganizationCommitments" />}>
            <Route path="/commitments/*" element={<CommitmentsController />} />
          </Route>

          <Route
            element={
              <ProtectedRoute subject="OrganizationAiOptimizerReports" />
            }
          >
            <Route path="/llm/*" element={<LLMController />} />
          </Route>

          <Route
            element={
              <ProtectedRoute subject="OrganizationDatabaseOptimization" />
            }
          >
            <Route path="/dbo/*" element={<DBOptimizationController />} />
          </Route>

          <Route element={<ProtectedRoute subject="Clusters" />}>
            <Route
              path="/external-clusters/:clusterId/*"
              element={<ClusterController />}
            />
          </Route>

          <Route
            path="/user/api-access/*"
            element={<ApiAccessKeysController />}
          />

          <Route
            path={'/optimization/*'}
            element={<OptimizationController />}
          />

          <Route path="/yaml-preview" element={<YamlPreviewPage />} />
        </Route>

        <Route
          path="/forbidden"
          element={
            <MainLayout noMenu sidebar={false}>
              <ForbiddenPage />
            </MainLayout>
          }
        />
      </Routes>
    </TourProvider>
  );
};
