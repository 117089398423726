import { useEffect } from 'react';

import { analyticsEvents } from 'core/analytics';
import { useNavigate } from 'hooks/useNavigate';
import { deleteCookie, getCookie } from 'utils/cookie';

const REDIRECT_COOKIE_KEY = '_cast_redirect_context';

type ProductValues = 'security' | 'ai-enabler' | 'ai-optimizer';

const redirectMap: Record<ProductValues, string> = {
  security: '/organization/security/dashboard',
  'ai-enabler': '/llm/report',
  'ai-optimizer': '/llm/report',
};

type RefContextCookie = {
  url?: string;
  context?: string;
  product?: ProductValues;
  timestamp?: number;
};

const getRefContextCookie = (): RefContextCookie => {
  try {
    return JSON.parse(
      decodeURIComponent(getCookie(REDIRECT_COOKIE_KEY) ?? '{}')
    ) as RefContextCookie;
  } catch (error) {
    return {};
  }
};

export const useNavigateToPageOfInterest = (isLoading: boolean) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const refContextCookie = getRefContextCookie();
    if (!refContextCookie.product) {
      return;
    }
    const redirectPath = redirectMap[refContextCookie.product];
    if (!redirectPath) {
      return;
    }

    deleteCookie({
      name: REDIRECT_COOKIE_KEY,
      domain:
        document.location.hostname === 'localhost' ? 'localhost' : '.cast.ai',
    });
    analyticsEvents.showedInterestIn({
      feature: refContextCookie.product,
      context: refContextCookie.context,
      url: refContextCookie.url,
    });
    navigate(redirectPath, { replace: true });
  }, [isLoading, navigate]);
};
