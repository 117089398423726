import { FilterPreset } from '@cast/design-system';

export type RangePickerQueryParamKeys = {
  fromDate: string;
  toDate: string;
  dateRangePreset: string;
};

export enum DateRangeFilter {
  ThisMonth = 'THIS_MONTH',
  Last15Mins = 'LAST_15_MINS',
  Last30Mins = 'LAST_30_MINS',
  Last24Hours = 'LAST_24_HOURS',
  LastHour = 'LAST_HOUR',
  TODAY = 'TODAY',
  Last7Days = 'LAST_7_DAYS',
  Last30Days = 'LAST_30_DAYS',
  Last1Year = 'LAST_YEAR',
  LastMonth = 'LAST_MONTH',
  LastWeek = 'LAST_WEEK',
  Last2Weeks = 'LAST_2_WEEKS',
  Last3Months = 'LAST_3_MONTHS',
}

export type Filters = { [key in DateRangeFilter]: FilterPreset };
