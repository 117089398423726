/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CostreportV1beta1AllocationGroup } from '../models';
// @ts-ignore
import type { CostreportV1beta1AllocationGroupDetails } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetAllocationGroupCostSummariesResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetAllocationGroupCostTimedSummariesResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetAllocationGroupEfficiencySummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetAllocationGroupTotalCostTimedResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetAllocationGroupWorkloadCostsResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetAllocationGroupWorkloadsEfficiencyResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetCostAllocationGroupDataTransferSummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetCostAllocationGroupDataTransferWorkloadsResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetCostAllocationGroupSummaryResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1GetCostAllocationGroupWorkloadsResponse } from '../models';
// @ts-ignore
import type { CostreportV1beta1ListAllocationGroupsResponse } from '../models';
/**
 * AllocationGroupAPIApi - axios parameter creator
 * @export
 */
export const AllocationGroupAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an allocation group for custom report.
         * @param {CostreportV1beta1AllocationGroupDetails} allocationGroup Allocation group details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPICreateAllocationGroup: async (allocationGroup: CostreportV1beta1AllocationGroupDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'allocationGroup' is not null or undefined
            assertParamExists('allocationGroupAPICreateAllocationGroup', 'allocationGroup', allocationGroup)
            const localVarPath = `/v1/cost-reports/allocation-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an allocation group.
         * @param {string} id ID of the allocation group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIDeleteAllocationGroup: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allocationGroupAPIDeleteAllocationGroup', 'id', id)
            const localVarPath = `/v1/cost-reports/allocation-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets allocation group cost summaries.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [groupId] Allocation group ID. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupCostSummaries: async (startTime: string, endTime: string, clusterIds?: Array<string>, groupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupCostSummaries', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupCostSummaries', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-group-summaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets allocation group timed cost summaries.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [groupId] Allocation group ID. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupCostTimedSummaries: async (startTime: string, endTime: string, clusterIds?: Array<string>, groupId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupCostTimedSummaries', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupCostTimedSummaries', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-group-costs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (groupId !== undefined) {
                localVarQueryParameter['groupId'] = groupId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets allocation group efficiency summary.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupEfficiencySummary: async (startTime: string, endTime: string, clusterIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupEfficiencySummary', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupEfficiencySummary', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/efficiency/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets allocation groups timed total cost only.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupTotalCostTimed: async (startTime: string, endTime: string, clusterIds?: Array<string>, pageLimit?: string, pageCursor?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupTotalCostTimed', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupTotalCostTimed', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-group-totals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workloads of cost allocation group.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupWorkloadCosts: async (groupId: string, startTime: string, endTime: string, clusterIds?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupWorkloadCosts', 'groupId', groupId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupWorkloadCosts', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupWorkloadCosts', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/{groupId}/workload-costs`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets allocation group efficiency workloads.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupWorkloadsEfficiency: async (groupId: string, startTime: string, endTime: string, clusterIds?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupWorkloadsEfficiency', 'groupId', groupId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupWorkloadsEfficiency', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetAllocationGroupWorkloadsEfficiency', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/{groupId}/workload-efficiency`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sort.field'] = sortField;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sort.order'] = sortOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets allocation group summary for datatransfer costs.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupDataTransferSummary: async (startTime: string, endTime: string, clusterIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupDataTransferSummary', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupDataTransferSummary', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/datatransfer-costs/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets workloads of datatransfer cost allocation group.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads: async (groupId: string, startTime: string, endTime: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads', 'groupId', groupId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/{groupId}/datatransfer-costs/workloads`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deprecated: use /v1/cost-reports/allocation-groups-summaries or /v1/cost-reports/allocation-group-costs.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupSummary: async (startTime: string, endTime: string, clusterIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupSummary', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupSummary', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/cost-reports/allocation-groups/{group_id}/workload-costs instead.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupWorkloads: async (groupId: string, startTime: string, endTime: string, clusterIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupWorkloads', 'groupId', groupId)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupWorkloads', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('allocationGroupAPIGetCostAllocationGroupWorkloads', 'endTime', endTime)
            const localVarPath = `/v1/cost-reports/allocation-groups/{groupId}/workloads`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = (startTime as any instanceof Date) ?
                    (startTime as any).toISOString() :
                    startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = (endTime as any instanceof Date) ?
                    (endTime as any).toISOString() :
                    endTime;
            }

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists allocation groups.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIListAllocationGroups: async (clusterIds?: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/cost-reports/allocation-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterIds) {
                localVarQueryParameter['clusterIds'] = clusterIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an allocation group for custom report.
         * @param {string} id ID of the allocation group
         * @param {CostreportV1beta1AllocationGroupDetails} allocationGroup Allocation group details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIUpdateAllocationGroup: async (id: string, allocationGroup: CostreportV1beta1AllocationGroupDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('allocationGroupAPIUpdateAllocationGroup', 'id', id)
            // verify required parameter 'allocationGroup' is not null or undefined
            assertParamExists('allocationGroupAPIUpdateAllocationGroup', 'allocationGroup', allocationGroup)
            const localVarPath = `/v1/cost-reports/allocation-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationGroupAPIApi - functional programming interface
 * @export
 */
export const AllocationGroupAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationGroupAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates an allocation group for custom report.
         * @param {CostreportV1beta1AllocationGroupDetails} allocationGroup Allocation group details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPICreateAllocationGroup(allocationGroup: CostreportV1beta1AllocationGroupDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1AllocationGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPICreateAllocationGroup(allocationGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPICreateAllocationGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes an allocation group.
         * @param {string} id ID of the allocation group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIDeleteAllocationGroup(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIDeleteAllocationGroup(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIDeleteAllocationGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets allocation group cost summaries.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [groupId] Allocation group ID. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetAllocationGroupCostSummaries(startTime: string, endTime: string, clusterIds?: Array<string>, groupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetAllocationGroupCostSummariesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetAllocationGroupCostSummaries(startTime, endTime, clusterIds, groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetAllocationGroupCostSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets allocation group timed cost summaries.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [groupId] Allocation group ID. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetAllocationGroupCostTimedSummaries(startTime: string, endTime: string, clusterIds?: Array<string>, groupId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetAllocationGroupCostTimedSummariesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetAllocationGroupCostTimedSummaries(startTime, endTime, clusterIds, groupId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetAllocationGroupCostTimedSummaries']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets allocation group efficiency summary.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetAllocationGroupEfficiencySummary(startTime: string, endTime: string, clusterIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetAllocationGroupEfficiencySummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetAllocationGroupEfficiencySummary(startTime, endTime, clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetAllocationGroupEfficiencySummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets allocation groups timed total cost only.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetAllocationGroupTotalCostTimed(startTime: string, endTime: string, clusterIds?: Array<string>, pageLimit?: string, pageCursor?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetAllocationGroupTotalCostTimedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetAllocationGroupTotalCostTimed(startTime, endTime, clusterIds, pageLimit, pageCursor, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetAllocationGroupTotalCostTimed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workloads of cost allocation group.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetAllocationGroupWorkloadCosts(groupId: string, startTime: string, endTime: string, clusterIds?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetAllocationGroupWorkloadCostsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetAllocationGroupWorkloadCosts(groupId, startTime, endTime, clusterIds, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetAllocationGroupWorkloadCosts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets allocation group efficiency workloads.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [sortField] Name of the field you want to sort
         * @param {AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum} [sortOrder] The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetAllocationGroupWorkloadsEfficiency(groupId: string, startTime: string, endTime: string, clusterIds?: Array<string>, pageLimit?: string, pageCursor?: string, sortField?: string, sortOrder?: AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetAllocationGroupWorkloadsEfficiencyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetAllocationGroupWorkloadsEfficiency(groupId, startTime, endTime, clusterIds, pageLimit, pageCursor, sortField, sortOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetAllocationGroupWorkloadsEfficiency']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets allocation group summary for datatransfer costs.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetCostAllocationGroupDataTransferSummary(startTime: string, endTime: string, clusterIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetCostAllocationGroupDataTransferSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetCostAllocationGroupDataTransferSummary(startTime, endTime, clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetCostAllocationGroupDataTransferSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets workloads of datatransfer cost allocation group.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads(groupId: string, startTime: string, endTime: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetCostAllocationGroupDataTransferWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads(groupId, startTime, endTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deprecated: use /v1/cost-reports/allocation-groups-summaries or /v1/cost-reports/allocation-group-costs.
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetCostAllocationGroupSummary(startTime: string, endTime: string, clusterIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetCostAllocationGroupSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetCostAllocationGroupSummary(startTime, endTime, clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetCostAllocationGroupSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/cost-reports/allocation-groups/{group_id}/workload-costs instead.
         * @param {string} groupId 
         * @param {string} startTime Filter items to include from specified time.
         * @param {string} endTime Filter items to include up to specified time.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async allocationGroupAPIGetCostAllocationGroupWorkloads(groupId: string, startTime: string, endTime: string, clusterIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1GetCostAllocationGroupWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIGetCostAllocationGroupWorkloads(groupId, startTime, endTime, clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIGetCostAllocationGroupWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists allocation groups.
         * @param {Array<string>} [clusterIds] Cluster IDs for filtering. Leave empty for the full list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIListAllocationGroups(clusterIds?: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1ListAllocationGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIListAllocationGroups(clusterIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIListAllocationGroups']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates an allocation group for custom report.
         * @param {string} id ID of the allocation group
         * @param {CostreportV1beta1AllocationGroupDetails} allocationGroup Allocation group details
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationGroupAPIUpdateAllocationGroup(id: string, allocationGroup: CostreportV1beta1AllocationGroupDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostreportV1beta1AllocationGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationGroupAPIUpdateAllocationGroup(id, allocationGroup, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AllocationGroupAPIApi.allocationGroupAPIUpdateAllocationGroup']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AllocationGroupAPIApi - factory interface
 * @export
 */
export const AllocationGroupAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationGroupAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates an allocation group for custom report.
         * @param {AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPICreateAllocationGroup(requestParameters: AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1AllocationGroup> {
            return localVarFp.allocationGroupAPICreateAllocationGroup(requestParameters.allocationGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an allocation group.
         * @param {AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIDeleteAllocationGroup(requestParameters: AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.allocationGroupAPIDeleteAllocationGroup(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets allocation group cost summaries.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummariesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupCostSummaries(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummariesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetAllocationGroupCostSummariesResponse> {
            return localVarFp.allocationGroupAPIGetAllocationGroupCostSummaries(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets allocation group timed cost summaries.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummariesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupCostTimedSummaries(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummariesRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetAllocationGroupCostTimedSummariesResponse> {
            return localVarFp.allocationGroupAPIGetAllocationGroupCostTimedSummaries(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets allocation group efficiency summary.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupEfficiencySummary(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetAllocationGroupEfficiencySummaryResponse> {
            return localVarFp.allocationGroupAPIGetAllocationGroupEfficiencySummary(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets allocation groups timed total cost only.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupTotalCostTimed(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimedRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetAllocationGroupTotalCostTimedResponse> {
            return localVarFp.allocationGroupAPIGetAllocationGroupTotalCostTimed(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workloads of cost allocation group.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCostsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupWorkloadCosts(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCostsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetAllocationGroupWorkloadCostsResponse> {
            return localVarFp.allocationGroupAPIGetAllocationGroupWorkloadCosts(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets allocation group efficiency workloads.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiencyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetAllocationGroupWorkloadsEfficiency(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiencyRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetAllocationGroupWorkloadsEfficiencyResponse> {
            return localVarFp.allocationGroupAPIGetAllocationGroupWorkloadsEfficiency(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets allocation group summary for datatransfer costs.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupDataTransferSummary(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetCostAllocationGroupDataTransferSummaryResponse> {
            return localVarFp.allocationGroupAPIGetCostAllocationGroupDataTransferSummary(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets workloads of datatransfer cost allocation group.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetCostAllocationGroupDataTransferWorkloadsResponse> {
            return localVarFp.allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deprecated: use /v1/cost-reports/allocation-groups-summaries or /v1/cost-reports/allocation-group-costs.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupSummary(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetCostAllocationGroupSummaryResponse> {
            return localVarFp.allocationGroupAPIGetCostAllocationGroupSummary(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary [Deprecated] Use /v1/cost-reports/allocation-groups/{group_id}/workload-costs instead.
         * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        allocationGroupAPIGetCostAllocationGroupWorkloads(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1GetCostAllocationGroupWorkloadsResponse> {
            return localVarFp.allocationGroupAPIGetCostAllocationGroupWorkloads(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists allocation groups.
         * @param {AllocationGroupAPIApiAllocationGroupAPIListAllocationGroupsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIListAllocationGroups(requestParameters: AllocationGroupAPIApiAllocationGroupAPIListAllocationGroupsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1ListAllocationGroupsResponse> {
            return localVarFp.allocationGroupAPIListAllocationGroups(requestParameters.clusterIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an allocation group for custom report.
         * @param {AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationGroupAPIUpdateAllocationGroup(requestParameters: AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroupRequest, options?: RawAxiosRequestConfig): AxiosPromise<CostreportV1beta1AllocationGroup> {
            return localVarFp.allocationGroupAPIUpdateAllocationGroup(requestParameters.id, requestParameters.allocationGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for allocationGroupAPICreateAllocationGroup operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroupRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroupRequest {
    /**
     * Allocation group details
     * @type {CostreportV1beta1AllocationGroupDetails}
     * @memberof AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroup
     */
    readonly allocationGroup: CostreportV1beta1AllocationGroupDetails
}

/**
 * Request parameters for allocationGroupAPIDeleteAllocationGroup operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroupRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroupRequest {
    /**
     * ID of the allocation group
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroup
     */
    readonly id: string
}

/**
 * Request parameters for allocationGroupAPIGetAllocationGroupCostSummaries operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummariesRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummariesRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummaries
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummaries
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummaries
     */
    readonly clusterIds?: Array<string>

    /**
     * Allocation group ID. Leave empty for the full list.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummaries
     */
    readonly groupId?: string
}

/**
 * Request parameters for allocationGroupAPIGetAllocationGroupCostTimedSummaries operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummariesRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummariesRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummaries
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummaries
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummaries
     */
    readonly clusterIds?: Array<string>

    /**
     * Allocation group ID. Leave empty for the full list.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummaries
     */
    readonly groupId?: string
}

/**
 * Request parameters for allocationGroupAPIGetAllocationGroupEfficiencySummary operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummaryRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummaryRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummary
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummary
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummary
     */
    readonly clusterIds?: Array<string>
}

/**
 * Request parameters for allocationGroupAPIGetAllocationGroupTotalCostTimed operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimedRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimedRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimed
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimed
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimed
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimed
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimed
     */
    readonly pageCursor?: string
}

/**
 * Request parameters for allocationGroupAPIGetAllocationGroupWorkloadCosts operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCostsRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCostsRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly groupId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCosts
     */
    readonly sortOrder?: AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum
}

/**
 * Request parameters for allocationGroupAPIGetAllocationGroupWorkloadsEfficiency operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiencyRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiencyRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly groupId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly clusterIds?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly pageCursor?: string

    /**
     * Name of the field you want to sort
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly sortField?: string

    /**
     * The sort order, possible values ASC or DESC, if not provided asc is the default   - ASC: ASC  - asc: desc  - DESC: ASC  - desc: desc
     * @type {'ASC' | 'asc' | 'DESC' | 'desc'}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiency
     */
    readonly sortOrder?: AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum
}

/**
 * Request parameters for allocationGroupAPIGetCostAllocationGroupDataTransferSummary operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummaryRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummaryRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummary
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummary
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummary
     */
    readonly clusterIds?: Array<string>
}

/**
 * Request parameters for allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloadsRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloadsRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloads
     */
    readonly groupId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloads
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloads
     */
    readonly endTime: string
}

/**
 * Request parameters for allocationGroupAPIGetCostAllocationGroupSummary operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummaryRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummaryRequest {
    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummary
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummary
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummary
     */
    readonly clusterIds?: Array<string>
}

/**
 * Request parameters for allocationGroupAPIGetCostAllocationGroupWorkloads operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloadsRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloadsRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloads
     */
    readonly groupId: string

    /**
     * Filter items to include from specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloads
     */
    readonly startTime: string

    /**
     * Filter items to include up to specified time.
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloads
     */
    readonly endTime: string

    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloads
     */
    readonly clusterIds?: Array<string>
}

/**
 * Request parameters for allocationGroupAPIListAllocationGroups operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIListAllocationGroupsRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIListAllocationGroupsRequest {
    /**
     * Cluster IDs for filtering. Leave empty for the full list.
     * @type {Array<string>}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIListAllocationGroups
     */
    readonly clusterIds?: Array<string>
}

/**
 * Request parameters for allocationGroupAPIUpdateAllocationGroup operation in AllocationGroupAPIApi.
 * @export
 * @interface AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroupRequest
 */
export interface AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroupRequest {
    /**
     * ID of the allocation group
     * @type {string}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroup
     */
    readonly id: string

    /**
     * Allocation group details
     * @type {CostreportV1beta1AllocationGroupDetails}
     * @memberof AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroup
     */
    readonly allocationGroup: CostreportV1beta1AllocationGroupDetails
}

/**
 * AllocationGroupAPIApi - object-oriented interface
 * @export
 * @class AllocationGroupAPIApi
 * @extends {BaseAPI}
 */
export class AllocationGroupAPIApi extends BaseAPI {
    /**
     * 
     * @summary Creates an allocation group for custom report.
     * @param {AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPICreateAllocationGroup(requestParameters: AllocationGroupAPIApiAllocationGroupAPICreateAllocationGroupRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPICreateAllocationGroup(requestParameters.allocationGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an allocation group.
     * @param {AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIDeleteAllocationGroup(requestParameters: AllocationGroupAPIApiAllocationGroupAPIDeleteAllocationGroupRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIDeleteAllocationGroup(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets allocation group cost summaries.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummariesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetAllocationGroupCostSummaries(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostSummariesRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetAllocationGroupCostSummaries(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets allocation group timed cost summaries.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummariesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetAllocationGroupCostTimedSummaries(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupCostTimedSummariesRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetAllocationGroupCostTimedSummaries(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets allocation group efficiency summary.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetAllocationGroupEfficiencySummary(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupEfficiencySummaryRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetAllocationGroupEfficiencySummary(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets allocation groups timed total cost only.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetAllocationGroupTotalCostTimed(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupTotalCostTimedRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetAllocationGroupTotalCostTimed(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.pageLimit, requestParameters.pageCursor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workloads of cost allocation group.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCostsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetAllocationGroupWorkloadCosts(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadCostsRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetAllocationGroupWorkloadCosts(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets allocation group efficiency workloads.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiencyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetAllocationGroupWorkloadsEfficiency(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetAllocationGroupWorkloadsEfficiencyRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetAllocationGroupWorkloadsEfficiency(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.sortField, requestParameters.sortOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets allocation group summary for datatransfer costs.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetCostAllocationGroupDataTransferSummary(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferSummaryRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetCostAllocationGroupDataTransferSummary(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets workloads of datatransfer cost allocation group.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupDataTransferWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetCostAllocationGroupDataTransferWorkloads(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deprecated: use /v1/cost-reports/allocation-groups-summaries or /v1/cost-reports/allocation-group-costs.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetCostAllocationGroupSummary(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupSummaryRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetCostAllocationGroupSummary(requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary [Deprecated] Use /v1/cost-reports/allocation-groups/{group_id}/workload-costs instead.
     * @param {AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIGetCostAllocationGroupWorkloads(requestParameters: AllocationGroupAPIApiAllocationGroupAPIGetCostAllocationGroupWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIGetCostAllocationGroupWorkloads(requestParameters.groupId, requestParameters.startTime, requestParameters.endTime, requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists allocation groups.
     * @param {AllocationGroupAPIApiAllocationGroupAPIListAllocationGroupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIListAllocationGroups(requestParameters: AllocationGroupAPIApiAllocationGroupAPIListAllocationGroupsRequest = {}, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIListAllocationGroups(requestParameters.clusterIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an allocation group for custom report.
     * @param {AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationGroupAPIApi
     */
    public allocationGroupAPIUpdateAllocationGroup(requestParameters: AllocationGroupAPIApiAllocationGroupAPIUpdateAllocationGroupRequest, options?: RawAxiosRequestConfig) {
        return AllocationGroupAPIApiFp(this.configuration).allocationGroupAPIUpdateAllocationGroup(requestParameters.id, requestParameters.allocationGroup, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum = typeof AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum[keyof typeof AllocationGroupAPIGetAllocationGroupWorkloadCostsSortOrderEnum];
/**
 * @export
 */
export const AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum = {
    ASC: 'ASC',
    asc: 'asc',
    DESC: 'DESC',
    desc: 'desc'
} as const;
export type AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum = typeof AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum[keyof typeof AllocationGroupAPIGetAllocationGroupWorkloadsEfficiencySortOrderEnum];
