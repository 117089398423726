/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiFeaturesV1ListResponse } from '../models';
// @ts-ignore
import type { CastaiFeaturesV1ResolveMessage } from '../models';
// @ts-ignore
import type { CastaiFeaturesV1ResolveResponse } from '../models';
/**
 * FeaturesAPIApi - axios parameter creator
 * @export
 */
export const FeaturesAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List all available feature flags
         * @param {string} [flagNamePrefix] Prefix to filter flags by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresAPIList: async (flagNamePrefix?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (flagNamePrefix !== undefined) {
                localVarQueryParameter['flagNamePrefix'] = flagNamePrefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resolve resolves a flags for the provided context
         * @param {CastaiFeaturesV1ResolveMessage} message ResolveMessage is the message used to resolve a feature flag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresAPIResolve: async (message: CastaiFeaturesV1ResolveMessage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'message' is not null or undefined
            assertParamExists('featuresAPIResolve', 'message', message)
            const localVarPath = `/v1/features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(message, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesAPIApi - functional programming interface
 * @export
 */
export const FeaturesAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List all available feature flags
         * @param {string} [flagNamePrefix] Prefix to filter flags by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featuresAPIList(flagNamePrefix?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiFeaturesV1ListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featuresAPIList(flagNamePrefix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesAPIApi.featuresAPIList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Resolve resolves a flags for the provided context
         * @param {CastaiFeaturesV1ResolveMessage} message ResolveMessage is the message used to resolve a feature flag.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featuresAPIResolve(message: CastaiFeaturesV1ResolveMessage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiFeaturesV1ResolveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featuresAPIResolve(message, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeaturesAPIApi.featuresAPIResolve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeaturesAPIApi - factory interface
 * @export
 */
export const FeaturesAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary List all available feature flags
         * @param {FeaturesAPIApiFeaturesAPIListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresAPIList(requestParameters: FeaturesAPIApiFeaturesAPIListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiFeaturesV1ListResponse> {
            return localVarFp.featuresAPIList(requestParameters.flagNamePrefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resolve resolves a flags for the provided context
         * @param {FeaturesAPIApiFeaturesAPIResolveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresAPIResolve(requestParameters: FeaturesAPIApiFeaturesAPIResolveRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiFeaturesV1ResolveResponse> {
            return localVarFp.featuresAPIResolve(requestParameters.message, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for featuresAPIList operation in FeaturesAPIApi.
 * @export
 * @interface FeaturesAPIApiFeaturesAPIListRequest
 */
export interface FeaturesAPIApiFeaturesAPIListRequest {
    /**
     * Prefix to filter flags by.
     * @type {string}
     * @memberof FeaturesAPIApiFeaturesAPIList
     */
    readonly flagNamePrefix?: string
}

/**
 * Request parameters for featuresAPIResolve operation in FeaturesAPIApi.
 * @export
 * @interface FeaturesAPIApiFeaturesAPIResolveRequest
 */
export interface FeaturesAPIApiFeaturesAPIResolveRequest {
    /**
     * ResolveMessage is the message used to resolve a feature flag.
     * @type {CastaiFeaturesV1ResolveMessage}
     * @memberof FeaturesAPIApiFeaturesAPIResolve
     */
    readonly message: CastaiFeaturesV1ResolveMessage
}

/**
 * FeaturesAPIApi - object-oriented interface
 * @export
 * @class FeaturesAPIApi
 * @extends {BaseAPI}
 */
export class FeaturesAPIApi extends BaseAPI {
    /**
     * 
     * @summary List all available feature flags
     * @param {FeaturesAPIApiFeaturesAPIListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesAPIApi
     */
    public featuresAPIList(requestParameters: FeaturesAPIApiFeaturesAPIListRequest = {}, options?: RawAxiosRequestConfig) {
        return FeaturesAPIApiFp(this.configuration).featuresAPIList(requestParameters.flagNamePrefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resolve resolves a flags for the provided context
     * @param {FeaturesAPIApiFeaturesAPIResolveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesAPIApi
     */
    public featuresAPIResolve(requestParameters: FeaturesAPIApiFeaturesAPIResolveRequest, options?: RawAxiosRequestConfig) {
        return FeaturesAPIApiFp(this.configuration).featuresAPIResolve(requestParameters.message, options).then((request) => request(this.axios, this.basePath));
    }
}

