import { ReactElement, ReactNode } from 'react';

import { SxProps } from '@mui/system';

import { HexIconNotification } from 'components/hex-icons';
import { StatusMessage } from 'components/messages/StatusMessage';

type Props = {
  icon?: ReactElement;
  title?: ReactNode;
  body?: ReactNode;
  sx?: SxProps;
};

export const NoNotifications = ({
  icon = <HexIconNotification />,
  title = 'No new notifications',
  body = 'Check back later for any new notifications.',
  sx,
}: Props) => {
  return (
    <StatusMessage
      icon={icon}
      title={title}
      body={body}
      sx={sx}
      bodyProps={{ mt: 0 }}
    />
  );
};
