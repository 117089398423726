import dayjs from 'dayjs';

import { ValidatedPreset } from './types';
import { DatePeriod, FilterPreset, RangeCondition } from '../../types';
import { rangeToUtc } from '../../utils';

export const validatePreset = (
  preset: FilterPreset,
  timezone: string,
  range: DatePeriod,
  startMonth?: RangeCondition,
  endMonth?: RangeCondition,
  maxNumberOfDays?: number
): ValidatedPreset => {
  let error;
  const rangeStartDate = dayjs.utc(preset.date(timezone).from).tz(timezone);
  const rangeEndDate = dayjs.utc(preset.date(timezone).to).tz(timezone);

  const startDate =
    typeof startMonth === 'function'
      ? startMonth({ range: rangeToUtc(range), timezone })
      : startMonth;

  if (startDate && rangeStartDate.isBefore(startDate)) {
    error = 'Preset start date is outside of picker boundaries';
  }

  const endDate =
    typeof endMonth === 'function'
      ? endMonth({ range: rangeToUtc(range), timezone })
      : endMonth;

  if (endDate && rangeEndDate.isAfter(endDate)) {
    error = 'Preset end date is outside of picker boundaries';
  }

  if (
    maxNumberOfDays &&
    Math.abs(rangeEndDate.diff(rangeStartDate, 'day')) > maxNumberOfDays - 1
  ) {
    error = 'Range is above max days limit';
  }

  return { ...preset, error };
};
