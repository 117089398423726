import { useCallback, useEffect, useState } from 'react';

import { Dayjs } from 'dayjs';
import { TZDate } from 'react-day-picker';

import { validateDateInput } from './_utils';
import { useDatePickerContext } from './useDatePickerContext';
import Input, { InputProps } from '../../controls/input/Input';
import { dateToInputString, inputStringToDayjs } from '../utils';

type DateTimeInputProps = InputProps & {
  dateToSyncWith?: TZDate;
  dateValidator: (value: Dayjs) => string | undefined;
  onDateChange: (date?: Dayjs) => void;
};

export const DateTimeInput = ({
  dateToSyncWith,
  dateValidator,
  onDateChange,
  ...rest
}: DateTimeInputProps) => {
  const ctx = useDatePickerContext();

  const [value, setValue] = useState(
    dateToSyncWith
      ? dateToInputString(dateToSyncWith, ctx.format, ctx.timezone)
      : ''
  );
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    // when date picker value is changed outside of input sync it with input value
    if (dateToSyncWith) {
      const dateString = dateToInputString(
        dateToSyncWith,
        ctx.format,
        ctx.timezone
      );

      if (value !== dateString) {
        setValue(dateString);
        setError(undefined);
      }
    } else {
      setValue('');
      setError(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateToSyncWith]);

  const validateInput = useCallback(() => {
    if (!value) {
      onDateChange();
      setError(undefined);
      return;
    }

    const parsed = inputStringToDayjs(value, ctx.format, ctx.timezone);

    if (!validateDateInput(value, ctx.allowTimeInput) || !parsed.isValid()) {
      setError('Invalid date');
    } else {
      const error = dateValidator(parsed);

      if (error) {
        setError(error);
      } else {
        onDateChange(parsed);
        setError(undefined);
      }
    }
  }, [
    ctx.allowTimeInput,
    ctx.format,
    ctx.timezone,
    dateValidator,
    onDateChange,
    value,
  ]);

  return (
    <Input
      size="medium"
      placeholder={`yyyy/mm/dd${ctx.allowTimeInput ? ' hh:mm' : ''}`}
      testId="date-picker-input"
      onChange={(_, value) => setValue(value)}
      value={value}
      error={error}
      onBlur={validateInput}
      {...rest}
    />
  );
};
