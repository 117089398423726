/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CastaiAuthtokenV1beta1AuthToken } from '../models';
// @ts-ignore
import type { CastaiAuthtokenV1beta1AuthTokenUpdate } from '../models';
// @ts-ignore
import type { CastaiAuthtokenV1beta1ListAuthTokensResponse } from '../models';
/**
 * AuthTokenAPIApi - axios parameter creator
 * @export
 */
export const AuthTokenAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary CreateAuthToken creates a new api auth token.
         * @param {CastaiAuthtokenV1beta1AuthToken} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPICreateAuthToken: async (item: CastaiAuthtokenV1beta1AuthToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('authTokenAPICreateAuthToken', 'item', item)
            const localVarPath = `/v1/auth/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(item, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes auth token.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIDeleteAuthToken: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authTokenAPIDeleteAuthToken', 'id', id)
            const localVarPath = `/v1/auth/tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the specified auth token.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIGetAuthToken: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authTokenAPIGetAuthToken', 'id', id)
            const localVarPath = `/v1/auth/tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists user auth tokens.
         * @param {string} [userId] User id to filter by, if this is set we will only return tokens that have this user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIListAuthTokens: async (userId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/auth/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the specified auth token.
         * @param {string} id 
         * @param {CastaiAuthtokenV1beta1AuthTokenUpdate} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIUpdateAuthToken: async (id: string, item: CastaiAuthtokenV1beta1AuthTokenUpdate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('authTokenAPIUpdateAuthToken', 'id', id)
            // verify required parameter 'item' is not null or undefined
            assertParamExists('authTokenAPIUpdateAuthToken', 'item', item)
            const localVarPath = `/v1/auth/tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(item, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthTokenAPIApi - functional programming interface
 * @export
 */
export const AuthTokenAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthTokenAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary CreateAuthToken creates a new api auth token.
         * @param {CastaiAuthtokenV1beta1AuthToken} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenAPICreateAuthToken(item: CastaiAuthtokenV1beta1AuthToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAuthtokenV1beta1AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenAPICreateAuthToken(item, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthTokenAPIApi.authTokenAPICreateAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes auth token.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenAPIDeleteAuthToken(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenAPIDeleteAuthToken(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthTokenAPIApi.authTokenAPIDeleteAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Retrieves the specified auth token.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenAPIGetAuthToken(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAuthtokenV1beta1AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenAPIGetAuthToken(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthTokenAPIApi.authTokenAPIGetAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Lists user auth tokens.
         * @param {string} [userId] User id to filter by, if this is set we will only return tokens that have this user id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenAPIListAuthTokens(userId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAuthtokenV1beta1ListAuthTokensResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenAPIListAuthTokens(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthTokenAPIApi.authTokenAPIListAuthTokens']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates the specified auth token.
         * @param {string} id 
         * @param {CastaiAuthtokenV1beta1AuthTokenUpdate} item 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authTokenAPIUpdateAuthToken(id: string, item: CastaiAuthtokenV1beta1AuthTokenUpdate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CastaiAuthtokenV1beta1AuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authTokenAPIUpdateAuthToken(id, item, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthTokenAPIApi.authTokenAPIUpdateAuthToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthTokenAPIApi - factory interface
 * @export
 */
export const AuthTokenAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthTokenAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary CreateAuthToken creates a new api auth token.
         * @param {AuthTokenAPIApiAuthTokenAPICreateAuthTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPICreateAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPICreateAuthTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAuthtokenV1beta1AuthToken> {
            return localVarFp.authTokenAPICreateAuthToken(requestParameters.item, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes auth token.
         * @param {AuthTokenAPIApiAuthTokenAPIDeleteAuthTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIDeleteAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPIDeleteAuthTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.authTokenAPIDeleteAuthToken(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the specified auth token.
         * @param {AuthTokenAPIApiAuthTokenAPIGetAuthTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIGetAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPIGetAuthTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAuthtokenV1beta1AuthToken> {
            return localVarFp.authTokenAPIGetAuthToken(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists user auth tokens.
         * @param {AuthTokenAPIApiAuthTokenAPIListAuthTokensRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIListAuthTokens(requestParameters: AuthTokenAPIApiAuthTokenAPIListAuthTokensRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAuthtokenV1beta1ListAuthTokensResponse> {
            return localVarFp.authTokenAPIListAuthTokens(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the specified auth token.
         * @param {AuthTokenAPIApiAuthTokenAPIUpdateAuthTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenAPIUpdateAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPIUpdateAuthTokenRequest, options?: RawAxiosRequestConfig): AxiosPromise<CastaiAuthtokenV1beta1AuthToken> {
            return localVarFp.authTokenAPIUpdateAuthToken(requestParameters.id, requestParameters.item, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authTokenAPICreateAuthToken operation in AuthTokenAPIApi.
 * @export
 * @interface AuthTokenAPIApiAuthTokenAPICreateAuthTokenRequest
 */
export interface AuthTokenAPIApiAuthTokenAPICreateAuthTokenRequest {
    /**
     * 
     * @type {CastaiAuthtokenV1beta1AuthToken}
     * @memberof AuthTokenAPIApiAuthTokenAPICreateAuthToken
     */
    readonly item: CastaiAuthtokenV1beta1AuthToken
}

/**
 * Request parameters for authTokenAPIDeleteAuthToken operation in AuthTokenAPIApi.
 * @export
 * @interface AuthTokenAPIApiAuthTokenAPIDeleteAuthTokenRequest
 */
export interface AuthTokenAPIApiAuthTokenAPIDeleteAuthTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenAPIApiAuthTokenAPIDeleteAuthToken
     */
    readonly id: string
}

/**
 * Request parameters for authTokenAPIGetAuthToken operation in AuthTokenAPIApi.
 * @export
 * @interface AuthTokenAPIApiAuthTokenAPIGetAuthTokenRequest
 */
export interface AuthTokenAPIApiAuthTokenAPIGetAuthTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenAPIApiAuthTokenAPIGetAuthToken
     */
    readonly id: string
}

/**
 * Request parameters for authTokenAPIListAuthTokens operation in AuthTokenAPIApi.
 * @export
 * @interface AuthTokenAPIApiAuthTokenAPIListAuthTokensRequest
 */
export interface AuthTokenAPIApiAuthTokenAPIListAuthTokensRequest {
    /**
     * User id to filter by, if this is set we will only return tokens that have this user id.
     * @type {string}
     * @memberof AuthTokenAPIApiAuthTokenAPIListAuthTokens
     */
    readonly userId?: string
}

/**
 * Request parameters for authTokenAPIUpdateAuthToken operation in AuthTokenAPIApi.
 * @export
 * @interface AuthTokenAPIApiAuthTokenAPIUpdateAuthTokenRequest
 */
export interface AuthTokenAPIApiAuthTokenAPIUpdateAuthTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenAPIApiAuthTokenAPIUpdateAuthToken
     */
    readonly id: string

    /**
     * 
     * @type {CastaiAuthtokenV1beta1AuthTokenUpdate}
     * @memberof AuthTokenAPIApiAuthTokenAPIUpdateAuthToken
     */
    readonly item: CastaiAuthtokenV1beta1AuthTokenUpdate
}

/**
 * AuthTokenAPIApi - object-oriented interface
 * @export
 * @class AuthTokenAPIApi
 * @extends {BaseAPI}
 */
export class AuthTokenAPIApi extends BaseAPI {
    /**
     * 
     * @summary CreateAuthToken creates a new api auth token.
     * @param {AuthTokenAPIApiAuthTokenAPICreateAuthTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthTokenAPIApi
     */
    public authTokenAPICreateAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPICreateAuthTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthTokenAPIApiFp(this.configuration).authTokenAPICreateAuthToken(requestParameters.item, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes auth token.
     * @param {AuthTokenAPIApiAuthTokenAPIDeleteAuthTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthTokenAPIApi
     */
    public authTokenAPIDeleteAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPIDeleteAuthTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthTokenAPIApiFp(this.configuration).authTokenAPIDeleteAuthToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves the specified auth token.
     * @param {AuthTokenAPIApiAuthTokenAPIGetAuthTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthTokenAPIApi
     */
    public authTokenAPIGetAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPIGetAuthTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthTokenAPIApiFp(this.configuration).authTokenAPIGetAuthToken(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists user auth tokens.
     * @param {AuthTokenAPIApiAuthTokenAPIListAuthTokensRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthTokenAPIApi
     */
    public authTokenAPIListAuthTokens(requestParameters: AuthTokenAPIApiAuthTokenAPIListAuthTokensRequest = {}, options?: RawAxiosRequestConfig) {
        return AuthTokenAPIApiFp(this.configuration).authTokenAPIListAuthTokens(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the specified auth token.
     * @param {AuthTokenAPIApiAuthTokenAPIUpdateAuthTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthTokenAPIApi
     */
    public authTokenAPIUpdateAuthToken(requestParameters: AuthTokenAPIApiAuthTokenAPIUpdateAuthTokenRequest, options?: RawAxiosRequestConfig) {
        return AuthTokenAPIApiFp(this.configuration).authTokenAPIUpdateAuthToken(requestParameters.id, requestParameters.item, options).then((request) => request(this.axios, this.basePath));
    }
}

