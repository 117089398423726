/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { PoliciesV1GetClusterNodeConstraintsResponse } from '../models';
// @ts-ignore
import type { PoliciesV1Policies } from '../models';
/**
 * PoliciesAPIApi - axios parameter creator
 * @export
 */
export const PoliciesAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets all viable settings to be used for the Node Constraints feature.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesAPIGetClusterNodeConstraints: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('policiesAPIGetClusterNodeConstraints', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/node-constraints`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets policies configuration for the target cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesAPIGetClusterPolicies: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('policiesAPIGetClusterPolicies', 'clusterId', clusterId)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/policies`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upsert cluster\'s policies configuration.
         * @param {string} clusterId The ID of the cluster.
         * @param {PoliciesV1Policies} policies Policies details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesAPIUpsertClusterPolicies: async (clusterId: string, policies: PoliciesV1Policies, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('policiesAPIUpsertClusterPolicies', 'clusterId', clusterId)
            // verify required parameter 'policies' is not null or undefined
            assertParamExists('policiesAPIUpsertClusterPolicies', 'policies', policies)
            const localVarPath = `/v1/kubernetes/clusters/{clusterId}/policies`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoliciesAPIApi - functional programming interface
 * @export
 */
export const PoliciesAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoliciesAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets all viable settings to be used for the Node Constraints feature.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesAPIGetClusterNodeConstraints(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoliciesV1GetClusterNodeConstraintsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesAPIGetClusterNodeConstraints(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoliciesAPIApi.policiesAPIGetClusterNodeConstraints']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Gets policies configuration for the target cluster.
         * @param {string} clusterId The ID of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesAPIGetClusterPolicies(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoliciesV1Policies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesAPIGetClusterPolicies(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoliciesAPIApi.policiesAPIGetClusterPolicies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upsert cluster\'s policies configuration.
         * @param {string} clusterId The ID of the cluster.
         * @param {PoliciesV1Policies} policies Policies details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async policiesAPIUpsertClusterPolicies(clusterId: string, policies: PoliciesV1Policies, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoliciesV1Policies>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.policiesAPIUpsertClusterPolicies(clusterId, policies, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PoliciesAPIApi.policiesAPIUpsertClusterPolicies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PoliciesAPIApi - factory interface
 * @export
 */
export const PoliciesAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoliciesAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets all viable settings to be used for the Node Constraints feature.
         * @param {PoliciesAPIApiPoliciesAPIGetClusterNodeConstraintsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesAPIGetClusterNodeConstraints(requestParameters: PoliciesAPIApiPoliciesAPIGetClusterNodeConstraintsRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoliciesV1GetClusterNodeConstraintsResponse> {
            return localVarFp.policiesAPIGetClusterNodeConstraints(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets policies configuration for the target cluster.
         * @param {PoliciesAPIApiPoliciesAPIGetClusterPoliciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesAPIGetClusterPolicies(requestParameters: PoliciesAPIApiPoliciesAPIGetClusterPoliciesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoliciesV1Policies> {
            return localVarFp.policiesAPIGetClusterPolicies(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upsert cluster\'s policies configuration.
         * @param {PoliciesAPIApiPoliciesAPIUpsertClusterPoliciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        policiesAPIUpsertClusterPolicies(requestParameters: PoliciesAPIApiPoliciesAPIUpsertClusterPoliciesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PoliciesV1Policies> {
            return localVarFp.policiesAPIUpsertClusterPolicies(requestParameters.clusterId, requestParameters.policies, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for policiesAPIGetClusterNodeConstraints operation in PoliciesAPIApi.
 * @export
 * @interface PoliciesAPIApiPoliciesAPIGetClusterNodeConstraintsRequest
 */
export interface PoliciesAPIApiPoliciesAPIGetClusterNodeConstraintsRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PoliciesAPIApiPoliciesAPIGetClusterNodeConstraints
     */
    readonly clusterId: string
}

/**
 * Request parameters for policiesAPIGetClusterPolicies operation in PoliciesAPIApi.
 * @export
 * @interface PoliciesAPIApiPoliciesAPIGetClusterPoliciesRequest
 */
export interface PoliciesAPIApiPoliciesAPIGetClusterPoliciesRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PoliciesAPIApiPoliciesAPIGetClusterPolicies
     */
    readonly clusterId: string
}

/**
 * Request parameters for policiesAPIUpsertClusterPolicies operation in PoliciesAPIApi.
 * @export
 * @interface PoliciesAPIApiPoliciesAPIUpsertClusterPoliciesRequest
 */
export interface PoliciesAPIApiPoliciesAPIUpsertClusterPoliciesRequest {
    /**
     * The ID of the cluster.
     * @type {string}
     * @memberof PoliciesAPIApiPoliciesAPIUpsertClusterPolicies
     */
    readonly clusterId: string

    /**
     * Policies details.
     * @type {PoliciesV1Policies}
     * @memberof PoliciesAPIApiPoliciesAPIUpsertClusterPolicies
     */
    readonly policies: PoliciesV1Policies
}

/**
 * PoliciesAPIApi - object-oriented interface
 * @export
 * @class PoliciesAPIApi
 * @extends {BaseAPI}
 */
export class PoliciesAPIApi extends BaseAPI {
    /**
     * 
     * @summary Gets all viable settings to be used for the Node Constraints feature.
     * @param {PoliciesAPIApiPoliciesAPIGetClusterNodeConstraintsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesAPIApi
     */
    public policiesAPIGetClusterNodeConstraints(requestParameters: PoliciesAPIApiPoliciesAPIGetClusterNodeConstraintsRequest, options?: RawAxiosRequestConfig) {
        return PoliciesAPIApiFp(this.configuration).policiesAPIGetClusterNodeConstraints(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets policies configuration for the target cluster.
     * @param {PoliciesAPIApiPoliciesAPIGetClusterPoliciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesAPIApi
     */
    public policiesAPIGetClusterPolicies(requestParameters: PoliciesAPIApiPoliciesAPIGetClusterPoliciesRequest, options?: RawAxiosRequestConfig) {
        return PoliciesAPIApiFp(this.configuration).policiesAPIGetClusterPolicies(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upsert cluster\'s policies configuration.
     * @param {PoliciesAPIApiPoliciesAPIUpsertClusterPoliciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesAPIApi
     */
    public policiesAPIUpsertClusterPolicies(requestParameters: PoliciesAPIApiPoliciesAPIUpsertClusterPoliciesRequest, options?: RawAxiosRequestConfig) {
        return PoliciesAPIApiFp(this.configuration).policiesAPIUpsertClusterPolicies(requestParameters.clusterId, requestParameters.policies, options).then((request) => request(this.axios, this.basePath));
    }
}

