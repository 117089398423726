/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1GetAgentStatusResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1GetInstallCmdResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1GetWorkloadResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1GetWorkloadsSummaryResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1ListWorkloadEventsResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1ListWorkloadScalingPoliciesResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1ListWorkloadsResponse } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1NewWorkloadScalingPolicy } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1UpdateWorkloadResponseV2 } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1UpdateWorkloadScalingPolicy } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1UpdateWorkloadV2 } from '../models';
// @ts-ignore
import type { WorkloadoptimizationV1WorkloadScalingPolicy } from '../models';
/**
 * WorkloadOptimizationAPIApi - axios parameter creator
 * @export
 */
export const WorkloadOptimizationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assigns the provided list of workload IDs to the specified policy.
         * @param {string} clusterId Cluster ID associated with the scaling policy.
         * @param {string} policyId Policy ID associated with the scaling policy.
         * @param {WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIAssignScalingPolicyWorkloads: async (clusterId: string, policyId: string, body: WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIAssignScalingPolicyWorkloads', 'clusterId', clusterId)
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('workloadOptimizationAPIAssignScalingPolicyWorkloads', 'policyId', policyId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('workloadOptimizationAPIAssignScalingPolicyWorkloads', 'body', body)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/policies/{policyId}/workloads`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a scaling policy that can be assigned to workloads at a later time.
         * @param {string} clusterId 
         * @param {WorkloadoptimizationV1NewWorkloadScalingPolicy} policy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPICreateWorkloadScalingPolicy: async (clusterId: string, policy: WorkloadoptimizationV1NewWorkloadScalingPolicy, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPICreateWorkloadScalingPolicy', 'clusterId', clusterId)
            // verify required parameter 'policy' is not null or undefined
            assertParamExists('workloadOptimizationAPICreateWorkloadScalingPolicy', 'policy', policy)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/policies`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a scaling policy.
         * @param {string} clusterId 
         * @param {string} policyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIDeleteWorkloadScalingPolicy: async (clusterId: string, policyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIDeleteWorkloadScalingPolicy', 'clusterId', clusterId)
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('workloadOptimizationAPIDeleteWorkloadScalingPolicy', 'policyId', policyId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/policies/{policyId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns status of workload-autoscaler agent.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetAgentStatus: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetAgentStatus', 'clusterId', clusterId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/components/workload-autoscaler`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns workload-autoscaler installation command.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetInstallCmd: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetInstallCmd', 'clusterId', clusterId)
            const localVarPath = `/v1/workload-autoscaling/scripts/workload-autoscaler-install`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns workload-autoscaler installation script.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetInstallScript: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/workload-autoscaling/scripts/workload-autoscaler-install.sh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a workload by a given id.
         * @param {string} clusterId 
         * @param {string} workloadId 
         * @param {boolean} [includeMetrics] 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetWorkload: async (clusterId: string, workloadId: string, includeMetrics?: boolean, fromTime?: string, toTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetWorkload', 'clusterId', clusterId)
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetWorkload', 'workloadId', workloadId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/workloads/{workloadId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (includeMetrics !== undefined) {
                localVarQueryParameter['includeMetrics'] = includeMetrics;
            }

            if (fromTime !== undefined) {
                localVarQueryParameter['fromTime'] = (fromTime as any instanceof Date) ?
                    (fromTime as any).toISOString() :
                    fromTime;
            }

            if (toTime !== undefined) {
                localVarQueryParameter['toTime'] = (toTime as any instanceof Date) ?
                    (toTime as any).toISOString() :
                    toTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns scaling policy.
         * @param {string} clusterId 
         * @param {string} policyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetWorkloadScalingPolicy: async (clusterId: string, policyId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetWorkloadScalingPolicy', 'clusterId', clusterId)
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetWorkloadScalingPolicy', 'policyId', policyId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/policies/{policyId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns workloads optimization summary.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetWorkloadsSummary: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIGetWorkloadsSummary', 'clusterId', clusterId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/workloads-summary`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of workload events for given cluster.
         * @param {string} clusterId 
         * @param {string} [workloadId] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [workloadName] 
         * @param {Array<WorkloadOptimizationAPIListWorkloadEventsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIListWorkloadEvents: async (clusterId: string, workloadId?: string, pageLimit?: string, pageCursor?: string, fromDate?: string, toDate?: string, workloadName?: string, type?: Array<WorkloadOptimizationAPIListWorkloadEventsTypeEnum>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIListWorkloadEvents', 'clusterId', clusterId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/workload-events`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (workloadId !== undefined) {
                localVarQueryParameter['workloadId'] = workloadId;
            }

            if (pageLimit !== undefined) {
                localVarQueryParameter['page.limit'] = pageLimit;
            }

            if (pageCursor !== undefined) {
                localVarQueryParameter['page.cursor'] = pageCursor;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (workloadName !== undefined) {
                localVarQueryParameter['workloadName'] = workloadName;
            }

            if (type) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of workload scaling policies for given cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIListWorkloadScalingPolicies: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIListWorkloadScalingPolicies', 'clusterId', clusterId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/policies`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of workloads for the given cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIListWorkloads: async (clusterId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIListWorkloads', 'clusterId', clusterId)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/workloads`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a scaling policy.
         * @param {string} clusterId Cluster ID associated with the scaling policy.
         * @param {string} policyId Policy ID associated with the scaling policy.
         * @param {WorkloadoptimizationV1UpdateWorkloadScalingPolicy} policy The values to configure for the policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIUpdateWorkloadScalingPolicy: async (clusterId: string, policyId: string, policy: WorkloadoptimizationV1UpdateWorkloadScalingPolicy, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIUpdateWorkloadScalingPolicy', 'clusterId', clusterId)
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('workloadOptimizationAPIUpdateWorkloadScalingPolicy', 'policyId', policyId)
            // verify required parameter 'policy' is not null or undefined
            assertParamExists('workloadOptimizationAPIUpdateWorkloadScalingPolicy', 'policy', policy)
            const localVarPath = `/v1/workload-autoscaling/clusters/{clusterId}/policies/{policyId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(policy, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a CAST AI managed workload V2.
         * @param {string} clusterId 
         * @param {string} workloadId 
         * @param {WorkloadoptimizationV1UpdateWorkloadV2} workload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIUpdateWorkloadV2: async (clusterId: string, workloadId: string, workload: WorkloadoptimizationV1UpdateWorkloadV2, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('workloadOptimizationAPIUpdateWorkloadV2', 'clusterId', clusterId)
            // verify required parameter 'workloadId' is not null or undefined
            assertParamExists('workloadOptimizationAPIUpdateWorkloadV2', 'workloadId', workloadId)
            // verify required parameter 'workload' is not null or undefined
            assertParamExists('workloadOptimizationAPIUpdateWorkloadV2', 'workload', workload)
            const localVarPath = `/v2/workload-autoscaling/clusters/{clusterId}/workloads/{workloadId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"workloadId"}}`, encodeURIComponent(String(workloadId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(workload, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkloadOptimizationAPIApi - functional programming interface
 * @export
 */
export const WorkloadOptimizationAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkloadOptimizationAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assigns the provided list of workload IDs to the specified policy.
         * @param {string} clusterId Cluster ID associated with the scaling policy.
         * @param {string} policyId Policy ID associated with the scaling policy.
         * @param {WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIAssignScalingPolicyWorkloads(clusterId: string, policyId: string, body: WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIAssignScalingPolicyWorkloads(clusterId, policyId, body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIAssignScalingPolicyWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Creates a scaling policy that can be assigned to workloads at a later time.
         * @param {string} clusterId 
         * @param {WorkloadoptimizationV1NewWorkloadScalingPolicy} policy 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPICreateWorkloadScalingPolicy(clusterId: string, policy: WorkloadoptimizationV1NewWorkloadScalingPolicy, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1WorkloadScalingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPICreateWorkloadScalingPolicy(clusterId, policy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPICreateWorkloadScalingPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Deletes a scaling policy.
         * @param {string} clusterId 
         * @param {string} policyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIDeleteWorkloadScalingPolicy(clusterId: string, policyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIDeleteWorkloadScalingPolicy(clusterId, policyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIDeleteWorkloadScalingPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns status of workload-autoscaler agent.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIGetAgentStatus(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1GetAgentStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIGetAgentStatus(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIGetAgentStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns workload-autoscaler installation command.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIGetInstallCmd(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1GetInstallCmdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIGetInstallCmd(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIGetInstallCmd']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns workload-autoscaler installation script.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIGetInstallScript(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIGetInstallScript(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIGetInstallScript']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a workload by a given id.
         * @param {string} clusterId 
         * @param {string} workloadId 
         * @param {boolean} [includeMetrics] 
         * @param {string} [fromTime] 
         * @param {string} [toTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIGetWorkload(clusterId: string, workloadId: string, includeMetrics?: boolean, fromTime?: string, toTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1GetWorkloadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIGetWorkload(clusterId, workloadId, includeMetrics, fromTime, toTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIGetWorkload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns scaling policy.
         * @param {string} clusterId 
         * @param {string} policyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIGetWorkloadScalingPolicy(clusterId: string, policyId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1WorkloadScalingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIGetWorkloadScalingPolicy(clusterId, policyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIGetWorkloadScalingPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns workloads optimization summary.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIGetWorkloadsSummary(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1GetWorkloadsSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIGetWorkloadsSummary(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIGetWorkloadsSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of workload events for given cluster.
         * @param {string} clusterId 
         * @param {string} [workloadId] 
         * @param {string} [pageLimit] 
         * @param {string} [pageCursor] Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [workloadName] 
         * @param {Array<WorkloadOptimizationAPIListWorkloadEventsTypeEnum>} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIListWorkloadEvents(clusterId: string, workloadId?: string, pageLimit?: string, pageCursor?: string, fromDate?: string, toDate?: string, workloadName?: string, type?: Array<WorkloadOptimizationAPIListWorkloadEventsTypeEnum>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1ListWorkloadEventsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIListWorkloadEvents(clusterId, workloadId, pageLimit, pageCursor, fromDate, toDate, workloadName, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIListWorkloadEvents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of workload scaling policies for given cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIListWorkloadScalingPolicies(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1ListWorkloadScalingPoliciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIListWorkloadScalingPolicies(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIListWorkloadScalingPolicies']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Returns a list of workloads for the given cluster.
         * @param {string} clusterId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIListWorkloads(clusterId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1ListWorkloadsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIListWorkloads(clusterId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIListWorkloads']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a scaling policy.
         * @param {string} clusterId Cluster ID associated with the scaling policy.
         * @param {string} policyId Policy ID associated with the scaling policy.
         * @param {WorkloadoptimizationV1UpdateWorkloadScalingPolicy} policy The values to configure for the policy.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIUpdateWorkloadScalingPolicy(clusterId: string, policyId: string, policy: WorkloadoptimizationV1UpdateWorkloadScalingPolicy, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1WorkloadScalingPolicy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIUpdateWorkloadScalingPolicy(clusterId, policyId, policy, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIUpdateWorkloadScalingPolicy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Updates a CAST AI managed workload V2.
         * @param {string} clusterId 
         * @param {string} workloadId 
         * @param {WorkloadoptimizationV1UpdateWorkloadV2} workload 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadOptimizationAPIUpdateWorkloadV2(clusterId: string, workloadId: string, workload: WorkloadoptimizationV1UpdateWorkloadV2, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkloadoptimizationV1UpdateWorkloadResponseV2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadOptimizationAPIUpdateWorkloadV2(clusterId, workloadId, workload, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadOptimizationAPIApi.workloadOptimizationAPIUpdateWorkloadV2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkloadOptimizationAPIApi - factory interface
 * @export
 */
export const WorkloadOptimizationAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkloadOptimizationAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Assigns the provided list of workload IDs to the specified policy.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIAssignScalingPolicyWorkloads(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.workloadOptimizationAPIAssignScalingPolicyWorkloads(requestParameters.clusterId, requestParameters.policyId, requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a scaling policy that can be assigned to workloads at a later time.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPICreateWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1WorkloadScalingPolicy> {
            return localVarFp.workloadOptimizationAPICreateWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a scaling policy.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIDeleteWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.workloadOptimizationAPIDeleteWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns status of workload-autoscaler agent.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetAgentStatus(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatusRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1GetAgentStatusResponse> {
            return localVarFp.workloadOptimizationAPIGetAgentStatus(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns workload-autoscaler installation command.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetInstallCmd(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmdRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1GetInstallCmdResponse> {
            return localVarFp.workloadOptimizationAPIGetInstallCmd(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns workload-autoscaler installation script.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetInstallScript(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.workloadOptimizationAPIGetInstallScript(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a workload by a given id.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetWorkload(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1GetWorkloadResponse> {
            return localVarFp.workloadOptimizationAPIGetWorkload(requestParameters.clusterId, requestParameters.workloadId, requestParameters.includeMetrics, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns scaling policy.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1WorkloadScalingPolicy> {
            return localVarFp.workloadOptimizationAPIGetWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns workloads optimization summary.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIGetWorkloadsSummary(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1GetWorkloadsSummaryResponse> {
            return localVarFp.workloadOptimizationAPIGetWorkloadsSummary(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of workload events for given cluster.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEventsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIListWorkloadEvents(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEventsRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1ListWorkloadEventsResponse> {
            return localVarFp.workloadOptimizationAPIListWorkloadEvents(requestParameters.clusterId, requestParameters.workloadId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.fromDate, requestParameters.toDate, requestParameters.workloadName, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of workload scaling policies for given cluster.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPoliciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIListWorkloadScalingPolicies(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPoliciesRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1ListWorkloadScalingPoliciesResponse> {
            return localVarFp.workloadOptimizationAPIListWorkloadScalingPolicies(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of workloads for the given cluster.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIListWorkloads(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadsRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1ListWorkloadsResponse> {
            return localVarFp.workloadOptimizationAPIListWorkloads(requestParameters.clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a scaling policy.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIUpdateWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1WorkloadScalingPolicy> {
            return localVarFp.workloadOptimizationAPIUpdateWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policyId, requestParameters.policy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a CAST AI managed workload V2.
         * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadOptimizationAPIUpdateWorkloadV2(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2Request, options?: RawAxiosRequestConfig): AxiosPromise<WorkloadoptimizationV1UpdateWorkloadResponseV2> {
            return localVarFp.workloadOptimizationAPIUpdateWorkloadV2(requestParameters.clusterId, requestParameters.workloadId, requestParameters.workload, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for workloadOptimizationAPIAssignScalingPolicyWorkloads operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest {
    /**
     * Cluster ID associated with the scaling policy.
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloads
     */
    readonly clusterId: string

    /**
     * Policy ID associated with the scaling policy.
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloads
     */
    readonly policyId: string

    /**
     * 
     * @type {WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloads
     */
    readonly body: WorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest
}

/**
 * Request parameters for workloadOptimizationAPICreateWorkloadScalingPolicy operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicyRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicy
     */
    readonly clusterId: string

    /**
     * 
     * @type {WorkloadoptimizationV1NewWorkloadScalingPolicy}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicy
     */
    readonly policy: WorkloadoptimizationV1NewWorkloadScalingPolicy
}

/**
 * Request parameters for workloadOptimizationAPIDeleteWorkloadScalingPolicy operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicyRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicy
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicy
     */
    readonly policyId: string
}

/**
 * Request parameters for workloadOptimizationAPIGetAgentStatus operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatusRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatus
     */
    readonly clusterId: string
}

/**
 * Request parameters for workloadOptimizationAPIGetInstallCmd operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmdRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmdRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmd
     */
    readonly clusterId: string
}

/**
 * Request parameters for workloadOptimizationAPIGetWorkload operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkload
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkload
     */
    readonly workloadId: string

    /**
     * 
     * @type {boolean}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkload
     */
    readonly includeMetrics?: boolean

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkload
     */
    readonly fromTime?: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkload
     */
    readonly toTime?: string
}

/**
 * Request parameters for workloadOptimizationAPIGetWorkloadScalingPolicy operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicyRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicyRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicy
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicy
     */
    readonly policyId: string
}

/**
 * Request parameters for workloadOptimizationAPIGetWorkloadsSummary operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummaryRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummary
     */
    readonly clusterId: string
}

/**
 * Request parameters for workloadOptimizationAPIListWorkloadEvents operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEventsRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEventsRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly workloadId?: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly pageLimit?: string

    /**
     * Cursor that defines token indicating where to start the next page. Empty value indicates to start from beginning of the dataset.
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly pageCursor?: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly fromDate?: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly toDate?: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly workloadName?: string

    /**
     * 
     * @type {Array<'EVENT_TYPE_INVALID' | 'EVENT_TYPE_SURGE' | 'EVENT_TYPE_OOM_KILL' | 'EVENT_TYPE_CONFIGURATION_CHANGEDV2' | 'EVENT_TYPE_RECOMMENDED_POD_COUNT_CHANGED' | 'EVENT_TYPE_RECOMMENDED_REQUESTS_CHANGED' | 'EVENT_TYPE_SCALING_POLICY_CREATED' | 'EVENT_TYPE_SCALING_POLICY_DELETED' | 'EVENT_TYPE_SCALING_POLICY_UPDATED' | 'EVENT_TYPE_SCALING_POLICY_ASSIGNED' | 'EVENT_TYPE_FAILED_HELM_TEST_HOOK'>}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEvents
     */
    readonly type?: Array<WorkloadOptimizationAPIListWorkloadEventsTypeEnum>
}

/**
 * Request parameters for workloadOptimizationAPIListWorkloadScalingPolicies operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPoliciesRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPoliciesRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPolicies
     */
    readonly clusterId: string
}

/**
 * Request parameters for workloadOptimizationAPIListWorkloads operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadsRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadsRequest {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloads
     */
    readonly clusterId: string
}

/**
 * Request parameters for workloadOptimizationAPIUpdateWorkloadScalingPolicy operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicyRequest
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicyRequest {
    /**
     * Cluster ID associated with the scaling policy.
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicy
     */
    readonly clusterId: string

    /**
     * Policy ID associated with the scaling policy.
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicy
     */
    readonly policyId: string

    /**
     * The values to configure for the policy.
     * @type {WorkloadoptimizationV1UpdateWorkloadScalingPolicy}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicy
     */
    readonly policy: WorkloadoptimizationV1UpdateWorkloadScalingPolicy
}

/**
 * Request parameters for workloadOptimizationAPIUpdateWorkloadV2 operation in WorkloadOptimizationAPIApi.
 * @export
 * @interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2Request
 */
export interface WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2Request {
    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2
     */
    readonly clusterId: string

    /**
     * 
     * @type {string}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2
     */
    readonly workloadId: string

    /**
     * 
     * @type {WorkloadoptimizationV1UpdateWorkloadV2}
     * @memberof WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2
     */
    readonly workload: WorkloadoptimizationV1UpdateWorkloadV2
}

/**
 * WorkloadOptimizationAPIApi - object-oriented interface
 * @export
 * @class WorkloadOptimizationAPIApi
 * @extends {BaseAPI}
 */
export class WorkloadOptimizationAPIApi extends BaseAPI {
    /**
     * 
     * @summary Assigns the provided list of workload IDs to the specified policy.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIAssignScalingPolicyWorkloads(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIAssignScalingPolicyWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIAssignScalingPolicyWorkloads(requestParameters.clusterId, requestParameters.policyId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a scaling policy that can be assigned to workloads at a later time.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPICreateWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPICreateWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPICreateWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a scaling policy.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIDeleteWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIDeleteWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIDeleteWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns status of workload-autoscaler agent.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIGetAgentStatus(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetAgentStatusRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIGetAgentStatus(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns workload-autoscaler installation command.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIGetInstallCmd(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetInstallCmdRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIGetInstallCmd(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns workload-autoscaler installation script.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIGetInstallScript(options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIGetInstallScript(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a workload by a given id.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIGetWorkload(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIGetWorkload(requestParameters.clusterId, requestParameters.workloadId, requestParameters.includeMetrics, requestParameters.fromTime, requestParameters.toTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns scaling policy.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIGetWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIGetWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns workloads optimization summary.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIGetWorkloadsSummary(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIGetWorkloadsSummaryRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIGetWorkloadsSummary(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of workload events for given cluster.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEventsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIListWorkloadEvents(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadEventsRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIListWorkloadEvents(requestParameters.clusterId, requestParameters.workloadId, requestParameters.pageLimit, requestParameters.pageCursor, requestParameters.fromDate, requestParameters.toDate, requestParameters.workloadName, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of workload scaling policies for given cluster.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPoliciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIListWorkloadScalingPolicies(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadScalingPoliciesRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIListWorkloadScalingPolicies(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of workloads for the given cluster.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIListWorkloads(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIListWorkloadsRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIListWorkloads(requestParameters.clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a scaling policy.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIUpdateWorkloadScalingPolicy(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadScalingPolicyRequest, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIUpdateWorkloadScalingPolicy(requestParameters.clusterId, requestParameters.policyId, requestParameters.policy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a CAST AI managed workload V2.
     * @param {WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadOptimizationAPIApi
     */
    public workloadOptimizationAPIUpdateWorkloadV2(requestParameters: WorkloadOptimizationAPIApiWorkloadOptimizationAPIUpdateWorkloadV2Request, options?: RawAxiosRequestConfig) {
        return WorkloadOptimizationAPIApiFp(this.configuration).workloadOptimizationAPIUpdateWorkloadV2(requestParameters.clusterId, requestParameters.workloadId, requestParameters.workload, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const WorkloadOptimizationAPIListWorkloadEventsTypeEnum = {
    INVALID: 'EVENT_TYPE_INVALID',
    SURGE: 'EVENT_TYPE_SURGE',
    OOM_KILL: 'EVENT_TYPE_OOM_KILL',
    CONFIGURATION_CHANGEDV2: 'EVENT_TYPE_CONFIGURATION_CHANGEDV2',
    RECOMMENDED_POD_COUNT_CHANGED: 'EVENT_TYPE_RECOMMENDED_POD_COUNT_CHANGED',
    RECOMMENDED_REQUESTS_CHANGED: 'EVENT_TYPE_RECOMMENDED_REQUESTS_CHANGED',
    SCALING_POLICY_CREATED: 'EVENT_TYPE_SCALING_POLICY_CREATED',
    SCALING_POLICY_DELETED: 'EVENT_TYPE_SCALING_POLICY_DELETED',
    SCALING_POLICY_UPDATED: 'EVENT_TYPE_SCALING_POLICY_UPDATED',
    SCALING_POLICY_ASSIGNED: 'EVENT_TYPE_SCALING_POLICY_ASSIGNED',
    FAILED_HELM_TEST_HOOK: 'EVENT_TYPE_FAILED_HELM_TEST_HOOK'
} as const;
export type WorkloadOptimizationAPIListWorkloadEventsTypeEnum = typeof WorkloadOptimizationAPIListWorkloadEventsTypeEnum[keyof typeof WorkloadOptimizationAPIListWorkloadEventsTypeEnum];
