import { Box, Stack, Typography } from '@mui/material';

import { Icons } from '@cast/design-system';

import { useAbility } from 'core/ability';
import { EarlyAccessLabel } from 'main-layout/sidebar/_components/EarlyAccessLabel';

import { SidebarItem } from './SidebarItem';
import { useMainLayout } from '../../hooks';
import { useSidebarCluster } from '../_hooks/useSidebarCluster';

export const ExtraFeaturesSidebar = () => {
  const { sidebarState } = useMainLayout();
  const cluster = useSidebarCluster();

  const canViewAi = useAbility().canOneOf('view', [
    'OrganizationAiOptimizerReports',
    'OrganizationAiOptimizerProviders',
    'OrganizationAiOptimizerModelDeployments',
    'OrganizationAiOptimizerPlayGround',
    'OrganizationAiOptimizerSettingsPage',
  ]);

  if (cluster || !sidebarState) {
    return null;
  }

  const isMinimized = sidebarState === 'minimized';

  return (
    <Stack>
      <Box
        sx={{
          px: isMinimized ? '0px' : '8px',
        }}
      >
        <Typography
          variant="L10M"
          color="textSecondary"
          pl={isMinimized ? 0 : 12}
          mb={10}
          textAlign={isMinimized ? 'center' : 'left'}
        >
          {isMinimized ? 'ext.' : 'extra features'}
        </Typography>

        <Stack gap={8}>
          {canViewAi && (
            <SidebarItem
              title="AI Enabler"
              icon={<Icons.Brain height={18} weight="fill" />}
              url="/llm"
            >
              <SidebarItem
                title="Cost Report"
                url="/llm/report"
                rules={[['view', 'OrganizationAiOptimizerReports']]}
              />
              <SidebarItem
                title="Providers"
                url="/llm/providers"
                rules={[['view', 'OrganizationAiOptimizerProviders']]}
              />
              <SidebarItem
                title="Model Deployments"
                url="/llm/model-deployments"
                rules={[['view', 'OrganizationAiOptimizerModelDeployments']]}
              />
              <SidebarItem
                title="Playground"
                url="/llm/playground"
                rules={[['view', 'OrganizationAiOptimizerPlayGround']]}
              />
              <SidebarItem
                title="Settings"
                url="/llm/settings"
                rules={[['view', 'OrganizationAiOptimizerSettingsPage']]}
              />
            </SidebarItem>
          )}

          <SidebarItem
            title="DB Optimizer"
            icon={<Icons.Database height={18} weight="fill" />}
            url="/dbo"
            endAdornment={<EarlyAccessLabel />}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
