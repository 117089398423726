import { ReactElement, ReactNode } from 'react';

import {
  Box,
  Stack,
  StackProps,
  Typography,
  SxProps,
  TypographyProps,
  BoxProps,
} from '@mui/material';
import clsx from 'clsx';

const classNames = {
  root: 'StatusMessage',
  iconWrapper: 'StatusMessage-IconWrapper',
  body: 'StatusMessage-Body',
  actions: 'StatusMessage-Actions',
};

export type StatusMessageProps = {
  stackProps?: StackProps;
  icon?: ReactElement;
  iconWrapperProps?: BoxProps;
  title: ReactNode;
  titleProps?: TypographyProps;
  name?: ReactNode;
  nameProps?: TypographyProps;
  body?: ReactNode;
  bodyProps?: TypographyProps;
  actions?: ReactNode;
  actionsProps?: BoxProps;
  sx?: SxProps;
  className?: string;
  testId?: string;
};

export const StatusMessage = ({
  stackProps,
  icon,
  iconWrapperProps,
  title,
  titleProps,
  name,
  nameProps,
  body,
  bodyProps,
  actions,
  actionsProps,
  sx,
  className,
  testId,
}: StatusMessageProps) => {
  return (
    <Stack
      alignItems="center"
      {...stackProps}
      className={clsx(classNames.root, className, stackProps?.className)}
      sx={sx}
      data-testid={testId}
    >
      {!!icon && (
        <Box
          mb={12}
          {...iconWrapperProps}
          className={clsx(classNames.iconWrapper, iconWrapperProps?.className)}
        >
          {icon}
        </Box>
      )}
      {!!name && (
        <Typography
          variant="h6"
          color="grey.600"
          mb={4}
          textAlign="center"
          data-testid="message-name"
          {...nameProps}
        >
          {name}
        </Typography>
      )}
      <Typography
        variant="h6"
        textAlign="center"
        data-testid="message-title"
        {...titleProps}
      >
        {title}
      </Typography>
      {!!body && (
        <Typography
          variant="P12R"
          color="grey.500"
          textAlign="center"
          mt={8}
          data-testid="message-body"
          {...bodyProps}
          className={clsx(classNames.body, bodyProps?.className)}
        >
          {body}
        </Typography>
      )}
      {!!actions && (
        <Box
          mt={32}
          {...actionsProps}
          className={clsx(classNames.actions, actionsProps?.className)}
        >
          {actions}
        </Box>
      )}
    </Stack>
  );
};

StatusMessage.classNames = classNames;
