import { AxiosInstance } from 'axios';

import { RuntimeSecurityAPIApiFactory } from '@cast/openapi';

import { AXIOS_CONFIGURATION } from './constants';

export const RuntimeSecurityApiAdapter = (client: AxiosInstance) => {
  const generatedApi = RuntimeSecurityAPIApiFactory(
    AXIOS_CONFIGURATION,
    '',
    client
  );

  // prettier-ignore
  return {
    getAnomalies: generatedApi.runtimeSecurityAPIGetAnomalies,
    getAnomalyDetails: generatedApi.runtimeSecurityAPIGetAnomaly,
    getAnomalyEvents: generatedApi.runtimeSecurityAPIGetAnomalyEvents,
    ackAnomalies: generatedApi.runtimeSecurityAPIAckAnomalies,
    closeAnomalies: generatedApi.runtimeSecurityAPICloseAnomalies,
    getAnomaliesOverview: generatedApi.runtimeSecurityAPIGetAnomaliesOverview,
    getRules: generatedApi.runtimeSecurityAPIGetRules,
    getRuleDetails: generatedApi.runtimeSecurityAPIGetRule,
    createRule: generatedApi.runtimeSecurityAPICreateRule,
    updateRule: generatedApi.runtimeSecurityAPIEditRule,
    deleteRules: generatedApi.runtimeSecurityAPIDeleteRules,
    toggleRules: generatedApi.runtimeSecurityAPIToggleRules,
    getRuntimeEvents: generatedApi.runtimeSecurityAPIGetRuntimeEvents,
    getRuntimeEventsGroups: generatedApi.runtimeSecurityAPIGetRuntimeEventGroups,
    getRuntimeEventsProcessTree: generatedApi.runtimeSecurityAPIGetRuntimeEventsProcessTree,
    getCustomDenyLists: generatedApi.runtimeSecurityAPIGetLists,
    createCustomDenyList: generatedApi.runtimeSecurityAPICreateList,
    deleteCustomDenyLists: generatedApi.runtimeSecurityAPIDeleteLists,
  };
};
