import { useMemo } from 'react';

import { Typography } from '@mui/material';

import { validatePreset } from './utils';
import { List } from '../../../lists/List';
import { ListItem } from '../../../lists/ListItem';
import { ListItemText } from '../../../lists/ListItemText';
import { ListSubheader } from '../../../lists/ListSubheader';
import { Tooltip } from '../../../tooltip';
import { useDatePickerContext } from '../../components/useDatePickerContext';
import { RangePickerProviderProps } from '../../types';
import { buildPresetRange, isSelectedFilterPreset } from '../../utils';

export const FilterPresetsList = () => {
  const ctx = useDatePickerContext<RangePickerProviderProps>();

  const validatedFilterPresets = useMemo(() => {
    return ctx.filterPresets.map((preset) =>
      validatePreset(
        preset,
        ctx.timezone,
        ctx.selected,
        ctx.startMonth,
        ctx.endMonth,
        ctx.maxNumberOfDays
      )
    );
  }, [
    ctx.filterPresets,
    ctx.timezone,
    ctx.selected,
    ctx.startMonth,
    ctx.endMonth,
    ctx.maxNumberOfDays,
  ]);

  return (
    <List size="small" disablePadding testId="range-picker-filter-presets">
      <ListSubheader
        sx={{ alignItems: 'center', padding: 8, color: 'grey.900' }}
      >
        <Typography variant="P12M">Date Range:</Typography>
      </ListSubheader>

      {validatedFilterPresets?.map((preset) => {
        return (
          <Tooltip
            key={preset.label}
            title={preset.error}
            wrapIf={!!preset.error}
            placement="top"
            disableInteractive
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -15],
                  },
                },
              ],
            }}
          >
            <span>
              <ListItem
                sx={{
                  borderRadius: '4px',
                  padding: '7px 8px',
                }}
                disabled={!!preset.error}
                selected={
                  ctx.selected
                    ? isSelectedFilterPreset(ctx.selected, preset, ctx.timezone)
                    : false
                }
                onClick={() => {
                  const dateRange = buildPresetRange(preset, ctx.timezone);
                  ctx.setSelected(dateRange);
                  if (dateRange.from) {
                    ctx.pickerRef?.scrollToMonth(dateRange.from);
                  }
                }}
                component="li"
                testId={'range-preset-' + preset.label}
              >
                <ListItemText
                  primary={preset.label}
                  primaryTypographyProps={{ variant: 'P12R' }}
                />
              </ListItem>
            </span>
          </Tooltip>
        );
      })}
    </List>
  );
};
